import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProvider from '../../../framework/src/StorageProvider';
import { withRouter,RouterProps } from "react-router";
// Customizable Area End


export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  classes: any;
  showNotification : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: number;
  id: string;
  uploading: boolean,
  authToken: string;
  selectedFiles: File[];
  // uploadedFileList : any[];
  // Customizable Area End
}

interface SS {
  id: any;
}
class NewPrintController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
        this.state = {
          loading: 0,
          uploading: false,
          id: "",
          authToken: "",
          selectedFiles: [],
          // uploadedFileList : []
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
      }

      uploadFile() {
        let formData = new FormData();
        formData.append("token", this.state.authToken);
        formData.append("data[account_id]", this.state.id);
        // console.log(this.state.fileName, "filenamessss", this.state.authToken)
        if (this.state.selectedFiles.length > 0) {
          for (let i = 0; i < this.state.selectedFiles.length; i++) {
            formData.append(
              `data[attachments][${i}][attachment]`,
              this.state.selectedFiles[i]
            );
          }
        }
        const baseURL =
          "https://secureprinting-37636-ruby.37636.dev.ap-southeast-1.aws.svc.builder.cafe";
        let request = new XMLHttpRequest();
        request.open("POST", baseURL + "/" + configJSON.uploadFilesEndPoint);
        request.setRequestHeader("accept", configJSON.contentType);
        request.addEventListener(
          "load",
          async (e) => {
            this.props.hideLoader();
            this.setState({ loading: 100 });
            this.setState({ uploading: false });
            let data = JSON.parse(request.response)?.data || [];
            await StorageProvider.remove('selectedFiles')
            // let uploadedFileList = await StorageProvider.get('selectedFiles') || "[]";
            // uploadedFileList = JSON.parse(uploadedFileList);
            // const newUploadedFile = [...uploadedFileList,...data];
            await StorageProvider.set('selectedFiles', JSON.stringify(data));
            const {showNotification} = this.props;
            showNotification("File successfully uploaded","success");
            setTimeout(() => {
              this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen");
            }, 1000);
            }
        );
        request.upload.addEventListener(
          "loadstart",
          () => {
            this.props.showLoader();
            this.setState({ loading: 0 });
            this.setState({ uploading: true });
          }
        );
        request.upload.addEventListener(
          "progress",
          (e) => {
            if (e.lengthComputable) {
              var percentage = Math.round((e.loaded / e.total) * 100);
              this.setState({ loading: percentage });
              this.setState({ uploading: true });
            }
          }
        );
        request.send(formData);
      }
}
export default (NewPrintController);
