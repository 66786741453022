// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Grid, Button, Collapse } from '@material-ui/core';
import { withRouter } from "react-router";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from '../../../components/src/AppColorConstants';
// Customizable Area End

import HeaderController, {
  Props,
  configJSON
} from "./HeaderController.Web";
import './css/Header.css'
import {
  logo, homeicon, abouticon, servicesicon, contactusicon, usericon, arrowdown, closeicon, menuicon
} from './assets'

 class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  changeMenu=()=>{
    this.setState({mobilemenu:!this.state.mobilemenu})
    const mobileMenu=document.getElementById("navMobileDropdown")
    mobileMenu.classList.toggle("navMobileHeight")
  }

   async componentDidMount() {
     this.getHeaderContent()
   }

  
  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
        <div
          className={"navBar navMobile"}
        >
          <div className="navBarContent">
            <div className={"navLeft"}>
              <img  onClick={()=>this.props.history.push("/")} src={logo} alt="" className={classes.logoImg}/>
            </div>
            <div className={"navMiddle"}>
            <span className={classes.navMiddleItem}>
              <img src={homeicon} alt="" className={classes.navMiddleImg}/>
              {this.state.header?.menu?.item1}
            </span>
              <span className={classes.navMiddleItem} onClick={()=>this.props.history.push("/About-us")}>
              <img src={abouticon} alt="" className={classes.navMiddleImg}/>
                {this.state.header?.menu?.item2}
            </span>
              <div className={"Header_Dropdown_Cont"}>
              <span className={classes.navMiddleItem}>
              <img src={servicesicon} alt="" className={classes.navMiddleImg}/>
                {this.state.header?.menu?.item3}
                <img src={arrowdown} alt="" className={classes.navDownArrowImg}/>
              </span>
                <div className="Header_sub_menu">
                <span className={`${classes.navMiddleMenuItem} secureServicesMenuItem`}
                >
                  {this.state.header?.submenu?.item1}
                  <div className="Header_sub_menu2">
                    <span className={classes.navMiddleMenuItem}>
                      {this.state.header?.submenu2?.item1}
                    </span>
                  <span className={classes.navMiddleMenuItem}>
                      {this.state.header?.submenu2?.item2}
                    </span>
                  <span className={classes.navMiddleMenuItem}>
                      {this.state.header?.submenu2?.item3}
                    </span>
                  </div>
                  <img src={arrowdown} alt="" className={classes.navDownArrowImg}/>
                </span>
                  <span className={classes.navMiddleMenuItem}>
                  {this.state.header?.submenu?.item2}
                    <img src={arrowdown} alt="" className={classes.navDownArrowImg}/>
                </span>
                </div>
              </div>
              <span className={classes.navMiddleItem} onClick={()=>this.props.history.push("/Contact-us")} >
              <img src={contactusicon} alt="" className={classes.navMiddleImg}/>
                {this.state.header?.menu?.item4}
              </span>
              <span className={classes.navMiddleItem} onClick={()=>this.props.history.push("/Faq")}>
              <img src={contactusicon} alt="" className={classes.navMiddleImg}/>
                {this.state.header?.menu?.item5}
              </span>
            </div>
            <div className={"navRight"}>
              <Button className={`${classes.navLoginBtn} navButton`} onClick={()=>this.props.history.push("/PhoneLoginWeb")}>
                <img src={usericon} alt="" className={classes.loginUser}/>
                {configJSON.login}
              </Button>
              <Button className={`${classes.navSignupBtn} navButton`}
                      onClick={()=>this.props.history.push("/SignUp")}
                      style={{backgroundColor:APP_COLORS.app_theme_green_color}}>{configJSON.signUp}</Button>
              <img src={this.state.mobilemenu?closeicon:menuicon} alt="" className={"nav_close_img"} onClick={this.changeMenu}/>
            </div>
          </div>

            <div className="navMobileContent navMobileHeight" id={"navMobileDropdown"}>
              <Collapse in={this.state.mobilemenu} style={{width:"100%"}}>
                <span className={`${classes.navMiddleItem} navMobileMenuItem`}>
                  <img src={homeicon} alt="" className={classes.navMiddleImg}/>
                  {this.state.header?.menu?.item1}
                </span>
                <span className={`${classes.navMiddleItem} navMobileMenuItem`} onClick={()=>this.props.history.push("/About-us")}>
                  <img src={abouticon} alt="" className={classes.navMiddleImg}/>
                  {this.state.header?.menu?.item2}
                </span>
                <span className={`${classes.navMiddleItem} navMobileMenuItem`}>
                  <img src={servicesicon} alt="" className={classes.navMiddleImg}/>
                  {this.state.header?.menu?.item3}
                  <img src={arrowdown} alt="" className={classes.navDownArrowImg}/>
                </span>
                <div className="services_mobile_submenu">
                  <span className={`${classes.navMiddleMenuItem} `}
                  >
                    {this.state.header?.submenu?.item1}
                  </span>
                  <div className="services_mobile_submenu2">
                      <span className={classes.navMiddleMenuItem}>
                        {this.state.header?.submenu2?.item1}
                      </span>
                    <span className={classes.navMiddleMenuItem}>
                        {this.state.header?.submenu2?.item2}
                      </span>
                    <span className={classes.navMiddleMenuItem}>
                        {this.state.header?.submenu2?.item3}
                      </span>
                  </div>
                  <span className={`${classes.navMiddleMenuItem} `}
                  >
                    {this.state.header?.submenu?.item2}
                  </span>
                </div>
                <span className={`${classes.navMiddleItem} navMobileMenuItem`} onClick={()=>this.props.history.push("/Contact-us")}>
                  <img src={contactusicon} alt="" className={classes.navMiddleImg}/>
                  {this.state.header?.menu?.item4}
                </span>
                <span className={`${classes.navMiddleItem} navMobileMenuItem`} onClick={()=>this.props.history.push("/Faq")}>
                  <img src={contactusicon} alt="" className={classes.navMiddleImg}/>
                  {this.state.header?.menu?.item5}
                </span>
                <div className={"navMobileButton"}>
                  <Button className={`${classes.navLoginBtn} navButton2`} onClick={()=>this.navigateTo("/PhoneLoginWeb")}>
                    <img src={usericon} alt="" className={classes.loginUser}/>
                    {configJSON.login}
                  </Button>
                  <Button className={`${classes.navSignupBtn} navButton2`}
                    onClick={()=>this.navigateTo("/SignUp")}
                    style={{backgroundColor:APP_COLORS.app_theme_green_color}}>
                    {configJSON.signUp}
                  </Button>
                </div>
              </Collapse>
            </div>
        </div>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  navBar:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    boxShadow:"0 2px 7px 0 rgba(147, 147, 147, 0.5)"
  },
  navMiddle:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-evenly"
  },
  navLeft:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  navRight:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  
  footerCont:{
    backgroundColor:APP_COLORS.app_theme_green_color,
    borderRadius:32,
    boxShadow: "0 -1px 6px 0 rgba(0, 0, 0, 0.5)",
  },
  logoImg:{
    width:90,
    height:90,
    margin:"5px auto",
    objectFit:"contain",
    cursor: 'pointer'
  },
  navMiddleImg:{
    width:45,
    height:45,
    marginRight:"10px",
    objectFit:"contain",
  },
  navDownArrowImg:{
    width:20,
    height:20,
    marginLeft:"7px",
    float:"right",
    objectFit:"contain",
  },
  //Text stlyling
  footerText:{
    fontSize:16,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'white',
    marginLeft:10,
    marginRight:10
  },

  navMiddleItem:{
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    display:"flex",
    alignItems:'center',
    cursor: 'pointer'
  },
  navMiddleMenuItem:{
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    margin:"10px auto",
    // padding:"0 20px",
    width:"100%",
    display:"flex",
    alignItems:'center',
    justifyContent:'space-between'
  },
  navLoginBtn:{
    borderRadius:12,
    height:45,
    width:130,
    textTransform:"none",
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },
  navSignupBtn:{
    borderRadius:12,
    height:45,
    color:"white",
    width:130,
    textTransform:"none",
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },

  //Dot Styling
  dot:{
    width:15,
    height:15,
    borderRadius:"50%",
    marginRight:10,
    cursor:"pointer"
  },
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(Header));
