Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "MenuOrdering";
  exports.labelBodyText = "MenuOrdering Body";
  exports.btnExampleTitle = "CLICK ME";
  exports.contentType = "application/json";
  exports.multiformData="multipart/form-data";
  exports.postAPiEndMethod = "POST";
  exports.patchAPiEndMethod = "PATCH"
  exports.deleteAPiEndMethod = "DELETE"
  exports.getAPiEndMethod = "GET"

  
exports.lastOrderEndPoint = "bx_block_menuordering/orders/get_last_order",
exports.orderDetailsEndPoint = "bx_block_ordermanagement/orders/26"
exports.getOrderDetailsAPIEndPoint = "bx_block_ordermanagement/orders/orderId"; //{userid}


  //API CONSTANTS


//Labels
exports.navBarName="David Wong";
exports.pageTitle="Home";
exports.POrderNumber="Order #23432";
exports.pDocs="12 Docs";
exports.userNameWith="with Sourabh Mishra";
exports.orderCompleteText="Order Complete";
exports.ShoporderAmount="Rs. 500";
exports.orderTime="12/12/2020 12:20 PM";
exports.recentOrders="Recent Orders";
exports.seeAll="See All";
  // Customizable Area End