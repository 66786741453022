// @ts-nocheck
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  userLogo,
  uploadSelected,
  uploadUnselected,
  currentOrdersSelected,
  currentOrdersUnselected,
  orderHistorySelected,
  orderHistoryUnselected,
  settingsSelected,
  settingsIcon,
  logoutIcon, menuIcon, closeIcon, supportSelectedIcon, supportIcon,
} from "../../UserDashBoard/src/assets";

import { homeSelected,home_unselected  } from "./assets";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import StorageProvider from "../../../framework/src/StorageProvider";
import { Switch, Route, Link, NavLink } from "react-router-dom";
import { Redirect, withRouter } from "react-router";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import MerchantSetting from "../../user-profile-basic/src/MerchantSetting.Web";
import ShopDashboard from "./ShopDashboard.Web";
import OrderManagement from "../../OrderManagement/src/OrderManagement.Web";
class MerchantSidebarNavigation extends React.Component {
  constructor(props: Props) {
    super(props);
  }
  navigationLink = [
    {
      name: "Home",
      path: "/MerchantSidebarNavigation/DashBoard",
      currentPath: "DashBoard",
      selectedPath: homeSelected,
      unSelectedPath: home_unselected,
    },
    {
      name: "Order History",
      path: "/MerchantOrderManagement/OrderHistory",
      currentPath: "OrderHistory",
      selectedPath: currentOrdersSelected,
      unSelectedPath: currentOrdersUnselected,
    },
    {
      name: "Support",
      path: "/MerchantSupport/Support",
      currentPath: "Support",
      selectedPath: supportSelectedIcon,
      unSelectedPath: supportIcon,
    },
    {
      name: "Settings",
      path: "/MerchantSettings/Settings",
      currentPath: "Settings",
      selectedPath: settingsSelected,
      unSelectedPath: settingsIcon,
    }
  ];

  // Customizable Area End
  isActiveLink = (pathName) => {
    let { location } = this.props;
    const path = location.pathname.toString();
    if (path.includes(pathName)) {
      return true;
    }
    return false;
  };
  logoutConfirmationDialog = () =>{
    const {openDialog} = this.props;
    const onClose = (msg:any)=>{
        if(msg === 'Yes'){
          this.clearStorageAndredirectToLogin()
        }
    }
    this.props.openDialog('Logout Confirmation','Are you sure want to logout?',onClose);
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name,
          profileImagUri: loginData.profile_image_path,
          user_type : loginData.user_type
        });
      }
    });
  }  

  clearStorageAndredirectToLogin = async () => {
    await StorageProvider.remove("loginData");
    await StorageProvider.remove("signUpData");
    await StorageProvider.remove("selectedFiles");
    await StorageProvider.remove("showPay");
    await StorageProvider.remove("orderId");
    await StorageProvider.remove("orderIdDetails");
    await StorageProvider.remove("user_type")
    await StorageProvider.set("user_type",this.state.user_type)
    this.props.history.push("/PhoneLoginWeb");
  };
  handleMobileMenu=()=>
  {
    const navBar=document.getElementById('Navbar')
    navBar.classList.toggle("header_open_cont")
    // console.log(navBar)
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.appContainer}>
        <div id={"Navbar"} className={`${classes.leftBackground} dashBoard_sidebar header_main_cont`}>
          <Grid
            container
            // direction="column"
            // spacing={2}
            justify="center"
            alignItems={"center"}
          >
            <Grid item xs={12} className={`logoCont`}>
              <img src={userLogo} className={`companyLogo`}/>
              <img src={closeIcon} className={`menuIcon`} onClick={this.handleMobileMenu}/>
            </Grid>
            <List disablePadding className={"navLink_cont"}>
            {this.navigationLink.map((navLinkItem) => {
              return (
                <Link
                  key={navLinkItem.name}
                  to={navLinkItem.path}
                  onClick={this.handleMobileMenu}
                  style={{ textDecoration: "none" }}
                >
                  <Grid  item xs={12}>
                    <ListItem  to={navLinkItem.path} disableGutters>
                      <ListItemIcon>
                        <img
                          src={
                            this.isActiveLink(navLinkItem.currentPath)
                              ? navLinkItem.selectedPath
                              : navLinkItem.unSelectedPath
                          }
                          className={classes.printIcon}
                        />
                      </ListItemIcon>
                      <p className={`navBarListTextItem`}
                         style={{fontFamily:APP_FONTS.SofiaProRegular}}
                      >{navLinkItem.name}</p>
                    </ListItem>
                  </Grid>
                </Link>
              );
            })}
            </List>
            <Grid
              xs={12}
              item
              style={{  margin: "10%",display:"flex",justifyContent:"center",cursor: "pointer"}}
            >
              <img src={logoutIcon} style={{margin:"auto"}} onClick={()=>{
                this.handleMobileMenu()
                this.logoutConfirmationDialog()}
              }/>
            </Grid>
          </Grid>
        </div>
        <div className={`dashBoard_content`} >
          <Grid item xs={12} className={`logoNavCont`}>
            {/*<img src={userLogo} className={`companyLogo`} />*/}
            <img src={menuIcon} className={`menuIcon`} onClick={this.handleMobileMenu}/>
          </Grid>
          <Switch>
            <PrivateRoute  role="Merchant" path={`/MerchantSidebarNavigation/DashBoard`}>
              <ShopDashboard />
            </PrivateRoute>
            <PrivateRoute  role="Merchant" path={`/MerchantSupport/Support`}>
              <ShopDashboard />
            </PrivateRoute>
            <PrivateRoute  role="Merchant" path={`/MerchantOrderManagement/OrderHistory`}>
              <OrderManagement />
            </PrivateRoute>
            <PrivateRoute  role="Merchant" path={`/MerchantSettings/Settings`}>
              <MerchantSetting />
            </PrivateRoute>
            <Redirect
              exact
              from={"/OrderManagement"}
              to={`/OrderManagement/OrderHistory`}
            />
            <Redirect
              exact
              from={"/MerchantSidebarNavigation"}
              to={`/MerchantSidebarNavigation/DashBoard`}
            />
            <Redirect
              exact
              from={"/MerchantSettings"}
              to={`/MerchantSettings/Settings`}
            />
            <Redirect
              exact
              from={"/MerchantSupport"}
              to={`/MerchantSupport/Support`}
            />
            <Redirect
              exact
              from={"/MerchantOrderManagement"}
              to={`/MerchantOrderManagement/OrderHistory`}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    appContainer: {
      minHeight: "100vh",
      width:'100%',
      position:"relative",
      overflowX:"hidden"
    },
    leftBackground: {
      paddingTop: "2%",
    },
    rightBackground: {
      borderRadius: "32px",
      backgroundColor: "#fff",
    },
    printIcon: {
      width: "50px",
    },
    logoutIcon: {
      width: "48px",
      height: "48px",
      margin: "143px 11px 0 7px",
      paddingLeft: "10px",
      objectFit: "contain",
    },
    labelSetting: {
      fontFamily: APP_FONTS.SofiaPro,
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    settingAvatar: {
      width: "18px",
      height: "18px",
      borderRadius: "15.2px",
      float: "right",
      marginTop: "20px",
      padding: "15px",
    },
    parentContainer: {
      padding: "30px",
    },
    editProfileColorButton: {
      width: "236px",
      marginLeft: "25px",
      padding: "15px",
      borderRadius: "12px",
      backgroundColor: "#32795f",
    },
    editProfileButton: {
      width: "236px",
      marginLeft: "25px",
      padding: "15px",
      borderRadius: "12px",
      marginTop: "10px",
      backgroundColor: "#fff",
    },
    editProfileButtonIcon: {
      width: "18px",
      height: "18px",
    },
    editProfileColorButtonTxt: {
      fontFamily: APP_FONTS.SofiaPro,
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#ffffff",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileButtonTxt: {
      fontFamily: APP_FONTS.SofiaPro,
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#8f92a1",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileArrowIcon: {
      width: "18px",
      height: "18px",
    },
    active: {
      color: "red",
    },
  });

  export default withRouter(
    withStyles(styles, { withTheme: true })(withConfirmBox((MerchantSidebarNavigation)))
  );
  