//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import NetInfo from "@react-native-community/netinfo";
import StorageProvider from '../../../framework/src/StorageProvider';
// import RazorpayCheckout from 'react-native-razorpay';
import { APP_COLORS } from "../../../components/src/AppColorConstants";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  id: string;
  authToken: string;
  filePath: any,
  fileName: String;
  fileType: any;
  imageUri: any;
  attachmentIds: Array<string>;
  orderAmount: number;
  taxAmount: number;
  totalAmount: number;
  orderId: number;
  razorpay_payment_id: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderPaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
  > {

  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  emailReg: RegExp;

  apiPlaceOrderCallId: String = "";
  apiGetOrderAmountDetailsCallId: String = "";
  apiUpdateOrderStatusCallId: String = "";
  apiCreateTransactionCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.updateOrderStatus = this.updateOrderStatus.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      loading: false,
      id: "",
      authToken: "",
      filePath: {},
      fileName: "",
      fileType: "",
      imageUri: '',
      attachmentIds: [],
      orderAmount: 0.00,
      taxAmount: 0.00,
      totalAmount: 0.00,
      orderId: 0,
      razorpay_payment_id: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiPlaceOrderCallId) {
        await this.setState({ loading: false })
        if (responseJson && responseJson.data && responseJson.data.id) {
          //TODO: Need to uncomment this to go with the login flow
          //Here clear all stored values in storage redirect to QR code screen

          let data: any = {}
          data.orderId = responseJson.data.id;
          data.goBack = false;
          await StorageProvider.remove('orderId');
          await StorageProvider.set('orderId', JSON.stringify(data));

          //  this.showAlert('Success!!!', responseJson.meta.message);
          // this.props.navigation.navigate('PaymentSuccessfullScreen');
          await this.setState({ orderId: responseJson.data.id });

          // setTimeout(() => {
            this.openRazorPay(responseJson.data.id);
          // }, 100);


        } else if (responseJson && responseJson.message) {
          //TODO: Need to uncomment this to go with the login flow
          this.showAlert("Response Alert!", responseJson.message);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiGetOrderAmountDetailsCallId) {
        await this.setState({ loading: false })
        if (responseJson && responseJson.data) {
          //TODO: Need to uncomment this to go with the login flow
          //Here clear all stored values in storage redirect to QR code screen
          if (responseJson.data.attributes) {
            this.setState({
              orderAmount: responseJson.data.attributes.order_amount,
              taxAmount: responseJson.data.attributes.tax_amount,
              totalAmount: responseJson.data.attributes.total_amount,
            })
          }
        } else if (responseJson && responseJson.message) {
          //TODO: Need to uncomment this to go with the login flow
          this.showAlert("Response Alert!", responseJson.message);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
        this.props.hideLoader()
      } else if (apiRequestCallId === this.apiUpdateOrderStatusCallId) {
        if (responseJson && responseJson.data) {
          //TODO: Need to uncomment this to go with the login flow
          // setTimeout(() => {
          //     this.showAlert("Response Alert!", "Order status has been updated with Payment Completed");
          // }, 100);

          setTimeout(() => {
            this.createTransactionApiCall();
          }, 1000);

          // this.props.navigation.goBack();
        } else if (responseJson && responseJson.message) {
          await this.setState({ loading: false });
          setTimeout(() => {
            this.showAlert("Response Alert!", responseJson.message);
          }, 100);
        }
        else {
          //Check Error Response
          await this.setState({ loading: false });
          setTimeout(() => {
            this.parseApiErrorResponse(responseJson);
          }, 100);
        }
      } else if (apiRequestCallId === this.apiCreateTransactionCallId) {
        await this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          //TODO: Need to uncomment this to go with the login flow
          // setTimeout(() => {
          //     this.showAlert("Response Alert!", "Order status has been updated with Payment Completed");
          // }, 100);

          setTimeout(() => {
            // this.showAlert("Response Alert!", "Order created Successfully and order status has been updated with Payment Completed");
            this.displayAlert();
          }, 100);

          // this.showAlert('Success!!!', responseJson.meta.message);


          // this.props.navigation.goBack();
        } else if (responseJson && responseJson.message) {
          setTimeout(() => {
            this.showAlert("Response Alert!", responseJson.message);
          }, 100);
        }
        else {
          //Check Error Response
          setTimeout(() => {
            this.parseApiErrorResponse(responseJson);
          }, 100);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
  //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user
  async placeOrderApiCall() {
    if (this.state.orderId == 0) {
      const header = {
        "Content-Type": configJSON.contentType,
        "token": this.state.authToken
      };

      const data = {
        account_id: Number(this.state.id),
        status: 'pending',
        total_docs: Number((this.state.attachmentIds).length),
        attachment_ids: this.state.attachmentIds
      };
      const httpBody = {
        data: data
      };

      this.setState({ loading: true });

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiPlaceOrderCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.placeOrderAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiEndMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.openRazorPay(this.state.orderId);
    }
  }

  async createTransactionApiCall() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    const data = {
      razorpay_order_id: "",
      order_id: this.state.orderId,
      razorpay_payment_id: this.state.razorpay_payment_id,
      transaction_type: "Paid"
    };
    const httpBody = {
      data: data
    };

    //  this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateTransactionCallId = requestMessage.messageId;

    let createTransactionAPIEndPoint = ('' + configJSON.createTransactionAPIEndPoint).split('orderId').join('' + this.state.orderId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      createTransactionAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async getOrderAmountDetailsApiCall() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    }
    const data = {
      "order_id": Number(this.state.orderId)
    }
    const httpBody = {
      data: data
    }
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apiGetOrderAmountDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateQrCodesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  openRazorPay = async (orderId: number) => {
    const self = this;
    let email = "";
    let name = "";
    let contact = "";
    await StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        email = loginData.email,
          name = loginData.name,
          contact = loginData.phone;
      }
    });

    var options = {
      description: 'PrintSec Secure Printing',
      image: 'https://i.imgur.com/3g7nmJC.png',
      currency: 'INR',
      key: 'rzp_test_lLzsgIspuUDaTU', // Your api key
      //amount: '' + Math.round(this.state.totalAmount) + '00',
      amount: `${(parseFloat(this.state.totalAmount).toFixed(2) * 100)}`,
      handler: function (response){
        self.setState({ razorpay_payment_id: response.razorpay_payment_id },()=>{});
        self.updateOrderStatus()
        self.props.showLoader();
      },
      name: 'Printsec pvt ltd',
      // order_id: `${orderId}`,
      prefill: {
        email: email,
        contact: contact,
        name: name
      },
      //  theme: { color: APP_COLORS.app_theme_green_color }
    }
    var rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', function (response){
      self.props.showNotification(`${response.error.description}`,"error")
      // self.props.showNotification('Payment failed or cancelled by user',"error")
    });
    rzp1.open();
    // RazorpayCheckout.open(options).then((data: any) => {
    //   // handle success
    //   // console.log("RazorpayCheckout---->", JSON.stringify(data));
    //   // console.log("data.razorpay_payment_id---->", data.razorpay_payment_id);
    //   // alert("Success: ==>" + JSON.stringify(data));
    //   this.setState({ razorpay_payment_id: data.razorpay_payment_id });
    //   this.updateOrderStatus();
    //
    // }).catch((error: any) => {
    //   // handle failure
    //   //alert(`Error: ${error.code} | ${error.description}`);
    //   alert('Payment failed or cancelled by user');
    // });
  }

   updateOrderStatus=()=> {
    console.log("Update Order")
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderStatusCallId = requestMessage.messageId;

    let updateOrderStatusAPIEndPoint = ('' + configJSON.updateOrderStatusAPIEndPoint).split('orderId').join('' + this.state.orderId)

    updateOrderStatusAPIEndPoint = updateOrderStatusAPIEndPoint + "?data[status]=Paymentcompleted";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), updateOrderStatusAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  displayAlert = () => {
    this.props.showNotification("Order created Successfully and order status has been updated with Payment Completed","success")
    setTimeout(()=>{
      this.props.history.push('/UserSidebarNavigation/UploadDashBoard/PaymentSuccessfullScreen')
    },1000)
  }

  dismissSpinner = () => {
    this.setState({loading:false});
    this.props.navigation.navigate('PaymentSuccessfullScreen')
  }
}
