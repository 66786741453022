//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Text,
    Image,
    TouchableOpacity,
    View,
    FlatList,
    Platform,
    PermissionsAndroid,
    ActivityIndicator,
    Linking,
} from "react-native";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
// import { GoogleMap, LoadScript ,Marker} from '@react-google-maps/api';
import { Grid, Typography, Button,Modal ,Checkbox, InputBase,Select,MenuItem} from '@material-ui/core';
// Customizable Area End

import NearByShopsController, {
    Props,
    configJSON
} from "./NearByShopsController.Web";
import CustomButton from "../../../components/src/CustomButton";
import Scale from "../../../components/src/Scale";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import {backIcon, detailsIcon, mapsIcon, markerIcon, navBarImg} from "./assets";

const radiusValues = [
    { label: '5 Km', value: '5', hidden: true },
    { label: '10 Km', value: '10' },
    { label: '15 Km', value: '15' },
    { label: '20 Km', value: '20' },
];
const containerStyle = {
    width: '100%',
    height:"100%"
};
const options={
    disableDefaultUI:true,
    zoomControl:true
}
class MapFilter extends NearByShopsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start

    _closeModal = () => {
        this.setState({
            showMapFilter: false,
            isVisibleDropDown: false,
        });
    };

    _saveTapped = async () => {
        /// console.log('Number(this.state.radiusKm)', Number(this.state.radiusKm));
        await this.setState({
            showMapFilter: false,
            radius: this.state.radiusKm,
            loading: false,
        });
        console.log('_saveTapped');
        await this.requestLocationPermission();
    };

    _contineToPayTapped = () => {
        // make api call to create order here
        console.log('_contineToPayTapped');
        this.props.navigation.navigate('PaymentSuccessfullScreen');
    };
    _handleAllShopsModal=()=>
    {
        this.setState({allShopsModal:!this.state.allShopsModal})
    }
    _filterTapped = () => {
        this.setState({
            showMapFilter: true,
        });
    };

    // Current Location //
    requestLocationPermission = async () => {
        if (Platform.OS === 'ios') {
            this.getOneTimeLocation();
            //subscribeLocationLocation();
        } else {
            try {
                const granted = await PermissionsAndroid.request(
                    PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
                    {
                        title: 'Location Access Required',
                        message: 'This App needs to Access your location',
                        buttonNeutral: "Ask Me Later",
                        buttonNegative: "Cancel",
                        buttonPositive: "OK"
                    },
                );
                if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                    //To Check, If Permission is granted
                    this.getOneTimeLocation();
                    // subscribeLocationLocation();
                } else {
                    console.warn('Permission Denied');
                }
            } catch (err) {
                console.warn(err);
            }
        }
    };
    getOneTimeLocation = () => {
        // this.setState({ loading: true });
        // console.log("getOneTimeLocation:==>");
        // Geolocation.getCurrentPosition(
        //     //Will give you the current location
        //     (position) => {
        //         //getting the Longitude from the location json
        //         const currentLongitude = JSON.stringify(position.coords.longitude);
        //         //getting the Latitude from the location json
        //         const currentLatitude = JSON.stringify(position.coords.latitude);
        //
        //         console.log("Lat:==>", currentLatitude);
        //         console.log("Lng:==>", currentLongitude);
        //
        //         this.setState({
        //             lat: Number(currentLatitude),
        //             lng: Number(currentLongitude)
        //         });
        //
        //         this.getNearByServiceProvidrs();
        //     },
        //     (error) => {
        //         // setLocationStatus(error.message);
        //         this.setState({ loading: false });
        //         console.log("error.message:==>", error.message);
        //     },
        //     {
        //         enableHighAccuracy: false,
        //         timeout: 30000,
        //         maximumAge: 1000,
        //     },
        // );
    };

    // Current Location //
    async componentDidMount() {
        navigator.geolocation.getCurrentPosition((position)=>{
            // console.log(position)
            this.setState({lat:position.coords.latitude,lan:position.coords.longitude})
        },()=>console.log("error fetching location"))
        // Customizable Area Start
        // await StorageProvider.get('loginData').then((res) => {
        //     const loginData = JSON.parse(res);
        //     if (loginData) {
        //         console.log("loginData::: ", loginData);
        //         this.setState({
        //             id: loginData.id,
        //             authToken: loginData.token
        //         });
        //     }
        // });
        //
        // await StorageProvider.get('showPay').then((res) => {
        //     const showPay = JSON.parse(res);
        //     if (showPay) {
        //         console.log("showPay::: ", showPay);
        //         this.setState({
        //             showPayButton: showPay.show_pay_button,
        //         });
        //     }
        // });
        //
        // await this.requestLocationPermission();
        // Customizable Area End
    }

    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            //Merge Engine DefaultContainer
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.parentCont}
          >
              <Grid item xs={3} className={classes.leftSideCont}>
              </Grid>
              <Grid item xs={9} className={classes.rightSideCont}>
                  <Grid container className={classes.totalCont}>
                      <Grid item xs={12} className={classes.navBar}>
                          <Typography className={classes.userName}>{configJSON.navBarName}</Typography>
                          <span className={classes.navSpan}>
                            <img src={navBarImg} alt="" className={classes.navSpanImg}/>
                          </span>
                      </Grid>
                      <Grid container className={classes.rightContent}>
                          <Grid item xs={12} className={classes.headingCont}>
                              <div className={classes.headingLeft}>
                                  <img src={backIcon} alt="" className={classes.backIcon}/>
                                  <Typography className={classes.selectFilesText}>{configJSON.findShopsHeading}</Typography>
                              </div>
                              <img src={detailsIcon} alt="" className={classes.backIcon} onClick={this._handleAllShopsModal}/>
                          </Grid>
                          <Grid item xs={12} className={classes.mapsCont}>
                              <div className={classes.mapContainer}>
                                  {/* <LoadScript
                                    googleMapsApiKey="AIzaSyBYo5s0uQPFgc8qafyO0Rzejpe78bi4ezw"
                                  >
                                      <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={{
                                            lat:this.state.lat,
                                            lng: this.state.lng
                                        }}
                                        zoom={10}
                                        options={options}
                                      >
                                          <Marker
                                            onLoad={()=>console.log("Marker Loaded")}
                                            position={{
                                                lat:this.state.lat,
                                                lng: this.state.lng
                                            }}
                                            icon={markerIcon}
                                          />
                                          <></>
                                      </GoogleMap>
                                  </LoadScript> */}
                              </div>
                              <Button className={classes.findPrintersBtn} style={{backgroundColor:'#32795f',marginTop:20}}>{configJSON.continueBtn}</Button>
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
              <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={this.state.allShopsModal}
                // open={true}
                onClose={this._handleAllShopsModal}
                className={classes.modalParent}
              >
                  <div className={classes.modalContent}>
                      <div className={classes.modalHeadingCont}>
                          <Typography className={classes.modalHeading}>{configJSON.modal2Heading}</Typography>
                      </div>
                      <div className={classes.modalContentCont}>
                          <Typography className={classes.modalContentText}>{configJSON.searchRadius}</Typography>
                          <Select
                            input={<InputBase className={classes.selectInputModal}/>}
                          >
                              {
                                  radiusValues.map(item=>
                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                  )
                              }
                          </Select>
                          <Button className={classes.closeModalBtn} style={{backgroundColor:'#32795f',marginTop:20}}>{configJSON.modalBtnText}</Button>
                      </div>
                  </div>
              </Modal>
          </Grid>
        );
    }
}

// Customizable Area Start

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
    parentCont:{
        width:'100%',
        minHeight:'100vh',
        display:'flex'
    },
    leftSideCont:
      {
          backgroundColor: APP_COLORS.app_theme_yellow_color,
          // height:'100%',
      },
    rightSideCont:
      {
          // height:'70%',
          background:'#f7f7f7',
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
      },
    totalCont:{
        width:'90%',
        margin:"auto"
    },
    rightContent:{
        position:"relative",
        minHeight:'80%',
        width:'100%',
        background:`#ffffff`,
        borderRadius:12,
        marginBottom:40
    },
    headingCont:{
        width:'90%',
        margin:"auto",
        padding:"20px 30px",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end"
    },
    mapsCont:{
        width:'100%',
        margin:"auto",
        position:"relative",
        minHeight:"80vh",
        display:"flex",
        alignItems:"flex-end",
    },
    mapContainer:{
        height:"100%",
        width:"100%",
        position:"absolute",
        top:0,
        left:0
    },
    headingLeft:{
        width:'100%',
        margin:"auto",
        display:"flex",
        alignItems:"center"
    },
    selectFilesText:{
        fontSize:22,
        marginLeft:15,
        fontFamily:APP_FONTS.SofiaProRegular,
        textAlign: 'left',
        fontWeight:'bold',
        color:'#1e1f20'
    },
    findPrintersBtn:
      {
          backgroundColor:'#32795f',
          color:'white',
          borderRadius:12,
          width:'90%',
          margin:'20px auto 30px 30px',
          fontFamily:APP_FONTS.SofiaProRegular,
          height:55,
          maxWidth:300
      },
    contentLeft:
      {
          display:'flex',
          flexDirection:"column",
          alignItems:'center',
          justifyContent:'center'
      },
    navBar:{
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'center',
        margin:"20px 30px"
    },
    navSpan:{
        height:45,
        width:45,
        backgroundColor:'#32795f',
        display:'flex',
        alignItems: 'center',
        justifyContent:'center',
        borderRadius: 14,
        marginLeft: 15,
        marginRight:30
    },
    navSpanImg:{
        height:20,
        width:20,
        objectFit:'contain'
    },
    userName:{
        fontFamily:APP_FONTS.SofiaProRegular,
        color:'#1e1f20'
    },
    backIcon:{
        width:40,
        height:40,
        objectFit:'contain'
    },


    //Modal Styling
    modalParent:{
        // height:'100%',
        width:'100%',
        position:'relative'
    },
    modalContent:{
        position:'absolute',
        right:0,
        borderRadius:'32px 0 0 32px',
        width:'25%',
        minWidth:270,
        height:'100vh',
        padding:"20px 0",
        overflowY:"scroll",
        backgroundColor: APP_COLORS.app_theme_yellow_color
    },
    modalContentCont:{
        display:'flex',
        // marginLeft:30,
        flexDirection:'column',
        alignItems:'flex-start'
        // justifyContent:'center'
    },
    modalHeadingCont:{
        display:'flex',
        margin:"20px 0",
        alignItems:'flex-end',
        justifyContent:'flex-start'
    },
    modalHeading:{
        fontSize:22,
        marginLeft:20,
        fontFamily:APP_FONTS.SofiaProRegular,
        textAlign: 'left',
        color:'#1e1f20'
    },
    printerName:{
        fontSize:16,
        fontFamily:APP_FONTS.SofiaProRegular,
        color:'#1e1f20',
        marginTop:10,
        marginBottom:10,
    },
    printerAddress:{
        fontSize:15,
        fontFamily:APP_FONTS.SofiaProRegular,
        color:'#8f92a1',
        marginBottom:10
    },
    modalContentText:{
        fontSize:13,
        letterSpacing:1,
        margin:"20px 0 10px 20px",
        fontFamily:APP_FONTS.SofiaProRegular,
        textAlign: 'left',
        textTransform:'uppercase',
        color:'#1e1f20'
    },
    closeModalBtn:{
        border:'2px solid #32795f',
        color:'white',
        borderRadius:12,
        width:'90%',
        margin:' auto',
        fontFamily:APP_FONTS.SofiaProRegular,
        height:55,
        maxWidth:300
    },
    spanModalImg:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        marginLeft:20,
        marginRight:20,
        fontSize:16,
        fontFamily:APP_FONTS.SofiaProRegular,
        color:'#1e1f20'
    },
    colorModalDiv:{
        display:'flex',
        width:'100%',
        alignItems:'center',
        justifyContent:'flex-start'
    },
    pageInputModal:{
        width:48,
        height:48,
        paddingLeft:15,
        textAlign: 'center',
        fontSize: '1.2rem',
        color:"#1e1f20",
        borderRadius:4,
        marginLeft:20,
        fontFamily:APP_FONTS.SofiaProRegular,
        border:"1px solid rgba(143, 146, 161, 0.4)"
    },
    selectInputModal:{
        width:"90%",
        // height:48,
        padding:"5px 10px",
        marginLeft:20,
        fontFamily:APP_FONTS.SofiaProRegular,
        border:"1px solid rgba(143, 146, 161, 0.4)",
        color:"#1e1f20",
        borderRadius:4,
        backgroundColor:"transparent"
    },
    paperCopy:{
        height:45,
        width:60,
        marginBottom:7,
        objectFit:'contain'
    },
    colorCopy:{
        height:50,
        marginBottom:7,
        width:50,
        objectFit:'contain'
    },
    eachShop:{
        width:'80%',
        margin:"15px auto",
        backgroundColor:"white",
        borderRadius:12,
        padding:10
    },
    navigateCont:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginTop:10,
        marginBottom:10
    },
    navigateImg:{
        height:15,
        width:15,
        marginRight:10,
        objectFit:'contain'
    },
    navigateText:{
        background:"#fdd001",
        borderRadius:5,
        padding:"5px 8px",
        display:"flex",
        alignItems:"center",
        fontSize:15,
        fontFamily:APP_FONTS.SofiaProRegular,
        fontWeight:"bold",
        color:"white",
        cursor:"pointer"
    }

});
// Customizable Area End

// Customizable Area End
export default withStyles(styles, { withTheme: true })(MapFilter);
