// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import Scale from "../../../components/src/Scale";
import UploadDashBoardController, {
  Props,
  configJSON
} from "./UploadDashBoardController.Web";
import { Grid, Typography } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider";
import { withRouter,Redirect,Route,Switch } from "react-router";
import NewPrint from "./NewPrint.Web";
import SelectedDocuments from "./SelectedDocuments.Web";
import NearByShopsToPay from "../../Maps/src/NearByShopsToPay.Web";
import PaymentSuccessful  from "./PaymentSuccessful.Web";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";
import UserSupport from "../../user-profile-basic/src/UserSupport.Web";
import OrderPaymentDetails from "./OrderPaymentDetails.Web";

class UploadDashBoard extends UploadDashBoardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          username: loginData.name,
          profileImagePath: loginData.profile_image_path,
        });
      }
    });
  }
  
  render() {
    const { classes } = this.props;
       return (
        <Grid container item xs={12} className={classes.rightSideCont}>
          <Grid container className={classes.totalCont}>
            <Grid item xs={12} className={classes.navBar}>
              <Typography className={classes.userName}>
                {this.state.username}
              </Typography>
              <span className={classes.navSpan}>
                {this.state.profileImagePath ? (
                  <img
                    src={this.state.profileImagePath}
                    className={classes.navSpanImg}
                  />
                ) : (
                  <img
                    src={require("../assets/profile_icon.png")}
                    className={classes.navSpanImg}
                  />
                )}
              </span>
            </Grid>
            <Switch>
            <PrivateRoute role="Customer" path={`/UserSidebarNavigation/UploadDashBoard/NewPrint`}>
                <NewPrint />
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen`}>
              <SelectedDocuments />
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/UserSidebarNavigation/UploadDashBoard/SelectShop`}>
              <NearByShopsToPay />
            </PrivateRoute>
              <PrivateRoute role="Customer" path={`/UserSidebarNavigation/UploadDashBoard/Payment`}>
                <OrderPaymentDetails />
              </PrivateRoute>
            <PrivateRoute role="Customer" path={`/UserSidebarNavigation/UploadDashBoard/PaymentSuccessfullScreen`}>
              <PaymentSuccessful />
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/UserSupport/Support/Support`}>
              <UserSupport />
            </PrivateRoute>
            <Redirect exact from={'/UserSupport/Support'} to={'/UserSupport/Support/Support'}></Redirect> 
            <Redirect exact from={'/UserSidebarNavigation/UploadDashBoard'} to={'/UserSidebarNavigation/UploadDashBoard/NewPrint'}></Redirect>
            </Switch>
          </Grid>
        </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    rightSideCont: {
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 0px",
    },
    navSpan: {
      height: '45px',
      width: '45px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: '14px',
      marginLeft: '15px',
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
  });

export default withRouter(withStyles(styles, { withTheme: true })(UploadDashBoard));