// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Alert } from "react-native";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End
import { RouterProps } from "react-router";
export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  openDialog: any;
  classes: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  currentCountryCode: any;
  loading: boolean;
  username: string;
  profileImagUri: string;
  authToken: String;
  id: String;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  apiGetOTPLCallId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.UserProfilePictureUpdateMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "🇮🇳 +91",
      enableField: false,
      loading: false,
      username: "",
      profileImagUri: "",
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.phoneReg = new RegExp(/^[0-9]+$/);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.UserProfilePictureUpdateMessage) === message.id) {
      var profile_image_path = message.getData(
        getName(MessageEnum.UserProfilePictureDataMessage)
      );
      this.setState({ profileImagUri: profile_image_path });
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiDeleteProfileCallId) {
        if (responseJson && responseJson.Message) {
          this.props.hideLoader();
          this.props.showNotification(responseJson.Message, "success");
          const keys=  await StorageProvider.getKeys();
          await StorageProvider.removeMultiple(keys)
          await StorageProvider.set("user_type", this.state.user_type);
          setTimeout(() => {
            this.props.history.push("/PhoneLoginWeb");
          }, 1000);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );

      this.props.showNotification(AlertBodyMessage, "error");
    }
  }

  async accountDeleteApiCall() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProfileCallId = requestMessage.messageId;

    let deleteAccountEndPoint = ('' + configJSON.deleteAccountEndPoint + '' + this.state.id)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (deleteAccountEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area Start
  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
}
