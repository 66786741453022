export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const userLogo = require("../assets/login_logo_1.png");
export const userImg = require("../assets/profile_dummy.jpg");
export const logoutIcon = require("../assets/logout.png");
export const userIcon = require("../assets/user.png");
export const arrowIcon = require("../assets/arrow-right.png");
export const arrowRightIcon = require("../assets/arrow_right_settings.png");
export const phoneIcon = require("../assets/edit_phone.png");
export const loginPasscodeIcon = require("../assets/login_passcode.png");
export const cameraIcon = require("../assets/camera_icon_edit_profile.png");
export const profileIcon = require("../assets/profile_icon.png");
export const deleteIcon = require("../assets/delete_photo.png");
export const editProfileIcon = require("../assets/edit_profile.png");
export const editPhoneColorIcon = require("../assets/edit_phone_color.png");
export const editProfileColorIcon = require("../assets/edit_profile_color.png");
export const loginPasscodeColorIcon = require("../assets/login_passcode_color.png");
export const mapsIcons = require("../assets/map.svg");
export const payoutDetailsIcon = require("../assets/payout_details.png");
export const currencyIcon = require("../assets/currenyIcon.svg");
export const currencyColorIcon = require("../assets/currenyColorIcon.svg");
export const colorPrintIcon = require("../assets/oval.svg");
export const greyscaleIcon = require("../assets/oval-copy.svg");
export const bothIcon = require("../assets/both.png");
export const fileIcon = require("../assets/file.png");
export const arrowRightBlackIcon = require("../assets/arrow-right-black.svg");
export const userGreenIcon = require("../assets/userIconGreen.svg");
export const locationIcon = require('../assets/address_from_map.png');
export const arrowRightBlack = require("../assets/arrow-right-black.svg");