// @ts-nocheck
import React from "react";
import { Grid, Typography, Button,Dialog } from '@material-ui/core';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {colorLogo, group, group2, group3, group4, group5, group6, group8, groupLogo} from "./assets";
// Customizable Area End
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import OTPInputView from '../../../components/src/OTPInputView';
import OTPInputView6Digit from '../../../components/src/OTPInputView6Digit';
import StorageProvider from '../../../framework/src/StorageProvider';
import ForgotPasswordVerifyOtpControllerWeb, {
    Props,
    configJSON
} from "./ForgotPasswordVerifyOtpController.Web";
import Scale from "../../../components/src/Scale";
import { withRouter  } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";

class ForgotPasswordVerifyOtp extends ForgotPasswordVerifyOtpControllerWeb {
    timer = null;
    otpReference = React.createRef(null);
    constructor(props: Props) {
      super(props);
      this.scrollRef = React.createRef();
      // this.state = {
      //   alertMessage:"",
      //   alertOpen:false,
      // };
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    getOtp(OTP: string) {
      this.setState({otpError: false});
      this.setState({ OTP });
    }

    getNewPasscode(passcode: string) {
      this.setState({passcodError: false});
      this.setState({ passcode });
    }

    getConfirmedNewPasscode(confirmedPasscode: string) {
       this.setState({confirmPasscodeError: false});
       this.setState({matchPasscodeError: false});
       this.setState({ confirmedPasscode });
    }
    async componentWillUnmount() {
        clearInterval(this.timer);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.timer = setInterval(this.manageTimer, 1000);
        StorageProvider.get('fpData').then((res) => {
            const fpData = JSON.parse(res);
            if (fpData) {
                console.log("Res::: ", fpData);
                this.setState({
                    token: fpData.token,
                    fullPhoneNumber: fpData.countryCode + fpData.phone,
                    countryCode : fpData.countryCode,
                    phone : fpData.phone
                });
            }
        });
        // Customizable Area End
    }

    manageTimer = () => {
        var states = this.state
        if (states.counter === 0) {
            clearInterval(this.timer)
        }
        else {
            this.setState({
                counter: this.state.counter - 1
            });

        }
    }
    setFullPhoneNumber(fullPhoneNumber: string) {
        console.log("FP_VERIFY_OTP:==>", fullPhoneNumber);
        this.setState({ fullPhoneNumber: fullPhoneNumber })
    }
    resetAndResendOTP(){
      if(this.otpReference.current){
        this.otpReference.current.resetOTP();
      }
    }
    reset = () => {
      this.resendOTP();
    }
  scrollToTop=()=>{
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }
    handleAlert=(txt)=>{
      this.setState({alertMessage:txt},()=>
      {
        this.setState({alertOpen:true})
      })
    }

    closeAlert=()=>{
      this.setState({alertOpen:false})
    }
    // Customizable Area End
    
    render() {
        const { classes } = this.props;
        if (this.state.restartTimer) {
          this.setState({
            counter: 120,
          });
          this.timer = setInterval(this.manageTimer, 1000);
          this.setState({
            restartTimer: false,
          });
        }
        return (
            <Grid 
            container
            direction="row"
            justify="center"
            ref={this.scrollRef}
            >
            <Grid container className={classes.background}>
            <Grid item xs={12} sm={6} className={classes.leftSide}>
            <Typography className={classes.signup}>
              Forgot Passcode
            </Typography>
            <Typography className={classes.VerifyOTP}>
              {configJSON.labelVerifyOtp}
            </Typography>
            <Grid>
            <Typography className={classes.weHaveSentAnOne}>
              {this.labelVerifyOtpMessage + '+' + this.state.countryCode + " " + this.state.phone}
            </Typography>
            <Typography className={classes.OTP}>
              {configJSON.labelOtp}
            </Typography>
            <OTPInputView reset={this.reset} ref={this.otpReference} getOtp={(otp: string) => this.getOtp(otp)} />
            <p className={classes.errorLabel}>
              {this.state.otpError}
            </p>
            <Typography className={classes.didntGetTheOTP}>
              <span style={{color:"#565656"}}>  {configJSON.labelDidntGotOtp}</span>
              {
                this.state.counter !== 0 ?(
                  <span style={{textDecoration:'underline',marginLeft:7,cursor:"pointer"}}>Resend In 0{Math.floor(this.state.counter / 60)}:
                    {(this.state.counter % 60).toString().length > 1
                      ? this.state.counter % 60
                      : "0" + (this.state.counter % 60)}</span>
                ):(
                  <span
                    style={{textDecoration:'underline',marginLeft:7,cursor:"pointer"}}
                    onClick={() => this.resetAndResendOTP()}
                  >
                     Resend OTP
                  </span>
                )
              }
            </Typography>
            </Grid>
            
            <Typography className={classes.digitPasscod}> 
            {configJSON.labelYourPasscode}
            </Typography>
            <OTPInputView6Digit 
            getOtp={(otp: string) => this.getNewPasscode(otp)}
            className={classes.passCodeField}
            />
            <p className={classes.errorLabel}>
              {this.state.passcodError}
            </p>
            <Typography className={classes.digitPasscod}> 
            {configJSON.labelConfirmPasscode}
            </Typography>
            <OTPInputView6Digit 
            getOtp={(otp: string) => this.getConfirmedNewPasscode(otp)}
            className={classes.passCodeField}
            />
            <p className={classes.errorLabel}>
              {this.state.confirmPasscodeError}
              {this.state.matchPasscodeError}
            </p>
            <Button variant="contained" 
            className={classes.signUpButton}
            style={{background:APP_COLORS.app_theme_green_color}}
            onClick={() => this.validateOTPAndVerifyMobileCall()}
            >
            <p className={classes.signUpButtonTitle}>{configJSON.btnNextTitle}</p>
            </Button>
            <Button variant="contained"
                    className={`site-wht-btn`}
                    style={{background:APP_COLORS.app_theme_green_color,fontFamily:APP_FONTS.SofiaProRegular}}
                    onClick={()=>{
                      {
                        this.scrollToTop()
                        this.props.history.push("/PhoneLoginWeb")
                      }}}
            >
              Log In
            </Button>
            </Grid>
              <Grid item xs={12} sm={6} className="right-side">
                <img src={colorLogo} className="site-logo" />
                <Grid className="right-icons mt-70">
                  <div className={"icon_cont"}>
                    <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
                    <img src={group3}  className={"right-alert-img"} />
                  </div>
                  <div className={"icon_cont"}>
                    <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
                    <img src={group2}  className={"right-alert-img"} />
                  </div>
                  <div className={"icon_cont"}>
                    <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
                    <img src={group6}  className={"right-alert-img"} />
                  </div>
                </Grid>
                <Grid className="right-icons">
                  <div className={"icon_cont"}>
                    <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
                    <img src={group4}  className={"right-alert-img "} />
                  </div>
                  <div className={"icon_cont "}>
                    <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
                    <img src={group5}  className={"right-alert-img"} />
                  </div>
                  <div className={"icon_cont"}>
                    <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
                    <img src={group}  className={"right-alert-img"} />
                  </div>
                </Grid>
                <img src={group8} className="group-logo bt-80" />
              </Grid>
          </Grid>
        </Grid>
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
    background: {
      width: '90%',
      minHeight: '100vh',
      // margin:"auto",
      borderRadius: '32px',
      background: '#fff',
    },
    signup: {
      width: '175px',
      height: '30px',
      margin: '0 44px 30px 0',
      fontFamily: 'SofiaPro',
      fontSize: '21px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.36,
      letterSpacing: 'normal',
      color: '#1e1f20'
    },
    rectangle: {
      width: '106px',
      height: '32px',
      margin: '0px 18px 0px 0px',
      padding: '5px 10px !important',
      borderRadius: '4px !important',
      backgroundColor: '#8f92a1 !important'
    },
    label: {
      margin: '0 5px 0 0',
      fontFamily: 'SFProDisplay',
      fontSize: '14px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      color: '#ffffff',
      textTransform: 'lowercase'
    },
    userImg: {
      width: '14px',
      height: '14px',
      margin: '3px 0 3px 5px',
      objectFit: 'contain'
    },
    storeImg: {
      width: '14px',
      height: '14px',
      margin: '3px 0 3px 5px',
      objectFit: 'contain'
    },
    leftSide: {
      padding: '40px'
    },
   rightSide: {
     padding: '40px',
     borderRadius: '32px',
     backgroundColor: '#fdd001',
     margin:"0px auto",
     // height:'max-content',
     display:"flex",
     flexDirection:"column",
     justifyContent:"space-between",
     position: 'relative',
    },
    colorLogoTransparent: {
      marginLeft:"auto"
    },
    groupLogo: {
      width: '90%',
      margin:"30px auto",
      // height: '420px',
      objectFit: 'contain',
    },
    digitPasscod: {
      width: '163px',
      height: '20px',
      margin: '14px 175px 10px 0',
      fontFamily: 'SofiaPro',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.67,
      letterSpacing: '1px',
      color: '#1e1f20'
    },
    signUpButton: {
      width: '98%',
      height: '58px',
      margin: '20px auto',
      padding: '20px',
      borderRadius: '12px',
      backgroundColor: '#32795f'
    },
    signUpButtonTitle: {
      width: '35px',
      height: '20px',
      fontFamily: 'SofiaPro',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#ffffff'
    },
    OTP: {
      width: '73px',
      height: '30px',
      margin: '0 251px 7px 0',
      fontFamily: 'SofiaPro',
      fontSize: '15px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 2,
      letterSpacing: 'normal',
      color: '#1e1f20'
    },
    login: {
      width: '35%',
      height: '30px',
      margin: 'auto',
      fontFamily: 'SofiaPro',
      fontSize: '20px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      color: '#1e1f20'
    },
    weHaveSentAnOne: {
      width: '324px',
      height: '40px',
      margin: '7px 0 15px 0',
      fontFamily: 'SofiaPro',
      fontSize: '14px',
      fontWeight: 'normal',
      fontstretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      color: '#8f92a1'
    },
  VerifyOTP:{
      fontFamily:APP_FONTS.SofiaProRegular
  },
    OTP: {
      width: '27px',
      height: '20px',
      margin: '27px 30px 10px 0',
      fontFamily: 'SofiaPro',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.67,
      letterSpacing: '1px',
      color: '#1e1f20'
    },
    otpField: {
      // width: '48px',
      // height: '48px',
      // margin: '11px 9px 153px 0',
      // padding: '13px 20px 15px',
      // opacity: 0.2,
      // borderRadius: '4px',
      // border: 'solid 2px #8f92a1',
      opacity: 0.2,
      padding: '1px 16px 3px',
      borderRadius: '4px'
    },
    didntGetTheOTP: {
        // width: '217px',
        // height: '19px',
        margin: '10px  0 0 0',
        fontFamily: 'SofiaPro',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.35px',
        color: '#565656'
      },
      passCodeField: {
        // width: '46px',
        // height: '48px',
        // border: 'solid 2px #8f92a1'
        margin: '1px 8px 0 0',
        padding: '13px 18px 15px 20px',
        opacity: 0.2,
        borderRadius: '4px'
      },
      errorLabel: {
        color: "red",
        fontFamily:APP_FONTS.SofiaProRegular
      }
  });
  const ForgotPasswordVerifyOtpWithLoader = withLoader(ForgotPasswordVerifyOtp)
const ForgotPasswordVerifyOtpWithToast = withToast(ForgotPasswordVerifyOtpWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(ForgotPasswordVerifyOtpWithToast));// Customizable Area End
