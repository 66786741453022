//@ts-nocheck

import React, { useState } from "react";
// Customizable Area Start

// Customizable Area End

import UserOrderDetailsController, {
  Props,
  configJSON,
} from "./OrderDetailsController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Modal } from "@material-ui/core";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import { withRouter } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import {textFileIcon} from './assets'
import {
  docExcel,
  docImage,
  docWord,
  docPdf,
  docPowerPoint,
} from "../../UserDashBoard/src/assets";
import moment from "moment";
import { FusionTablesLayer } from "react-google-maps";

// import Moment from 'moment';
class OrderDetails extends UserOrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  _findNearByBttonTapped = async () => {
    let data: any = {};
    data.show_pay_button = false;
    await StorageProvider.remove("showPay");
    await StorageProvider.set("showPay", JSON.stringify(data));
    this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectShop");
  };

  // Customizable Area Start
  async componentDidMount() {
    //Customizable Area Start
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        authToken: loginData.token,
      });
    }
    let orderIdDetails = await StorageProvider.get("orderIdDetails");
    if (orderIdDetails) {
      orderIdDetails = JSON.parse(orderIdDetails);
      this.setState({
        orderId: orderIdDetails.id,
        from : orderIdDetails.from
      },()=>{
        this.getOrderDetailsApiCall();
      });
    }
    // Customizable Area End
  }
  getExpiryDateOfDoc = (createdAt: any) => {
    var now = new Date().getTime();
    var countDownTime = new Date(createdAt)
        countDownTime = countDownTime.setHours(countDownTime.getHours() + 4 )
    var distance = countDownTime - now;
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    if(hours > 0){
      hours = hours.toString().length > 1 ? hours : "0" + hours;
      minutes = minutes.toString().length > 1 ? minutes : "0" + minutes; 
      const time = "Expiry In " + hours + ":" + minutes + " hours" 
      return time;   
    } else if(hours === 0){
      hours = hours.toString().length > 1 ? hours : "0" + hours;
      minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
      const time = "Expiry In " + hours + ":" + minutes + " minutes"
      return time;
    } 
  };
  // Customizable Area End
  fileType(fileType: String) {
    const ImageallowedExtensions = /(jpg|jpeg|png|gif|svg)$/i;
    const pptAllowedExtension = /(ppt|pptx)$/i;
    const docAllowedExtension = /(doc|docx)$/i;
    const pdfAllowedExtension = /(pdf)$/i;
    const excelAllowedExtension = /(xlsx|xls|csv)$/i;
    if (ImageallowedExtensions.exec(fileType)) {
      return docImage;
    } else if (pptAllowedExtension.exec(fileType)) {
      return docPowerPoint;
    } else if (docAllowedExtension.exec(fileType)) {
      return docWord;
    } else if (pdfAllowedExtension.exec(fileType)) {
      return docPdf;
    } else if (excelAllowedExtension.exec(fileType)) {
      return docExcel;
    }
    return docExcel;
  }
  completeOrder = (event) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === "Yes") {
        this.updateOrderStatus(true);
      }
    };
    this.props.openDialog(
      "Finish Order",
      "All Set? Are you sure want to finish this order?",
      onClose
    );
  }

  render() {
    const { classes } = this.props;
    let orderStatus =""
    if ((this.state?.resData?.status != undefined)) {
      if (this.state.resData.status == 'Pending') {
          orderStatus = "Pending";
      } else if (this.state.resData.status == 'Completed') {
          orderStatus = "Completed";
      } else if (this.state.resData.status == 'Printing') {
          orderStatus = "Printing";
      } else if (this.state.resData.status == 'Printingcompleted') {
          orderStatus = "Printing Completed";
      } else if (this.state.resData.status == 'Refunded') {
          orderStatus = "Refunded";
      } else if (this.state.resData.status == 'Paymentcompleted') {
          orderStatus = "Payment Completed";
      } else if(this.state.resData.status == 'Autocancel'){
        orderStatus = "Auto Canceled";
      }
    }
    return (
      <Grid container className={classes.rightContent}>
            <Grid item xs={12}>
              <div className={classes.headingLeft}>
                <img src={require('../assets/backIcon.png')} onClick={()=>{this.state.from === 'AllOrder' ? 
                      this.props.history.push('/OrderManagement/OrderHistory') :
                      this.props.history.push('/OrderManagement/QRCode')}
                    }
                 alt="" className={classes.backIcon} />
                 <Typography className={classes.pageHeader}>
                   Order Detail
                </Typography>
              </div>
            </Grid>
           
        <Grid container className={classes.qrContainer}>
          <Grid item xs={12} sm={6} className={classes.orderDetailsCont}>
            <Typography className={classes.selectFilesText}>
              Order #{this?.state?.resData?.order_number}
            </Typography>
            <Typography className={classes.orderMerchantTxt}>
            {(("with "+ this.state.resData?.merchant_details?.shop_name && this.state.resData?.merchant_details?.shop_name))}
            </Typography>
            <Typography className={classes.orderDetailsText}>
            {(moment(this.state.resData.created_at).format('DD/MM/yyyy hh:mm A'))}
            </Typography>
            <Typography className={classes.orderDetailsText}>
              {orderStatus}
                  {/* {this.state.from !== 'AllOrder' ? orderStatus : this.state.resData.status} */}
            </Typography>
            <Typography className={classes.orderDetailsText}>
            
              {orderStatus === 'Pending' || orderStatus === 'Payment Completed' && this.getExpiryDateOfDoc(this.state.resData.created_at) }
            </Typography>
            <Typography className={classes.orderDetailsText}>
             {this?.state?.resData?.total_amount && `Total Paid: Rs.${parseFloat(this?.state?.resData?.total_amount.toString()).toFixed(2)}`}
            </Typography>
            {orderStatus !== 'Autocancel' && orderStatus !== 'Completed' && <Typography className={classes.orderDetailsText}>
            {this?.state?.resData.total_docs ? `${this?.state?.resData.total_docs} Documents`  : this?.state?.resData.total_docs===1 ?  '1 Document' : null} 
            </Typography>
         }
          </Grid>
          <Grid item xs={12} sm={6} className={classes.contentLeft}>
          {(orderStatus === 'Pending' || orderStatus === 'Payment Completed') ?
            <>
            <img
              src={'data:image/png;base64,'+this.state?.resData?.qrcode_b64}
              alt="QR Code"
              className={classes.barCodeImg}
            />
            <Typography className={classes.scanCode}>
              Scan this QR code at any printer shop from list or shop of your
              choice to get your print out
            </Typography>
          </>
            :""}
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.docsCont}>
        {
          this.state.resData.status === 'Autocancel' 
           && <Grid item xs={12}>
             <Typography className={classes.scanCode}>
                  Order is autocancel so all the documents has been removed
              </Typography>
          </Grid>
            
        }
        {
          this.state.resData.status === 'Printingcompleted' 
           && <Grid item xs={12}>
             <Typography className={classes.scanCode}>
                  Printing has been completed,
                  <br/>
                  Tap on finish order to complete the order
              </Typography>
          </Grid>
            
        }
        {
          this.state.resData.status !== 'Printingcompleted' && this.state.resData.status !== 'Autocancel' && this.state.resData.status !== 'Pending'
            && this.state.resData.status !== 'Paymentcompleted'
           && <Grid item xs={12}>
             <Typography className={classes.scanCode}>
                  { `No files are visible because order is ${orderStatus}`}
              </Typography>
          </Grid>
            
        }

        {(orderStatus === 'Ready For Print' ||  orderStatus === 'Payment Completed') && this.state.dataSource.map((file) => {
          return (
              <Grid key={file.id} item xs={12} sm={6} md={3}>
                      <Grid item xs={12} className={file?.colour ? classes.eachDocs : classes.eachDocsSettingNotApply }>
                        {(file.content_type === 'application/msword') || (file.content_type === 'application/vnd.oasis.opendocument.text') || (file.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? (<img src={require('../assets/docword.png')} className={classes.docImg} />) :
                      (file.content_type === 'image/gif') || (file.content_type === 'image/png') || (file.content_type === 'image/jpg') || (file.content_type === 'image/jpeg') ? (<img src={require('../assets/image_placeholder.jpg')} className={classes.docImg} />) :
                          (file.content_type === 'application/pdf') ? (<img src={require('../assets/pdf_placeholder.png')} className={classes.docImg} />) :
                            (file.content_type === 'text/plain') ? (<img src={textFileIcon} className={classes.docImg} />) :
                              (file.content_type === 'application/vnd.ms-powerpoint') || (file.content_type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') || (file.content_type === 'application/vnd.oasis.opendocument.presentation') ? (<img src={require('../assets/ppt.png')} className={classes.docImg} />) :
                                  (file.content_type === 'application/vnd.ms-excel') || (file.content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.content_type === 'application/vnd.oasis.opendocument.spreadsheet') ? (<img src={require('../assets/docexcel.png')} className={classes.docImg} />) :
                                      (<img src={require('../assets/pdf_placeholder.png')} className={classes.docImg} />)}
              
                        <Typography className={classes.docTitle}>
                          {file?.file_name ? file?.file_name : ""}
                        </Typography>
                        <Typography className={classes.docContent}>
                        All Pages - {file?.colour && file?.colour  }
                        </Typography>
                        <Typography className={classes.docContent}>
                          {file?.layout && file?.layout} - { file?.page_size && file?.page_size}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {file?.print_pages_to && file?.print_pages_to} copy
                        </Typography>
            </Grid>
          </Grid>)})
          }
        </Grid>
        <Grid item xs={11} className={classes.btnCont}>
      {this.state.resData.status == undefined ? null : 
      ((this.state.resData.status == 'Pending')) ? <Button
                  className={classes.findPrintersBtn}
                  style={{ backgroundColor: "#32795f" }}
                  onClick={() => this._findNearByBttonTapped()}
                >
            FIND PRINTERS NEARBY
          </Button> : null} 
        </Grid>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.openAllPrintModel}
          onClose={()=>{
            this.props.history.push('/OrderManagement/OrderHistory/History');
          }}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                Print Order #{this?.state?.resData?.order_number}
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
              <img
                src={
                  !false
                    ? require("../assets/print.svg")
                    : require("../assets/printDocument.png")
                }
                alt=""
                className={classes.modalImg}
              />
              <Typography className={classes.modalContentText}>
                {true
                  ? "All Set"
                  : "Printing Documents"}
              </Typography>
              <Typography className={classes.modalContentText}>
                {!false
                  ? `Printed ${this.state.dataSource.length} Docs`
                  : `Printed ${this.state.dataSource.length} Docs`}
              </Typography>
              {true ? (
                <Button
                  style={{ backgroundColor: "#32795f" }}
                  className={classes.closeModalBtn}
                  onClick={this.completeOrder}
                >
                  Finish Order
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#32795f" }}
                  className={classes.closeModalBtn}
                  onClick={this.finishPrinting}
                >
                  Finish Printing
                </Button>
              )}
            </div>
          </div>
        </Modal>
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
    },
    leftSideCont: {
      backgroundColor: APP_COLORS.app_theme_yellow_color,
      // height:'100%',
    },
    rightSideCont: {
      // height:'70%',
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headingLeft: {
      width: "95%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      marginTop : '2%'
    },
    backIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor : "pointer"
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    qrContainer: {
      width: "90%",
      margin: "50px auto",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    selectFilesText: {
      fontSize: 22,
      margin: "0 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    pageHeader: {
      fontSize: 22,
      marginLeft: "5px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    orderDetailsCont: {
      marginTop: 15,
      width: "100%",
    },
    orderDetailsText: {
      fontSize: 16,
      margin: "5px 0 5px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    orderMerchantTxt: {
      fontSize: 16,
      margin: "5px 0 5px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
    },
    scanCode: {
      fontSize: 14,
      width: "80%",
      margin: "20px auto 0 auto",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
    },
    orderDetailsName: {
      fontSize: 20,
      margin: "8px 0 8px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docsCont: {
      width: "95%",
      margin: "0 auto 20px",
    },
    eachDocs: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      // height:250,
      // width:'90%',
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docNav: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    docNavImg: {
      height: 16,
      width: 16,
      marginRight: 5,
      objectFit: "contain",
    },
    docTitle: {
      fontWeight: "bold",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
      wordBreak:"break-all",
      margin: "10px 0",
      wordBreak: 'break-all',
    },
    docImg: {
      height: 50,
      width: 65,
      margin: "10px auto",
      objectFit: "contain",
    },
    barCodeImg: {
      width: 240,
      height: 240,
      objectFit: "contain",
      margin: "20px auto 0 auto",
    },
    docContent: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
    },
    btnCont: {
      margin: "auto",
      marginTop: "20px",
      borderRadius: 12,
    },
    qrCont: {
      margin: "auto",
      marginTop: "20px",
      paddingLeft: 20,
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    modalImg:{
      height: 80,
      width: 80,
      objectFit: "contain",
      marginTop:50
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    closeModalBtn:{
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "40px auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    modalContentText: {
      fontSize: 14,
      margin: "10px auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    modalContentCont: {
      display: "flex",
      height: "85%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    modalHeadingCont: {
      height: "15%",
      minWidth: 220,
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      height: "100%",
      width: "25%",
      minWidth: 220,
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    closeModalBtn:{
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "40px auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    }
  });
// Customizable Area End
const OrderDetailsWithConfirmBox = withConfirmBox(OrderDetails);
const OrderDetailsWithLoader = withLoader(OrderDetailsWithConfirmBox)
const OrderDetailsWithToast = withToast(OrderDetailsWithLoader)

export default withRouter(withStyles(styles, { withTheme: true })(OrderDetailsWithToast));