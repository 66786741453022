//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithRouter,RouterProps } from "react-router";
// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export type Props =  RouterProps & {
    // Customizable Area Start
    id: string;
    authToken: string;
    filePath: any,
    fileName: any;
    fileType: any;
    imageUri: any,
    openDialog : any;
    showNotification : any;
    showLoader : any;
    hideLoader : any;
  
    // Customizable Area End
}
export type attachment = {
    id: number;
    account_id: number;
    colour: string;
    layout: string;
    page_size: string;
    scale: string;
    print_sides: string;
    print_pages_from: string;
    print_pages_to: string;
    total_pages: null,
    is_expired: boolean,
    attachment_path: string;
    content_type: string,
    file_name: string
    
}
export type attachmentList = {
          id: String,
          type: String;
          attributes: attachment;
        } 

interface S {
    // Customizable Area Start
    text: any,
    loading: boolean;
    uploading : number;
    id: string;
    selectedFileList : attachmentList[],
    uploadedFiles : File[],
    attachmentIds: Array<string>;
    authToken: string;
    pagePropertiesModal:boolean,
    pagePropertiesForSelectedFile : attachmentList | null
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class PagePrintSettingsController extends BlockComponent<
    Props,
    S,
    SS
    > {
    apiUpdatePrintPropertiesCallId: String = "";
    apiDeletePrintPropertiesCallId : String = ""
    apiPlaceOrderCallId : String = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),

        ]
        // Customizable Area Start
        this.state = {
            text: true,
            loading: false,
            id: "",
            authToken: "",
            colorActive: true,
            pagePropertiesModal:false,
            selectedFileList : [],
            uploadedFiles : [],
            attachmentIds: [],
            pagePropertiesForSelectedFile : null,
            uploading : 0,
            continueModal:false
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiUpdatePrintPropertiesCallId) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.meta) {
                    if(responseJson && responseJson.data){
                        this.props.showNotification(responseJson.meta.message,'success');
                        const selectedFileList = [...this.state.selectedFileList];
                        const data = Array.isArray(responseJson.data) ? responseJson.data : [responseJson.data];
                        for (let index = 0; index < data.length; index++) {
                            const newFile = data[index];
                            const newSelectedFileIndex = selectedFileList.findIndex((file)=> {
                                return file.id == newFile.id});
                            let newSelectedFile = selectedFileList[newSelectedFileIndex];
                                newSelectedFile =  {...newSelectedFile,attributes:{...newFile.attributes}};
                                selectedFileList[newSelectedFileIndex] = newSelectedFile;        
                        }
                        this.setState({selectedFileList:selectedFileList},async ()=>{
                            await StorageProvider.remove('selectedFiles')
                            await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
                        })
                    }
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
                    this.sendAPIFailMessage();
                }
            } else if(this.apiDeletePrintPropertiesCallId === apiRequestCallId){
                this.setState({ loading: false });
                if (responseJson && responseJson.meta) {
                    this.props.hideLoader();
                    this.props.showNotification("Attachment deleted successfully","success")
                    const selectedFileList = [...this.state.selectedFileList];
                    const newSelectedFileList = selectedFileList.filter((file)=> {return file.id != this.attachmentId});
                    this.setState({selectedFileList : newSelectedFileList},async ()=>{
                    await StorageProvider.remove('selectedFiles')
                    await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
                    })
                    this.props.showNotification(responseJson.meta.message,'success');
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
                    this.sendAPIFailMessage();
                }
            }
            else if (apiRequestCallId === this.apiPlaceOrderCallId) {
                this.setState({loading: false})
                if (responseJson && responseJson.data && responseJson.data.id) {
                    let data: any = {}
                    data.orderId = responseJson.data.id;
                    data.goBack = false;
                    await StorageProvider.remove('orderId');
                    await StorageProvider.set('orderId', JSON.stringify(data));
                    this.props.hideLoader();
                    this.props.showNotification(responseJson?.meta?.message, 'success');
                    this.props.history.push('/UserSidebarNavigation/UploadDashBoard/Payment');

                }
            }
        }
        // Customizable Area End
    }

    setDefaultPropertiesForFile(fileList : any[]) {
        const attributes = {
                    colour: "greyscale",
                    layout: "potrait",
                    page_size: "A4",
                    scale: "Print all pages",
                    print_sides: "One",
                    print_pages_from: 0,
                    print_pages_to: 0,
          }
          for(let i=0;i<fileList.length;i++){
              fileList[i] = {...fileList[i],attributes:{...fileList[i].attributes,...attributes}}
          }
          return fileList;
      }

    deleteAttachMent = (attachmentId) => {
        const header = {
            "token" : this.state.authToken,
            "Content-Type": configJSON.contentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.props.showLoader();
        this.apiDeletePrintPropertiesCallId = requestMessage.messageId;
        this.attachmentId = attachmentId;
        let deletePrintPropertiesEndPoint = ('' + configJSON.deleteAPiEndPoint).split('attachment_id').join('' + attachmentId);
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            deletePrintPropertiesEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteAPiEndMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updatePrintProperties(isAll: boolean) {
        const formData = new FormData();
        //  this.setState({ totalPage: this.state.numberOfPages + this.state.numberPagesTo })
        let totalPage: any = (Number(this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to) - Number(this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from) + 1)
        console.log("totalPage - TO-Print==>", totalPage);

        formData.append('token', this.state.authToken);
        formData.append('data[account_id]', this.state.id);
        formData.append('attachment_data[colour]', this.state.pagePropertiesForSelectedFile?.attributes.colour);
        formData.append('attachment_data[layout]', this.state.pagePropertiesForSelectedFile?.attributes.layout);
        formData.append('attachment_data[page_size]', this.state.pagePropertiesForSelectedFile?.attributes.page_size);
        formData.append('attachment_data[scale]', this.state.pagePropertiesForSelectedFile?.attributes.scale);
        formData.append('attachment_data[print_sides]', this.state.pagePropertiesForSelectedFile?.attributes.print_sides);
        formData.append('attachment_data[print_pages_to]', this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to);
        formData.append('attachment_data[print_pages_from]', this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from);
        formData.append('attachment_data[total_pages]', (totalPage));
        if (isAll) {
            formData.append('attachment_data[set_all]', '1');
        }
        this.setState({ loading: true });
        this.props.showLoader();

        const header = {
            "accept": configJSON.contentType
        };


        let savePrintPropertiesEndPoint = ('' + configJSON.savePrintPropertiesEndPoint).split('attachment_id').join('' + this.state.pagePropertiesForSelectedFile?.attributes.id);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiUpdatePrintPropertiesCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            savePrintPropertiesEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPiEndMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    async createOrder(){
        let selectedFiles = await StorageProvider.get("selectedFiles")
        const selectedAttachment = selectedFiles && JSON.parse(selectedFiles);
        let attachmentIds=[]
        if (selectedAttachment) {
             attachmentIds = selectedAttachment.map(
              (attachment) => attachment.id
            );
        }
        this.setState({
            attachmentIds: attachmentIds
        },()=>{
            const header = {
                "Content-Type": configJSON.contentType,
                "token": this.state.authToken
            };
            const data = {
                account_id: Number(this.state.id),
                status: 'pending',
                total_docs: Number((this.state.attachmentIds).length),
                attachment_ids: this.state.attachmentIds
            };
            const httpBody = {
                data: data
            };
            this.setState({ loading: true });

            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiPlaceOrderCallId = requestMessage.messageId;

            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.placeOrderAPiEndPoint
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.postAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        })
    }
    uploadFile() {
        let formData = new FormData();
        formData.append("token", this.state.authToken);
        formData.append("data[account_id]", this.state.id);
        // console.log(this.state.fileName, "filenamessss", this.state.authToken)
        if (this.state.uploadedFiles.length > 0) {
          for (let i = 0; i < this.state.uploadedFiles.length; i++) {
            formData.append(
              `data[attachments][${i}][attachment]`,
              this.state.uploadedFiles[i]
            );
          }
        }
        this.props.showLoader();
        const baseURL =
          "https://secureprinting-37636-ruby.37636.dev.ap-southeast-1.aws.svc.builder.cafe";
        let request = new XMLHttpRequest();
        request.open("POST", baseURL + "/" + configJSON.uploadFilesEndPoint);
        request.setRequestHeader("accept", configJSON.contentType);
        request.upload.addEventListener(
            "loadstart",
            () => {
              this.props.showLoader();
              this.setState({ uploading: 0 });
            }
          );
          request.upload.addEventListener(
            "progress",
            (e) => {
              if (e.lengthComputable) {
                var percentage = Math.round((e.loaded / e.total) * 100);
                this.setState({ uploading: percentage });
              }
            }
          );
        request.addEventListener(
          "load",
          async function(e) {
            this.props.hideLoader();
            this.props.showNotification("file successfully uploaded","success")
            let data = JSON.parse(request.response)?.data || [];
            let newSelectedFiles = [...data,...this.state.selectedFileList];
            //  newSelectedFiles = this.setDefaultPropertiesForFile(newSelectedFiles);
            this.setState({selectedFileList : newSelectedFiles,uploadedFiles : []},async ()=>{
                await StorageProvider.remove('selectedFiles')
                await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
            })
          }.bind(this)
        );
        request.send(formData);
      }

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

   
}