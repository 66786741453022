export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const facebookIcon = require("../assets/facebook.svg");
export const githubIcon = require("../assets/git-hub-copy.svg");
export const linkedinIcon = require("../assets/linkedin.svg");
export const twitterIcon = require("../assets/twitter.svg");
export const instgramIcon = require("../assets/instagram.svg");
export const macbookImg = require("../assets/macbook-scene.png");
export const macbookImg2 = require("../assets/macbook-scene@2x.png");
export const macbookImg3 = require("../assets/macbook-scene@3x.png");
export const addUser = require("../assets/add-user.svg");
export const group7 = require("../assets/group-7.svg");
export const group12 = require("../assets/group-12.svg");
export const group3 = require("../assets/group-3.svg");
export const prevIcon = require("../assets/prevIcon.svg");
export const nextIcon = require("../assets/nextIcon.svg");
export const storyIcon = require("../assets/stories@3x.png");
export const banner = require("../assets/bannerBackground.svg");
export const logo = require("../assets/logo_transparent_new.gif");
export const bannerResize = require("../assets/bannerBackground-resize.svg");
export const AppStore = require("../assets/aivalable-on-the-app-store-2.svg");
export const PlayStore = require("../assets/google-play-badge@3x.jpg");
export const QRcode = require("../assets/qr-code.svg");
export const slidesImages = [
  require("../assets/icons-data-privacy.svg"),
  require("../assets/icons-data-encryption.svg"),
  require("../assets/icons-multi-factor-authentication.svg"),
  require("../assets/icons-document-security.svg"),
  require("../assets/icons-secure-download-hassle-free.svg"),
  require("../assets/icons-document-delivery-to-desired.svg")
];
export const banner2Img1=[require("../assets/bannerTwo/group-3.png"),require("../assets/bannerTwo/group-3@2x.png"),require("../assets/bannerTwo/group-3@3x.png")]
export const banner2Img2=[require("../assets/bannerTwo/group-4.png"),require("../assets/bannerTwo/group-4@2x.png"),require("../assets/bannerTwo/group-4@3x.png")]
export const banner2Img3=[require("../assets/bannerTwo/group-2.png"),require("../assets/bannerTwo/group-2@2x.png"),require("../assets/bannerTwo/group-2@3x.png")]
export const banner3Img=[require("../assets/14972-nq-16-vm.png"),require("../assets/14972-nq-16-vm@2x.png"),require("../assets/14972-nq-16-vm@3x.png")]
export const homeicon=require("../assets/homeicon.svg")
export const abouticon=require("../assets/abouticon.svg")
export const servicesicon=require("../assets/coloricon.svg")
export const contactusicon=require("../assets/phoneicon.svg")
export const usericon=require("../assets/user.svg")
export const arrowdown=require("../assets/arrow-down.svg")
export const closeicon=require("../assets/close.svg")
export const menuicon=require("../assets/menuIcon.svg")
