// @ts-nocheck
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  userLogo,
  userImg,
  logoutIcon,
  userIcon,
  arrowIcon,
  phoneIcon,
  arrowRightIcon,
  loginPasscodeIcon,
  profileIcon,
  editPhoneColorIcon,
  editProfileColorIcon,
  loginPasscodeColorIcon,
  editProfileIcon,
  currencyIcon,
  arrowRightBlack,
  currencyColorIcon,
} from "./assets";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";

import MerchantSettingsController, {
  Props,
  configJSON,
} from "./MerchantSettingController.Web";
import { Switch, Route, Link, NavLink } from "react-router-dom";
import TermsAndCondition from "./TermsAndConditionBlock.Web";
import LicenseAgreement from './LicenseAgreement.Web'
import PrivacyPolicyBlock from "./PrivacyPolicyBlock.Web";
import { Redirect, withRouter } from "react-router";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";
import ChangePhoneNumber from "../../../blocks/user-profile-basic/src/ChangePhoneNumberBlock.Web";
import ChangePasscode from "../../../blocks/user-profile-basic/src/ChangePasscodeBlock.Web";
import EditPayoutDetails from "./EditPayoutDetails.Web";
import EditMerchantBusinessDetails from "./EditMerchantBusinessDetails.Web";
import SoftwareInformation from "./SoftwareInformation.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
class MerchantSettings extends MerchantSettingsController {
  constructor(props: Props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name,
          profileImagUri: loginData.profile_image_path,
          user_type : loginData.user_type,
          authToken: loginData.token,
          id: loginData.id
        });
      }
    });
  }

  refreshStorageData = async () => {
    StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name,
          profileImagUri: loginData.profile_image_path,
        });
      }
    });
  };
  updateCounter=()=>{
    console.log("update counter")
    this.refreshStorageData()
  }
  isActiveLink = (pathName) => {
    let { location } = this.props;
    const path = location.pathname.toString();
    if (path.includes(pathName)) {
      return true;
    }
    return false;
  };
  navigationLink = [
    {
      name: "Edit Business Details",
      path: "/MerchantSettings/Settings/EditBusinessDetails",
      currentPath: "EditBusinessDetails",
      img: editProfileColorIcon,
      unImg: editProfileIcon,
    },
    {
      name: "Change Phone Number",
      path: "/MerchantSettings/Settings/ChangePhoneNumber",
      currentPath: "ChangePhoneNumber",
      img: editPhoneColorIcon,
      unImg: phoneIcon,
    },
    {
      name: "Change Login Passcode",
      path: "/MerchantSettings/Settings/ChangePasscode",
      currentPath: "ChangePasscode",
      img: loginPasscodeColorIcon,
      unImg: loginPasscodeIcon,
    },
    {
      name: "Edit Payout Details",
      path: "/MerchantSettings/Settings/EditPayoutDetails",
      currentPath: "EditPayoutDetails",
      img: currencyColorIcon,
      unImg: currencyIcon,
    },
    {
      name: "Delete My Account",
      path: "/DeleteMyAccount",
      currentPath: "DeleteMyAccount",
      img: require("../assets/delete_account.png"),
      unImg: require("../assets/delete_account.png"),
    },
    {
      name: "Logout",
      path: "/logout",
      currentPath: "Logout",
      img: logoutIcon,
      unImg: logoutIcon,
    },
  ];

  bottomNavigationLinks=[
    {
      label:"Privacy Policy",
      path:"/MerchantSettings/Settings/PrivacyPolicy"
    },
    {
      label:"Terms & Conditions",
      path:"/MerchantSettings/Settings/Terms"
    },
    {
      label:"End User License Agreement",
      path:"/MerchantSettings/Settings/LicenseAgreement"
    },
    {
      label:"Additional Software Information Copyright",
      path:"/MerchantSettings/Settings/SoftwareInformation"
    }
  ]


  deleteMyAccountConfirmation(){
    const { openDialog } = this.props;
    const onClose = (msg: any) => {
      if (msg === "Yes") {
        this.accountDeleteApiCall();
      }
    };
    this.props.openDialog(
      "Delete Account Confirmation",
      "Are you sure want to delete account?",
      onClose
    );
  }





  logoutConfirmationDialog = () => {
    const { openDialog } = this.props;
    const onClose = (msg: any) => {
      if (msg === "Yes") {
        this.clearStorageAndredirectToLogin();
      }
    };
    this.props.openDialog(
      "Logout Confirmation",
      "Are you sure want to logout?",
      onClose
    );
  };

  clearStorageAndredirectToLogin = async () => {
    
    const keys=  await StorageProvider.getKeys();
    await StorageProvider.removeMultiple(keys)
    // await StorageProvider.remove("loginData");
    // await StorageProvider.remove("signUpData");
    // await StorageProvider.remove("selectedFiles");
    // await StorageProvider.remove("showPay");
    // await StorageProvider.remove("orderId");
    // await StorageProvider.remove("orderIdDetails");
    // await StorageProvider.remove("user_type")
    await StorageProvider.set("user_type",this.state.user_type)
    this.props.history.push("/PhoneLoginWeb");
  };
  navigateTo = (pathName) => {
    if (pathName === "/logout") {
      this.logoutConfirmationDialog();
    } else if(pathName === "/DeleteMyAccount") {
      this.deleteMyAccountConfirmation();
    } else {
      this.props.history.push(pathName);
    }
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      <Grid container  className={classes.leftSidebar}>
        <Grid item xs={12} sm={4} className={classes.leftSidebar} >
          <Grid container justify={'space-between'} className={classes.parentContainer}>
            <Grid item xs={6}>
              <p className={classes.labelSetting}>{configJSON.labelSetting}</p>
            </Grid>
            <Grid item xs>
              {this.state.profileImagUri ? (
                <img
                  src={this.state.profileImagUri}
                  className={classes.settingAvatar}
                />
              ) : (
                <img src={profileIcon} className={classes.settingAvatar} />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify={"space-between"}
            alignItems="center"
          >
            {this.navigationLink.map((navItem) => {
              return this.isActiveLink(navItem.currentPath) ? 
              <Grid key={navItem.name} item xs={10} className={classes.editProfileColorButton} onClick={()=>{this.navigateTo(navItem.path)}}>
                <img
                  src={navItem.img}
                  className={classes.editProfileButtonIcon}
                />
              <span
                to={navItem.path}
                className={classes.editProfileColorButtonTxt}
              >
                {navItem.name}
              </span>
              <img
                src={arrowRightIcon}
                className={classes.editProfileArrowIcon}
              /></Grid> : 
              <Grid key={navItem.name} item xs={10} className={classes.editProfileButton} onClick={()=>{this.navigateTo(navItem.path)}}>
              <img
                src={navItem.unImg}
                className={classes.editProfileButtonIcon}
              />
            <span
              to={navItem.path}
              className={classes.editProfileButtonTxt}
            >
              {navItem.name}
            </span>
            <img
              src={arrowRightBlack}
              className={classes.editProfileArrowIcon}
            /></Grid> })}
          </Grid>
          {
            this.bottomNavigationLinks.map((item,index)=>
              <Typography className={classes.tabBottomText} style={index===0?{marginTop:30}:null} onClick={()=>{this.navigateTo(item.path)}} >{item.label}<img src={arrowRightBlack} alt="" className={classes.tabNextIcon}/></Typography>
            )
          }
          {/*<Typography className={classes.tabBottomText} style={{marginTop:30}} onClick={()=>{this.navigateTo(navItem.path)}} >{configJSON.labelPrivacy}<img src={arrowRightBlack} alt="" className={classes.tabNextIcon}/></Typography>*/}
          {/*<Typography className={classes.tabBottomText}>{configJSON.labelTerms}<img src={arrowRightBlack} alt="" className={classes.tabNextIcon}/></Typography>*/}
          {/*<Typography className={classes.tabBottomText}>{configJSON.labelEndUser}<img src={arrowRightBlack} alt="" className={classes.tabNextIcon}/></Typography>*/}
          {/*<Typography className={classes.tabBottomText}>{configJSON.labelAdditional}<img src={arrowRightBlack} alt="" className={classes.tabNextIcon}/></Typography>*/}
        </Grid>
        <Grid item xs={12} sm={8} className={classes.rightBackground} ref={this.scrollRef}>
          <Switch>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/EditBusinessDetails`}
            >
              <EditMerchantBusinessDetails mode="edit" renderComp={this.updateCounter}/>
            </PrivateRoute>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/ChangePhoneNumber`}
            >
              <ChangePhoneNumber />
            </PrivateRoute>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/ChangePasscode`}
            >
              <ChangePasscode />
            </PrivateRoute>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/EditPayoutDetails`}
            >
              <EditPayoutDetails mode="edit" />
            </PrivateRoute>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/Terms`}
            >
              <TermsAndCondition />
            </PrivateRoute>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/LicenseAgreement`}
            >
              <LicenseAgreement />
            </PrivateRoute>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/SoftwareInformation`}
            >
              <SoftwareInformation />
            </PrivateRoute>
            <PrivateRoute
              role="Merchant"
              exact
              path={`/MerchantSettings/Settings/PrivacyPolicy`}
            >
              <PrivacyPolicyBlock/>
            </PrivateRoute>
            <Redirect
              exact
              from={"/MerchantSettings/Settings"}
              to={"/MerchantSettings/Settings/EditBusinessDetails"}
            />
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    leftSidebar: {
      backgroundColor: "#f7f7f7",
    },
    rightBackground: {
      borderRadius: "32px",
      marginTop:50,
      backgroundColor: "#fff",
    },
    printIcon: {
      width: "50px",
    },
    logoutIcon: {
      width: "48px",
      height: "48px",
      margin: "180px 11px 0 7px",
      objectFit: "contain",
    },
    labelSetting: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.8,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    settingAvatar: {
      width: "35px",
      height: "35px",
      borderRadius: "16px",
      float: "right",
      marginTop: "10px",
      padding: "15px",
    },
    parentContainer: {
      padding: "16px",
    },
    editProfileColorButton: {
      width: "90%",
      margin: "3% auto",
      cursor : 'pointer',
      display:"flex",
      justifyContent:"space-between",
      alignItems:'center',
      padding: "17px ",
      borderRadius: "12px",
      backgroundColor: "#32795f",
    },
    editProfileButton: {
      width: "90%",
      margin: "3% auto",
      cursor : 'pointer',
      display:"flex",
      justifyContent:"space-between",
      alignItems:'center',
      cursor:"pointer",
      padding: "17px",
      borderRadius: "12px",
      backgroundColor: "#fff",
    },
    editProfileButtonIcon: {
      width: "40px",
      height: "40px",
      position : 'relative'
    },
    editProfileColorButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width:"70%",
      lineHeight: 1,
      marginLeft:"15px",
      letterSpacing: "normal",
      color: "#ffffff",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      marginLeft:"15px",
      fontStyle: "normal",
      width:"70%",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#8f92a1",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileArrowIcon: {
      width: "18px",
      // position: 'absolute',
      margin : '0.8rem',
      // marginLeft : '10.5rem',
      height: "18px",
    },
    endUserfTxt: {
      marginTop: "180px",
      marginLeft: "25px",
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    accountInformationsTxt: {
      marginTop: "50px",
      marginLeft: "30px",
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    edittProfileDetailTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      marginLeft: "30px",
      color: "#8f92a1",
    },
    textMargin: {
      marginLeft: "30px",
    },
    profileImage: {
      height: "50px",
      width: "50px",
    },
    textField: {
      marginTop: "15px",
      marginLeft: "30px",
    },
    textFieldLabel: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20;",
    },
    saveButton: {
      bordeRadius: "12px",
      backgroundColor: "#32795f",
      marginLeft: "30px",
      marginTop: "50px",
      padding: "18px 108px 20px 109px",
      borderRadius: "12px",
      "&:hover": {
        backgroundColor: "#32795f",
      },
    },
    saveButtonText: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#fff",
    },
    active: {
      color: "red",
    },
    tabNameText: {
      fontSize: 14,
      width: "80%",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginLeft: 15,
    },
    tabBottomText: {
      fontSize: 14,
      width: "80%",
      cursor: "pointer",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      margin: "10px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });
  const MerchantSettingsWithConfirmBox = withConfirmBox(MerchantSettings);
  const MerchantSettingsWithLoader = withLoader(MerchantSettingsWithConfirmBox)
  const MerchantSettingsWithToast = withToast(MerchantSettingsWithLoader)

export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantSettingsWithToast)
);