Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";



//Text Labels
exports.Privacypolicy="Privacy policy"
exports.GetStarted="Get Started"
exports.Termofservice="Term of service"
exports.copyRight="© 2020 PrintSEC Digital"
exports.experienceLine1="Experience matters"
exports.experienceLine2="for good applications."
exports.experienceContentLine1="The first is a non technical method which requires the use of adware removal software."
exports.experienceContentLine2="A non technical method which requires the use of adware removal software.."
exports.WhyHeading="Why PrintSEC?"
exports.WhyContentLine1="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
exports.WhyContentLine2=  "incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"
exports.slideTitle=  "Slide Titles"
exports.slideContent=  "Slide content goes here."
exports.testimonialsHeading=  "Testimonials"
exports.testimonialsContent=  "The first is a non technical method which requires the use of adware removal software. The first is a non technical method which requires the use of adware removal software. The first is a non technical method which requires the use of adware removal software. The first is a non technical method which requires the use of adware removal software."
exports.PersonName=  "Person Name"
exports.Designation=  "Designation"
exports.PlayStore=  "Google Play"
exports.AppStore=  "App Store"
exports.ScanToDownload=  "Scan To Download"
exports.bannerTwoText1=  "Secure"
exports.bannerTwoText2=  "Printing Services"
exports.bannerOneText1=  "Get Your Documents"
exports.bannerOneText2= "Printed"
exports.bannerOneText3=  "Safely & Securely"
exports.bannerThreeText1=  "Digital"
exports.bannerThreeText2=  "Consulting"
exports.bannerThreeText3=  "Services"
exports.bannerThreeButtonText=  "Login To Consulting Services"
exports.bannerOneContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"
exports.home="Home"
exports.about="About Us"
exports.contact="Contact Us"
exports.services="Services"
exports.signUp="Signup"
exports.login="Login"
exports.secureServices="Secure Printing Services "
exports.digitalServices="Digital Consulting Services"
exports.PublicQueue="Public Queue"
exports.PrivateQueue="Private Queue"
exports.Industryspecificqueue="Industry specific queue"
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";






//API CONSTANTS
exports.getHeaderFooterEndPoint = "bx_block_adminconsole/settings/app_configs/get_header_footer"
exports.getAPiEndMethod = "GET"
exports.postAPiEndMethod = "POST"
exports.contactUsApiEndPoint = "account_block/supports/contact_us"
exports.contentType = "application/json";










// Customizable Area End