// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
import { RouterProps } from "react-router";
export type Props = RouterProps & {
  id: string;
  classes:any;
  showNotification : any;
  showLoader : any;
  hideLoader : any;
  openDialog: any;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  resData: any;
  orderId: number;
  authToken: string;
  dataSource: any;
  loading: boolean;
  orderNumber: number;
  documentsCount: number;
  expireTime : any,
  countDownTime : any;
  openAllPrintModel : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
  > {
  apiGetOrderDetailsCallId: String = "";
  apiUpdateOrderStatusCallId: String = "";
  state = {
    resData: {},
    dataSource: [],
    loading: false,
    orderNumber: 0,
    documentsCount: 0,
    authToken: "",
    orderId: 0,
    expireTime : null,
    countDownTime : "",
    openAllPrintModel : false
  };
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // this.parseApiErrorResponse(errorReponse);
      if (apiRequestCallId === this.apiGetOrderDetailsCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          await this.setState({ 
            resData: responseJson?.data?.attributes, 
            openAllPrintModel : responseJson?.data?.attributes.status === 'Printingcompleted',
            dataSource: responseJson?.data?.attributes?.order_attachments?.attachment_details 
          });
        } else if (responseJson && responseJson.message) {
          await this.setState({ resData: {} });
          await this.setState({ dataSource: [] });
          this.props.showNotification(responseJson.message,"error");
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiUpdateOrderStatusCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.props.hideLoader();
          this.props.showNotification(
            "Order Status has been updated to completed",
            "success"
          );
          setTimeout(() => {
            this.props.history.push('/OrderManagement/OrderHistory/History');
          }, 1000);
        } else if (responseJson && responseJson.message) {
          this.props.showNotification(responseJson.message,"error");
        }
        else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
  }

  // Customizable Area Start

  showTime(){
    setInterval(()=>{
        if(this.state.expireTime){
            var now = new Date().getTime();
            var distance = this.state.expireTime - now;
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            // var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // const time = hours + "h " + minutes + "m " + seconds + "s ";
            // if(hours >=0 ){
            //   this.setState({countDownTime : time})  
            // } else {
            //   this.setState({countDownTime : false})
            // }
            if(hours > 0){
              hours = hours.toString().length > 1 ? hours : "0" + hours;
              minutes = minutes.toString().length > 1 ? minutes : "0" + minutes; 
              const time = hours + ":" + minutes + " hours" 
              this.setState({countDownTime : time})    
          } else if(hours === 0){
              const time = hours + ":" + minutes + " minutes"
              this.setState({countDownTime : time})  
          } else {
            this.setState({countDownTime : false})
          }
             
        }
    },1000)
  }

  getOrderDetailsApiCall() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrderDetailsCallId = requestMessage.messageId;

    let getOrderDetailsAPIEndPoint = ('' + configJSON.getOrderDetailsAPIEndPoint).split('orderId').join('' + this.state.orderId)

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getOrderDetailsAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateOrderStatus = (isFinish: boolean, isAskedRefund: boolean, reasonForRefund: string) => {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderStatusCallId = requestMessage.messageId;

    let getOrderDetailsAPIEndPoint = ('' + configJSON.getOrderDetailsAPIEndPoint).split('orderId').join('' + this.state.orderId)

    if (isFinish) {
      getOrderDetailsAPIEndPoint = getOrderDetailsAPIEndPoint + "?data[status]=Completed";
    } else if (isAskedRefund) {
      getOrderDetailsAPIEndPoint = getOrderDetailsAPIEndPoint + "?data[status]=Refunded&data[refund_reason]=" + reasonForRefund;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getOrderDetailsAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
