Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.exampleAPiMethod = "POST";
  exports.postAPiMethod = "POST";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "MenuOrdering";
  exports.labelBodyText = "MenuOrdering Body";
  exports.btnExampleTitle = "CLICK ME";
  exports.contentType = "application/json";
  exports.multiformData="multipart/form-data";
  exports.postAPiEndMethod = "POST";
  exports.patchAPiEndMethod = "PATCH"
  exports.deleteAPiEndMethod = "DELETE"
  
  // text constant
  exports.selectFilesText = "Select Files to Print"
  exports.continuePrintBtnTxt = "Continue to Print"
  exports.printProperties = "Print Properties"
  exports.pagesToPrint = "Pages to Print"
  exports.pagesTo = "to"
  exports.Colors = "COLORS"
  exports.Layout = "LAYOUT"
  exports.pageSize = "PAGE SIZE"
  exports.Scale = "SCALE"
  exports.printSlides = "PRINT SIDES"
  exports.saveBtn = "SAVE CHANGES"
  exports.applyBtn = "SAVE AND APPLY TO ALL DOCS"
  exports.continuePayBtn = "Continue to payment"
  exports.findShopsBtn = "Find Open Print Shops"

  
  //API CONSTANTS
  exports.uploadFilesEndPoint = "bx_block_bulkuploading/attachments";
  exports.getUploadFilesEndPoint = "attachment_block/attachments/account_id/get_user_attachments";
  exports.getPrintPropertiesEndPoint = "attachment_block/attachments/account_id/save_print_properties";
  exports.savePrintPropertiesEndPoint = "attachment_block/attachments/attachment_id/save_print_properties";
  exports.deleteDocumentsEndPoint = "attachment_block/attachments/attachment_id"
  exports.generateQrCodesEndPoint = "bx_block_qrcodes/orders/generate_qrcode"
  exports.deleteAPiEndPoint = "bx_block_bulkuploading/attachments/attachment_id"
  exports.getOrderAmountDetailsEndPoint="bx_block_ordermanagement/orders/get_order_amount";
  exports.placeOrderAPiEndPoint = "bx_block_ordermanagement/orders";
  exports.createTransactionAPIEndPoint ="bx_block_ordermanagement/orders/orderId/transactions"
  exports.updateOrderStatusAPIEndPoint = "bx_block_ordermanagement/orders/orderId"; //{userid}

  //API CONSTANTS
  // Customizable Area End