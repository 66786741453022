//@ts-nocheck

import React from "react";
// Customizable Area Start

import ScannedOrderDetailsController, {
  Props,
  configJSON,
} from "./ScannedOrderDetailsController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Modal } from "@material-ui/core";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import { withRouter } from "react-router";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import OTPInputView from "../../../components/src/OTPInputView";
import Moment from "moment";
import printJS from 'print-js';


import {
  docExcel,
  docImage,
  docWord,
  docPdf,
  docPowerPoint,
} from "../../UserDashBoard/src/assets";
import {
  checkbox_empty,
  checkbox_marked,
  activeDownload,
  passiveDownload,
  textFileIcon
} from "./assets";
import Downloader from "js-file-downloader";
import { labelFirstName } from "../../mobile-account-registration/src/user-additional-detail-input/config";

class ScannedOrderDetails extends ScannedOrderDetailsController {
  timer: null;
  otpReference = React.createRef(null);
  constructor(props: Props) {
    super(props); 
  }

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          authToken: loginData.token,
        });
      }
    });

    await StorageProvider.get("scannedOrderDetail").then((res) => {
      const orderId = JSON.parse(res);
      if (orderId) {
        this.setState({
          orderId: orderId,
        });
      }
    });

    await this.getOrderDetailsApiCall();

    // Customizable Area End
  }

  _onDownloadFileClicked = () => {
    if (this.state.isDownloadClicked) {
      const selectedDocs = this.state.orderAttachment.filter((attachment) => {
        return attachment.isSelected;
      });
      if (selectedDocs.length > 0) {
        this.getOtpToDownloadDocs();
        this.setState({ openModel: true });
      } else {
        this.props.showNotification("Please select at least 1 doc", "warn");
      }
    } else {
      this.setState({
        isDownloadClicked: true,
      });
    }
  };
  async componentWillUnmount() {
    clearInterval(this.timer);
  }
  completeOrder = () => {
    this.updateOrderStatus();
  };

  _selectedItem = (selectedAttachment: any) => {
    let orderAttachment = [...this.state.orderAttachment];
    let attachmentIndex = orderAttachment.findIndex((attachment) => {
      return selectedAttachment.id === attachment.id;
    });
    let selectedAttachmentItem = orderAttachment[attachmentIndex];
    selectedAttachmentItem = {
      ...selectedAttachmentItem,
      isSelected: !selectedAttachmentItem.isSelected,
    };
    orderAttachment[attachmentIndex] = selectedAttachmentItem;
    this.setState({ orderAttachment: orderAttachment });
  };

  // Customizable Area End

  getExpiryDateOfDoc = (createdAt: any,orderStatus) => {
    if (orderStatus == "Completed") {
      return "";
    }
    if (orderStatus == "Printing") {
      return "";
    }
    if (orderStatus == "Printing Completed") {
      return "";
    }
    if (createdAt != undefined) {
      var now = new Date().getTime();
      var countDownTime = new Date(createdAt)
        countDownTime = countDownTime.setHours(countDownTime.getHours() + 4 )
      var distance = countDownTime - now;
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      if (hours > 0) {
        hours = hours.toString().length > 1 ? hours : "0" + hours;
        minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
        const time = "Expiry In " + hours + ":" + minutes + " hours";
        return time;
      } else if (hours === 0) {
        hours = hours.toString().length > 1 ? hours : "0" + hours;
        minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
        const time = "Expiry In " + hours + ":" + minutes + " minutes";
        return time;
      }
    }
  };

  getOtp(OTP: string) {
    this.setState({ OTP });
  }

  manageTimer = () => {
    if (this.state.counter > 0) {
      this.setState(
        ({ counter }) => ({
          counter: counter - 1,
        }),
        () => {
          if (this.state.counter === 0) {
            clearInterval(this.timer);
          }
        }
      );
    }
  };
  restartTimer = () => {
    if (this.state.restartTimer) {
      this.setState({
        counter: 120,
      });
      this.timer = setInterval(this.manageTimer, 1000);
      this.setState({
        restartTimer: false,
      });
    }
  };
  resendOTP = () => {
    if (this.state.counter === 0) this.getOtpToDownloadDocs();
  };
  async downloadURI(url, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
      })
    );
    document.body.removeChild(link);
  }

  _downloadFiles = async () => {
    const selectedDocs = this.state.orderAttachment.filter((attachment) => {
      return attachment.isSelected;
    });
    if (selectedDocs.length > 0) {
      selectedDocs.forEach((docObj: any) => {
        this.downloadURI(docObj.file_url, docObj.file_name);
      });
    }
    this.props.history.push("/MerchantOrderManagement/OrderHistory/History");
  };

  goBackToPrinting = () => {
    this.setState({openAllPrintModel : false, documentSelected: false})
  }

  finishPrinting = (event) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === "Yes") {
        this.printingFinshedOrderUpdate();
      }
    };
    this.props.openDialog(
      "Printing Finish Confirmation",
      "Are you sure want to finish Printing Docs?",
      onClose
    );
  };

  updateOrderStatusToPrintingConfirmBox = (event) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === "Yes") {
        this.updateOrderStatusToPrinting();
      }
    };
    this.props.openDialog(
      "Print Confirmation",
      "Are you sure want to Print All Documents?",
      onClose
    );
  };

  resetAndResendOTP() {
    if (this.otpReference.current) {
      this.otpReference.current.resetOTP();
    }
  }
  reset = () => {
    this.resendOTP();
  };

  printDocument =(attachment) => {
    if(attachment.content_type === "image/gif" ||
    attachment.content_type === "image/png" ||
    attachment.content_type === "image/jpg" ||
    attachment.content_type === "image/jpeg" ) {
     this.printImage(attachment);
    } else {
    printJS({printable: attachment.pdf_url, type: 'pdf', onPrintDialogClose: this.onPrintDialogClose});
    }
  }

   callPrint = (farmeId) => {
    var PDF = document.getElementById(farmeId);
    PDF.focus();
    PDF.contentWindow.print();
  }

  onloadingStart = () => {
    setTimeout(function(){
      (window).addEventListener('mousemove', window.onafterprint);
    }, 1);
}

  onPrintDialogClose = () => {
    var self = this;
      const { openDialog } = self.props;
      const onClose = (msg) => {
        if (msg === "Yes") {
          self.setState({openAllPrintModel : true})
        }
      };
      self.props.openDialog(
        "Print Confirmation",
        "Are you sure want to print completed?",
        onClose
      );
  }

  printImage = (attachment) => {
    var self = this;
    var frame = document.createElement('iframe');
    frame.id = "hidden_iframe";
    var external_doc = new Blob([`<html><body><img src=${attachment.pdf_url}></body></html>`], {
      type: 'text/html',
    });
    frame.onload = function() {
      try {
         window.onafterprint = (e) => {
          (window).removeEventListener('mousemove', window.onafterprint);
          const { openDialog } = self.props;
          const onClose = (msg) => {
            if (msg === "Yes") {
              self.setState({openAllPrintModel : true})
            }
          };
          self.props.openDialog(
            "Print Confirmation",
            "Are you sure want to print completed?",
            onClose
          );
        };
      this.contentWindow && this.contentWindow.print();
      setTimeout(function(){
        (window).addEventListener('mousemove', window.onafterprint);
      }, 1);
        return;
      } catch (e) {}
      frame.parentNode.removeChild(frame); 
    };
    frame.src = URL.createObjectURL(external_doc);
    document.body.appendChild(frame);
  }

 handleDocumentsState= (len)=>{
  const someArr = new Array(len).fill(0)
  let someObj={}
  someArr.forEach((item,index)=>{
      let someOtherObj={[index]:false}
      someObj={...someObj,...someOtherObj}
  })
  this.setState({documentSelected:someObj},()=>{
  })
}

updateDocumentState=(index)=>{
  const state=this.state.documentSelected
  if(state[index]) {
    for(const key in state){
      state[key] = false
    }
    this.setState({documentSelected:state},()=>{
    })
  } else {
    for(const key in state){
      state[key] = false
    }
    let someobj= {
      ...state,
      [index]:!state[index]
  }
    this.setState({documentSelected:someobj},()=>{
    })
  }
}

findSelectedDocUrl= () =>{
    for(const key in this.state.documentSelected){
        if (this.state.documentSelected[key]) {
            this.printDocument(this.state.orderAttachment[key])
        }
    }
}

printButtonEnable = () => {
  for(const key in this.state.documentSelected){ 
    if(this.state.documentSelected[key]) {
      return false;
    }
  }
  return true;
}

render() {
    const { classes } = this.props;
    let orderStatus = "";
    if (this.state.restartTimer) {
      this.restartTimer();
    }
    if (this.state.otpVerified) {
      this.setState({ otpVerified: false });
      clearInterval(this.timer);
      this._downloadFiles();
    }
    const selectedDocs = this.state.orderAttachment.filter((attachment) => {
      return attachment.isSelected;
    });
    if (this.state.orderDetail.status != undefined) {
      if (this.state.orderDetail.status == "Pending") {
        orderStatus = "Ready For Print";
      } else if (this.state.orderDetail.status == "Completed") {
        orderStatus = "Completed";
      } else if (this.state.orderDetail.status == "Printing") {
        orderStatus = "Printing";
      } else if (this.state.orderDetail.status == "Printingcompleted") {
        orderStatus = "Printing Completed";
      } else if (this.state.orderDetail.status == 'Paymentcompleted') {
        orderStatus = "Payment Completed";
      } else if (this.state.orderDetail.status == "Refunded") {
        orderStatus = "Refunded";
      }
    }
    return (
      <Grid container className={classes.rightContent}>
        <Grid item xs={12}>
              <div className={classes.headingLeft}>
                <img src={require('../assets/backIcon.png')} onClick={()=>{
                  this.props.history.push(
                    "/MerchantOrderManagement/OrderHistory/History"
                  );
                 }}
                 alt="" className={classes.backIcon} />
                 <Typography className={classes.pageHeader}>
                   Order Detail
                </Typography>
              </div>
            </Grid>
        <Grid item xs={12} className={classes.headingCont}>
          <Typography className={classes.selectFilesText}>
            {this.state.orderDetail.order_number &&
              `Order #${this.state.orderDetail.order_number}`}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.orderDetailsCont}>
          <Typography className={classes.orderDetailsName}>
            {this.state.orderDetail?.customer_details?.name}
          </Typography>
          <Typography className={classes.orderDetailsText}>
            {this.state.orderDetail.created_at &&
              Moment(this.state.orderDetail.created_at).format(
                "DD/MM/yyyy hh:mm A"
              )}
          </Typography>
          <Typography className={classes.orderDetailsText}>
            {this.state.orderDetail.status === "Autocancel" ? this.state.orderDetail.status: null}
            {orderStatus &&
              (orderStatus === "Pending" ? "Ready For Print" : orderStatus)}
          </Typography>
          <Typography className={classes.orderDetailsText}>
            {this.state.orderDetail.created_at &&
              this.getExpiryDateOfDoc(
                this.state.orderDetail.created_at,
                orderStatus
              )}
          </Typography>
          <Typography className={classes.orderDetailsText}>
            {this.state.orderDetail.total_amount &&
              `Total Paid: Rs.${parseFloat(this.state.orderDetail.total_amount.toString()).toFixed(2)}`}
          </Typography>
          {this.state.orderDetail.status !== 'Autocancel' && this.state.orderDetail.status !== 'Completed' && <Typography className={classes.orderDetailsText}>
            {this.state.orderDetail.total_docs + " Documents" }
        </Typography>
        }
        </Grid>
        {orderStatus == "Ready For Print" || orderStatus === 'Payment Completed' && (
          <Grid
            item
            xs={12}
            container
            justify="flex-end"
            alignItems="center"
          >
            <Grid item xs={3} style={{cursor:"pointer"}}>
                <>
                  <img
                  style ={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    cursor : 'pointer'
                  }}
                   onClick={this._onDownloadFileClicked}
                    src={
                      this.state.isDownloadClicked
                        ? activeDownload
                        : passiveDownload
                    }
                    alt=""
                  />
                  <Typography className={classes.downloadText}>
                    {this.state.isDownloadClicked
                      ? `Download Selected Files (${selectedDocs.length})`
                      : "Select File To Download"}
                  </Typography>
                </>
            </Grid>
          </Grid>
        )}

         <Grid item xs={12}>
        <Typography className={this.state.orderDetail.status !== 'Autocancel' ? classes.totalFilesText : classes.downloadText}>
          {this.state.orderDetail.order_number == undefined
            ? " "
            : orderStatus == "Printing" || orderStatus == "Printing Completed" || orderStatus == "Completed"
            ? "No files are visible because order is " + orderStatus
            : null}
           </Typography>
        </Grid>
        <Grid  item xs={12} className={classes.downloadText}>
        {
          this.state.orderDetail.status === 'Autocancel'
           &&
             <Typography className={classes.totalFilesText}>
                  Order is autocancel so all the documents has been removed
              </Typography>
        }
        </Grid>
        {orderStatus == "Ready For Print" || orderStatus === 'Payment Completed' && (
          <Grid container spacing={4} className={classes.docsCont}>
            {this.state.orderAttachment.map((attachment,index) => {
              return (
                <Grid key={attachment.id} item xs={12} sm={6} md={3}>
                  <Grid item container xs={12} className={`printCheckboxParent ${classes.eachDocs} ${this.state.documentSelected[index]?"printselectFile":""}`}>
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid
                        xs={12}
                        item
                        onClick={() => {
                          this._selectedItem(attachment);
                        }}
                      >
                        {this.state.isDownloadClicked && (
                          <img
                            src={
                              attachment.isSelected
                                ? checkbox_marked
                                : checkbox_empty
                            }
                            alt=""
                            className={classes.docNavImg}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} className="printCheckboxParentConta">
                      <div className="printCheckbox">   
                      <input type="checkbox" className="selectedBox" checked={this.state.documentSelected[index]} onChange={()=>this.updateDocumentState(index)}
                      />
                      </div>
                      {this.state.documentSelected[index] ? 
                      <img
                        src={require("../assets/print.svg")}
                        alt=""
                        className={classes.printImg}
                        onClick= {() =>{this.printDocument(attachment)}}
                      /> 
                      : null
                       }
                      </Grid>
                    </Grid>
                    <div className={classes.docNav}>
                      {attachment.content_type === "application/msword" ||
                      attachment.content_type ===
                        "application/vnd.oasis.opendocument.text" ||
                      attachment.content_type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                        <img
                          src={require("../assets/docword.png")}
                          className={classes.docImg}
                        />
                      ) : attachment.content_type === "image/gif" ||
                        attachment.content_type === "image/png" ||
                        attachment.content_type === "image/jpg" ||
                        attachment.content_type === "image/jpeg" ? (
                        <img
                          src={require("../assets/image_placeholder.jpg")}
                          className={classes.docImg}
                        />
                      ) : attachment.content_type === "application/pdf" ? (
                        <img
                          src={require("../assets/pdf_placeholder.png")}
                          className={classes.docImg}
                        />
                      ) : attachment.content_type ===
                          "application/vnd.ms-powerpoint" ||
                        attachment.content_type ===
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                        attachment.content_type ===
                          "application/vnd.oasis.opendocument.presentation" ? (
                        <img
                          src={require("../assets/ppt.png")}
                          className={classes.docImg}
                        />
                      ) : attachment.content_type ===
                          "application/vnd.ms-excel" ||
                        attachment.content_type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        attachment.content_type ===
                          "application/vnd.oasis.opendocument.spreadsheet" ? (
                        <img
                          src={require("../assets/docexcel.png")}
                          className={classes.docImg}
                        />
                      ) : (attachment.content_type === "text/plain")?(
                          <img
                          src={textFileIcon} className={classes.docImg}/>
                          ): (
                        <img
                          src={require("../assets/pdf_placeholder.png")}
                          className={classes.docImg}
                        />
                      )}
                    </div>
                    <Typography className={classes.docTitle}>
                      {attachment.file_name ? attachment.file_name : ""}
                    </Typography>
                      <Typography className={classes.docContent}>
                      All Pages - {attachment.colour ? attachment.colour : ""}
                      </Typography>
                      <Typography className={classes.docContent}>
                        {attachment.layout && attachment.layout} -{" "}
                        {attachment.page_size && attachment.page_size}
                      </Typography>
                      <Typography className={classes.docContent}>
                        {attachment.print_pages_to && attachment.print_pages_to}{" "}
                        copy
                      </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}
       {/* {orderStatus == "Ready For Print" || orderStatus === 'Payment Completed' && ( <Grid item xs={11} className={classes.btnCont}>
          
            <Button
            disabled={this.printButtonEnable()}
            variant="contained"
            className={classes.findPrintersBtn}
            onClick={this.findSelectedDocUrl}
            >
              PRINT
            </Button>
        </Grid>
        )} */}
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.openModel}
          onClose={()=>{
            this.setState({openModel : false})
          }}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                Verify OTP
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
              <div className={classes.modalEachDiv}>
                <Typography className={classes.modalContentText}>
                  Ask your customer for approval by sharing their OTP.
                </Typography>
              </div>
              <div className={classes.modalEachDiv}>
                <Typography
                  className={classes.modalContentText}
                  style={{ textAlign: "left" }}
                >
                  OTP:
                </Typography>
              </div>
              <div className={classes.modalEachDiv}>
                <OTPInputView
                  reset={this.reset}
                  ref={this.otpReference}
                  getOtp={(otp: string) => this.getOtp(otp)}
                  className={classes.passCodeField}
                />
              </div>
              <div className={classes.modalEachDiv}>
                <Typography className={classes.modalContentText}>
                  Didn’t get the OTP?
                  {this.state.counter !== 0?(
                    <span style={{ textDecoration: "underline",cursor:"pointer",marginLeft:7  }}>
                  Resend In 0{Math.floor(this.state.counter / 60)}:
                      {(this.state.counter % 60).toString().length > 1
                        ? this.state.counter % 60
                        : "0" + (this.state.counter % 60)}
                </span>
                  ):(
                    <span
                      style={{ textDecoration: "underline",cursor:"pointer",marginLeft:7 }}
                      onClick={() => this.resetAndResendOTP()}
                    >
                    Resend OTP
                  </span>
                  )}
                </Typography>
              </div>
              <div className={classes.modalEachDiv}>
                <Button
                  className={classes.closeModalBtn}
                  onClick={this.validateOtpCall}
                  style={{ backgroundColor: "#32795f" }}
                >
                  DOWNLOAD FILES
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.openAllPrintModel}
          onClose={()=>{
            this.setState({openAllPrintModel : false})
          }}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                Print Order # {this.state.orderDetail.order_number}
              </Typography>
            </div>
            <div className={classes.modalPrintContent}>
              <img
                src={
                  !true
                    ? require("../assets/print.svg")
                    : require("../assets/printDocument.png")
                }
                alt=""
                className={classes.modalImg}
              />
              {/* <Typography className={classes.modalContentText}>
                {false
                  ? "All Set"
                  : "Printing Documents"}
              </Typography> */}
              {/* <Typography className={classes.modalContentText}>
                {!true
                  ? `${this.state.orderAttachment.length}/${this.state.orderAttachment.length} Docs`
                  : `Printing ${this.state.orderAttachment.length} Docs`}
              </Typography> */}
              {/* {!this.state.isPrintingFinished && (
                <Typography className={classes.modalContentText}>
                  Using Konika Minolta C223e
                </Typography>
              )} */}
              {false ? (
                <Button
                  style={{ backgroundColor: "#32795f" }}
                  className={classes.closeModalBtn}
                  onClick={this.completeOrder}
                >
                  Finish Order
                </Button>
              ) : (
                <div>
                <Button
                  style={{ backgroundColor: "#32795f" }}
                  className={classes.closeModalBtnFinish}
                  onClick={this.finishPrinting}
                >
                  Finish Printing
                </Button>
                <Button
                style={{ backgroundColor: "#32795f" }}
                className={classes.closeModalBtnContinue}
                onClick={this.goBackToPrinting}
              >
                Continue To Print
              </Button>
              </div>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.printAllDocumentStarted}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>Printing</Typography>
            </div>
            <div className={classes.modalContentCont}>
              <img
                src={require("../assets/printDocument.png")}
                alt=""
                className={classes.modalImg}
              />
              <Typography className={classes.modalContentText}>
                {"All Set"}
              </Typography>
              <Typography className={classes.modalContentText}>
                {`${this.state.orderAttachment.length} Docs`}{" "}
              </Typography>
              <Button
                style={{ backgroundColor: "#32795f" }}
                className={classes.closeModalBtn}
                onClick={this.finishPrinting}
              >
                Finish Printing
              </Button>
            </div>
          </div>
        </Modal>
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
    },
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      height: "100%",
      width: "25%",
      minWidth: 220,
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    leftSideCont: {
      backgroundColor: APP_COLORS.app_theme_yellow_color,
      // height:'100%',
    },
    rightSideCont: {
      // height:'70%',
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContentCont: {
      display: "flex",
      height: "90%",
      flexDirection: "column",
      alignItems: "center",
    },
    modalPrintContent:{
      display: "flex",
      height: "90%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent:"center"
    },
    modalHeadingCont: {
      height: "10%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    backImg: {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    headingCont: {
      width: "100%",
    },
    headingLeft:{
      display:'flex',
      alignItems:'center',
      margin:'20px 0 8px 20px;'
    },
    selectFilesText: {
      fontSize: 22,
      margin: "20px 0 0 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    orderDetailsCont: {
      marginTop: 7,
      width: "100%",
    },
    modalEachDiv:{
      width:"90%",
      margin:"5px auto"
    },
    downloadRightCont:{
      display: "flex",
      cursor:"pointer",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    orderDetailsText: {
      fontSize: 16,
      margin: "5px 0 5px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    orderDetailsName: {
      fontSize: 20,
      margin: "8px 0 8px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#2f2f30",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    modalContentText: {
      fontSize: 14,
      margin: "10px auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    qrText: {
      fontSize: 16,
      margin: 0,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#8f92a1",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    }
    },
    backgroundImg: {
      background: "#f7f7f7",
      // height:'100%'
      // backgroundImage:`url(require('../assets/background.png'))`,
      // backgroundRepeat:"repeat"
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docsCont: {
      width: "95%",
      margin: "0 auto 20px",
    },
    downloadCont: {
      width: "95%",
      margin: "20px auto 10px auto",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    downloadRight: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    eachDocs: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      // height:250,
      // width:'90%',
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docNav: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    docNavImg: {
      height: 16,
      width: 16,
      marginRight: 5,
      objectFit: "contain",
    },
    printImg: {
      height: 16,
      width: 16,
      marginRight: 5,
      objectFit: "contain",
      cursor: "pointer"
    },
    modalImg: {
      height: 70,
      width: 70,
      objectFit: "contain",
    },
    descCont: {
      display: "flex",
      borderRadius: 12,
      backgroundColor: "white",
      padding: "10px 20px",
      width: "90%",
      margin: "auto",
    },
    eachDesc: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "auto",
      marginTop: "20px",
    },
    uploadCont: {
      margin: "auto",
      marginTop: "20px",
      border: "2px dotted #77c285",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 12,
    },
    uploadImg: {
      height: 130,
      width: 130,
      margin: "auto",
      objectFit: "contain",
    },
    uploadText: {
      fontSize: 22,
      width: "80%",
      margin: "20px auto",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    docTitle: {
      fontWeight: "bold",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
      margin: "10px 0",
      wordBreak: 'break-all'
    },
    docImg: {
      height: 50,
      width: 65,
      margin: "10px auto",
      objectFit: "contain",
    },
    qrImg: {
      height: 70,
      width: 70,
      marginBottom: 10,
      objectFit: "contain",
    },
    docContent: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
    },
    downloadText: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
      marginTop:10
    },
    totalFilesText: {
      margin : '3%',
      fontSize: 14,
      // fontWeight:"bold",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    btnCont: {
      margin: "auto",
      marginTop: "20px",
      borderRadius: 12,
    },
    qrCont: {
      margin: "auto",
      marginTop: "20px",
      paddingLeft: 20,
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    closeModalBtn: {
      color: "white",
      borderRadius: 12,
      width: "100%",
      margin: " auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    closeModalBtnFinish:{
      color: "white",
      borderRadius: 12,
      width: "100%",
      margin: "20px auto 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    closeModalBtnContinue:{
      color: "white",
      borderRadius: 12,
      width: "100%",
      // margin: "20px auto 80px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    pageHeader: {
      fontSize: 22,
      marginLeft: "5px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    backIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor : "pointer"
    },
  });
// Customizable Area End
const ScannedOrderDetailsWithConfirmBox = withConfirmBox(ScannedOrderDetails);
const ScannedOrderDetailsWithLoader = withLoader(
  ScannedOrderDetailsWithConfirmBox
);
const ScannedOrderDetailsWithToast = withToast(ScannedOrderDetailsWithLoader);
export default withRouter(
  withStyles(styles, { withTheme: true })(ScannedOrderDetailsWithToast)
);