// @ts-nocheck
import React from "react";
import {
  Grid,
  Typography,
  Button,
  InputBase,
  OutlinedInput,
} from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import StorageProvider from "../../../framework/src/StorageProvider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withRouter } from "react-router";
import {
  cameraIcon,
  profileIcon,
  deleteIcon,
  mapsIcons,
  userLogo,
  locationIcon
} from "./assets";
import EditMerchantBusinessDetailsController, {
  Props,
  configJSON,
} from "./EditMerchantBusinessDetailsController.Web";
import TagView from "../../../components/src/TagView";
import { colorPrintIcon, greyscaleIcon, bothIcon, fileIcon } from "./assets";

class EditMerchantBusinessDetails extends EditMerchantBusinessDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  _tagAllTapped = () => {
    this.setState(
      (prevState) => ({
        pageA1Available: false,
        pageA2Available: false,
        pageA3Available: false,
        pageA4Available: false,
        pageLetterAvailable: false,
        pageAllAvailable: true,
      }),
      () => {
        if (this.state.pageAllAvailable) {
          this.setState({ selectedPageSize: ["All"] });
        } else {
          const selectedPageSize = this.state.selectedPageSize.filter(
            (papertype) => {
              return papertype !== "All";
            }
          );
          this.setState({ selectedPageSize: selectedPageSize });
        }
      }
    );
  };

  _saveAndContinueTapped = () => {
    this.updateMerchantBusinsessDetailsCall(this.props.renderComp);
  };

  // this is to get current geolocation and address from geolocation
  requestLocationPermission = async () => {
    this.getOneTimeLocation();
  };

  getOneTimeLocation = () => {
    this.setState({ loading: true });
    this.props.showLoader();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLongitude = JSON.stringify(position.coords.longitude);
        const currentLatitude = JSON.stringify(position.coords.latitude);
        this.setState(
          {
            lat: currentLatitude,
            lng: currentLongitude,
          },
          () => {
            this.getAddress(currentLatitude, currentLongitude);
          }
        );
      },
      (error) => {
        this.setState({ loading: false });
        this.props.hideLoader();
        console.log("error.message:==>", error.message);
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  };

  getAddress = (lat: any, lng: any) => {
    this.props.showLoader();
    var request = new XMLHttpRequest();
    var method = "GET";
    var url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&sensor=true&key=AIzaSyBI5xkmKUlPoVouVxtAY2prU4ghXF5V0kc";
    var async = true;
    request.open(method, url, async);
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        var data = JSON.parse(request.responseText);
        this.props.hideLoader();
        var address = data.results[0].formatted_address || "";
        this.setState({ shopAddress: address });
      }
    };
    request.send();
  };

  async componentDidMount() {
    // Customizable Area Start
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        id: loginData.id,
        authToken: loginData.token,
        profileImagePath: loginData.profile_image_path
      });
    }

    if (this.props.mode === "edit") {
      const businessDetailsData = await StorageProvider.get(
        "businessDetailsData"
      );
      if (businessDetailsData) {
        const businessDetails = JSON.parse(businessDetailsData);
        this.setState(
          {
            shopName: businessDetails.shop_name,
            ownersName: businessDetails.owner_name,
            shopAddress: businessDetails.shop_address,
            gstNumber: businessDetails.gst_number,
            bhimUPI: businessDetails.bhim_upi_number,
            email: businessDetails.email,
            lat: businessDetails.lat,
            lng: businessDetails.lng,
            pagesAvailables: businessDetails.pages,
            printColors: businessDetails.print_colours,
          },
          () => {
            this.getSelectedPages();
            this.getSelectedColor();
          }
        );
      }
    } else if (this.props.mode === "create") {
      await this.requestLocationPermission();
    }
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getSelectedPages() {
    this.state.pagesAvailables.forEach((element) => {
      if (element == "A1") {
        this.setState({ pageA1Available: true },()=>{
          this.setSelectedPageSize(this.state.pageA1Available,'A1')
        });
      } else if (element == "A2") {
        this.setState({ pageA2Available: true },()=>{
          this.setSelectedPageSize(this.state.pageA2Available,'A2')
        });
      } else if (element == "A3") {
        this.setState({ pageA3Available: true },()=>{
          this.setSelectedPageSize(this.state.pageA3Available,'A3')
        });
      } else if (element == "A4") {
        this.setState({ pageA4Available: true },()=>{
          this.setSelectedPageSize(this.state.pageA4Available,'A4')
        });
      } else if (element == "Letter") {
        this.setState({ pageLetterAvailable: true },()=>{
          this.setSelectedPageSize(this.state.pageLetterAvailable,'Letter')
        });
      } else if (element == "All") {
        this._tagAllTapped();
      }
    });
  }
  setSelectedPageSize(isAvailable,paperSize){
    if (isAvailable) {
      this.setState((prevState) => ({
        selectedPageSize: [...prevState.selectedPageSize.filter(
          (papertype) => {
            return papertype !== "All";
          }
        ), paperSize],
      }));
    } else {
      this.setState((prevState) => ({ selectedPageSize: prevState.selectedPageSize.filter(
          (papertype) => {
            return papertype !== paperSize;
          }
        ) }));
    }
  }
  getSelectedColor() {
    this.state.printColors.forEach((element) => {
      if (element == "Color") {
        this.setState({
          selectedPrintColor: element,
          bothPrintAvailable: false,
        });
      } else if (element == "Grey") {
        this.setState({
          selectedPrintColor: element,
          bothPrintAvailable: false,
        });
      } else if (element == "Both") {
        this.setState({
          selectedPrintColor: null,
          bothPrintAvailable: true,
        });
      }
    });
  }
  handleLatterType = (paperSize) => {
    if (paperSize == "A1") {
      this.setState(
        (prevState) => ({
          pageA1Available: !prevState.pageA1Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA1Available,paperSize)
        }
      );
    } else if (paperSize == "A2") {
      this.setState(
        (prevState) => ({
          pageA2Available: !prevState.pageA2Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA2Available,paperSize)
        }
      );
    } else if (paperSize == "A3") {
      this.setState(
        (prevState) => ({
          pageA3Available: !prevState.pageA3Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA3Available,paperSize)
        }
      );
    } else if (paperSize == "A4") {
      this.setState(
        (prevState) => ({
          pageA4Available: !prevState.pageA4Available,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageA4Available,paperSize)
        }
      );
    } else if (paperSize == "Letter") {
      this.setState(
        (prevState) => ({
          pageLetterAvailable: !prevState.pageLetterAvailable,
          pageAllAvailable: false,
        }),
        () => {
          this.setSelectedPageSize(this.state.pageLetterAvailable,paperSize)
        }
      );
    } else if (paperSize == "All") {
      this._tagAllTapped();
    }
  };

  handleColorType = (color) => {
    if (color === "Color") {
      this.setState({
        selectedPrintColor: "Color",
        bothPrintAvailable: false,
      });
    } else if (color === "greyscale") {
      this.setState({
        selectedPrintColor: "Grey",
        bothPrintAvailable: false,
      });
    } else if (color === "Both") {
      this.setState({
        selectedPrintColor: 'Both',
        bothPrintAvailable: true,
      });
    }
  };
  _onProfileImageDelete = () => {
    const {openDialog} = this.props;
    const onClose = (msg)=>{
      if(msg === 'Yes'){
        this.handleDeleteImage();
      }
    }
    this.props.openDialog('Remove profile picture','Are you sure want to delete profile image',onClose);
  };
  handleImageChange = (event) => {
    this.setState({
      imageUri: event.target.files[0],
      fileName: event.target.files[0].name,
      fileType: event.target.files[0].type,
      file: event.target.files,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        filePath: reader.result,
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };
  handleDeleteImage = () => {
    if (this.state.profileImagePath) {
      this.deleteProfileImageApiCall(this.props.renderComp);
    } else if (this.state.imageUri) {
      this.deleteProfileImageApiCall(this.props.renderComp);
    }
  };

  render() {
    const { classes } = this.props;
    const { mode } = this.props;
    let image = <img src={this.state.filePath===null?profileIcon:this.state.filePath} style={{cursor:"pointer"}} className={classes.profileImage} />;
    if(this.state.profileImagePath){
      image = <img src={this.state.profileImagePath} className={classes.profileImage} />
    }
    return (
      //Merge Engine DefaultContainer
      <Grid container direction="column" className={classes.parentCont}>
        <Grid
          item
          container
          xs={12}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            {mode === "create" ? (
              <>
                <Typography variant="body1" className={classes.sofiaPro}>Before We Continue</Typography>
                <Typography variant="body2" className={classes.sofiaPro}>
                  Add Business Detail(1/2)
                </Typography>
                <Typography variant="subtitle1" className={classes.sofiaPro}>Basic Information</Typography>
              </>
            ) : (
              <Typography variant="subtitle1" className={classes.sofiaPro} style={{fontSize:22}}>Edit Business Details</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {mode === "create" &&
              <img src={userLogo} style={{marginLeft:"7%"}} />
            }
          </Grid>
        </Grid>
        {mode === "edit" &&
        <Grid
          xs={12}
          sm={6}
          item
          container
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
          className={classes.textMargin}
        >
          <div className={classes.tabImgContentLeft}>
            <Grid style={{ margin: "auto 15px" }}>
              <div className={classes.inputContainer}>
                <input
                  type="file"
                  onChange={this.handleImageChange}
                  className={classes.cameraIcon}
                />
                <img src={cameraIcon}
                     onChange={this.handleImageChange}
                />
              </div>
            </Grid>
            <Grid style={{margin:"auto 15px"}}>
              {
                image
              }
            </Grid>
            <Grid style={{ margin: "auto 15px" }}>
              <div>
                <img
                  src={deleteIcon}
                  style={{cursor:"pointer"}}
                  onClick={() => this._onProfileImageDelete()}
                />
              </div>
            </Grid>
          </div>
        </Grid>
        }
        <Grid item xs={12} container>
          <Grid item xs={12} sm={6}>
            <div className={classes.tabContentLeft}>
              <Typography className={classes.inputLabel}>SHOP NAME <span className={classes.mandatoryMark}>*</span></Typography>
              <InputBase
                placeholder="Enter Shop Name"
                type="text"
                className={classes.inputTextBox}
                name="shopName"
                onChange={this.handleInputChange}
                value={this.state.shopName}
              />
              <Typography className={classes.inputLabel}>
                OWNER'S NAME <span className={classes.mandatoryMark}>*</span>
              </Typography>
              <InputBase
                placeholder="Enter Owner Name"
                type="text"
                className={classes.inputTextBox}
                name="ownersName"
                onChange={this.handleInputChange}
                value={this.state.ownersName}
              />
              <Typography className={classes.inputLabel}>
                SHOP ADDRESS <span className={classes.mandatoryMark}>*</span>
              </Typography>
              <InputBase
                type="text"
                placeholder="Enter Shop Address"
                name="shopAddress"
                className={classes.inputTextBox}
                onChange={(e) => this.handleInputChange(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={locationIcon} alt="" />
                  </InputAdornment>
                }
                value={this.state.shopAddress}
              />
              <Typography className={classes.inputLabel}>GST Number</Typography>
              <InputBase
                type="text"
                placeholder="Enter GST Number"
                name="gstNumber"
                className={classes.inputTextBox}
                onChange={this.handleInputChange}
                value={this.state.gstNumber}
              />
              <Typography className={classes.inputLabel}>EMAIL <span className={classes.mandatoryMark}>*</span></Typography>
              <InputBase
                type="text"
                placeholder="Enter Email"
                name="email"
                className={classes.inputTextBox}
                onChange={this.handleInputChange}
                value={this.state.email}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} style={{marginTop:30}}>
            <div className={classes.tabContentLeft}>
              <Typography className={classes.basicInfo}>
                {configJSON.labelPrintInformation}
              </Typography>
              <Typography className={classes.inputLabel}>
                {configJSON.labelPrintTypeAvailabilty}
              </Typography>
              <div className={classes.printTypeCont}>
              <span
                onClick={() => {
                  this.handleColorType("Color");
                }}
                className={classes.spanPrintTypeImg}
                style={{
                  marginTop: 15,
                  color:
                    this.state.selectedPrintColor === "Color" && !this.state.bothPrintAvailable
                      ? "#32795f"
                      : "#1e1f20",
                  // border : this.state.selectedPrintColor === "Color" && !this.state.bothPrintAvailable
                  // ? '2px solid #32795f'
                  // : "none"
                }}
              >
                <img
                  src={colorPrintIcon}
                  alt=""
                  className={classes.colorCopy}
                />
                Colored
              </span>
                <span
                  onClick={() => {
                    this.handleColorType("greyscale");
                  }}
                  className={classes.spanPrintTypeImg}
                  style={{
                    marginTop: 15,
                    color:
                      this.state.selectedPrintColor === "Grey" && !this.state.bothPrintAvailable
                        ? "#32795f"
                        : "#1e1f20",
                    // border : this.state.selectedPrintColor === "Grey" && !this.state.bothPrintAvailable
                    // ? '2px solid #32795f'
                    // : "none"
                  }}
                >
                <img
                  src={greyscaleIcon}
                  alt=""
                  className={classes.colorCopy}
                />
                Greyscale
              </span>
                <Button
                  className={classes.timeSelectBtn}
                  onClick={() => {
                    this.handleColorType("Both");
                  }}
                  style={{
                    marginTop: 15,
                    backgroundColor:
                      this.state.bothPrintAvailable
                        ? "#32795f"
                        : APP_COLORS.app_theme_yellow_color,
                  }}
                >
                  <img
                    src={bothIcon}
                    style={{ marginRight: 7 }}
                    alt=""
                  />
                  {configJSON.labelBoth}
                </Button>
              </div>
              <Typography
                className={classes.inputLabel}
                style={{ marginTop: 30 }}
              >
                {configJSON.labelPagesAvailable}
              </Typography>
              <div className={classes.printTypeCont}>
                {configJSON.paperSizes.map((item) => (
                  <Button
                    key={item}
                    className={classes.pageSelectBtn}
                    onClick={() => {
                      this.handleLatterType(item);
                    }}
                    style={{
                      backgroundColor: APP_COLORS.app_theme_yellow_color,
                      backgroundColor:
                        this.state.selectedPageSize.indexOf(item) > -1
                          ? "#32795f"
                          : APP_COLORS.app_theme_yellow_color,
                    }}
                  >
                    <img src={fileIcon} style={{ marginRight: 7 }} alt="" />
                    {item}
                  </Button>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.tabContentLeft}>
              <Button
                className={classes.saveChangesBtn}
                onClick={this._saveAndContinueTapped}
                style={{
                  backgroundColor: APP_COLORS.app_theme_green_color,
                }}
              >
                {configJSON.btnTextSaveChanges}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "95%",
      margin: "auto",
    },
    rightSideCont: {
      // height:'100%',
      background: "#f7f7f7",
      // display:'flex',
      // alignItems:'center',
      // justifyContent:'center'
    },
    totalCont: {
      width: "95%",
      margin: "auto",
    },
    tabNamesCont: {
      // background:'#ff0000',
      // height:'100%'
      paddingTop: 20,
    },
    tabContentCont: {
      background: "white",
      borderRadius: "12px 0 0 12px",
      paddingTop: 20,
      paddingBottom: 30,
      // height:200
    },
    tabContentLeft: {
      width: "85%",
      margin: "auto",
    },
    tabImgContentLeft:{
      width: "85%",
      margin: "10px auto",
      display: "flex",
      alignItems: "center",
    },
    headingCont: {
      display: "flex",
      alignItems: "center",
      marginBottom: 30,
      justifyContent: "space-between",
    },
    printTypeCont: {
      display: "flex",
      width: "100%",
      cursor : 'pointer',
      alignItems: "center",
      // justifyContent:'space-between',
      flexWrap: "wrap",
    },
    tabNameSelected: {
      background: "#32795f",
      borderRadius: 12,
      padding: "10px 10px",
      display: "flex",
      marginTop: 15,
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tabName: {
      background: "white",
      borderRadius: 12,
      padding: "10px 10px",
      display: "flex",
      marginTop: 15,
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "space-between",
    },

    //Image Styling
    headingIcon: {
      width: 50,
      height: 50,
      objectFit: "contain",
    },
    tabIcon: {
      width: 45,
      height: 45,
      objectFit: "contain",
    },
    tabNextIcon: {},

    //Text Styling
    screenHeading: {
      fontSize: 22,
      // fontWeight:'bold',
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    basicInfo: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginTop: 7,
    },
    inputLabel: {
      fontSize: 14,
      marginTop: 15,
      letterSpacing: 1,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    sofiaPro:{
      fontFamily: APP_FONTS.SofiaProRegular,
      marginLeft:20,
      marginTop:20
    },
    spanPrintTypeImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    tabNameTextSelected: {
      fontSize: 16,
      width: "80%",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "white",
      marginLeft: 15,
    },
    tabNameText: {
      fontSize: 16,
      width: "80%",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginLeft: 15,
    },
    tabBottomText: {
      fontSize: 16,
      width: "80%",
      cursor: "pointer",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      margin: "10px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    //Input Styling
    inputTextBox: {
      padding: "5px 10px",
      width: "100%",
      marginTop: 7,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },

    //Button styling
    timeSelectBtn: {
      color: "white",
      textTransform: "none",
      borderRadius: 4,
      marginRight: "10px",
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    pageSelectBtn: {
      color: "white",
      textTransform: "none",
      borderRadius: 4,
      marginRight: "10px",
      marginTop: 15,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    saveChangesBtn: {
      color: "white",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "100%",
      height: 50,
      marginRight: "10px",
      marginTop: 15,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    cameraIcon: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    inputContainer: {
      position: "relative",
    },
    profileImage: {
      height: "90px",
      width: "90px",
      objectFit:"contain"
    },
    mandatoryMark:{
      color:'red'
    }
  });

const EditMerchantBusinessDetailsWithConfirmBox = withConfirmBox(
  EditMerchantBusinessDetails
);
const EditMerchantBusinessDetailsWithLoader = withLoader(
  EditMerchantBusinessDetailsWithConfirmBox
);
const EditMerchantBusinessDetailsWithToast = withToast(
  EditMerchantBusinessDetailsWithLoader
);
export default withRouter(
  withStyles(styles, { withTheme: true })(EditMerchantBusinessDetailsWithToast)
);

// Customizable Area End
