//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  showNotification : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: string;
  authToken: string;
  loading: boolean;
  dataSource: [];
  showFilter: boolean;
  selectedTag: string;
  selectedOrderStatus: string;
  isVisibleDropDown: boolean;
  selectedRangeTag: string;
  startDate: string;
  endDate: string;
  showDatePicker: boolean;
  selectedFrom: boolean;
  allShopsModal:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MerchantOrderHistoryController extends BlockComponent<
Props,
  S,
  SS
  > {
  apiGetOrderHistoryCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
                                getName(MessageEnum.AlertMessage)];

    this.state = {
      id: "",
      authToken: "",
      loading: false,
      dataSource: [],
      showFilter: false,
      selectedTag: "",
      selectedOrderStatus: "none",
      isVisibleDropDown: false,
      selectedRangeTag: "",
      startDate: "",
      endDate: "",
      showDatePicker: false,
      selectedFrom: false,
      allShopsModal:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    //   runEngine.debugLog("Message Recived", message);
    if(getName(MessageEnum.AlertMessage) === message.id){
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      await this.setState({ dataSource: [] });
      this.props.showNotification(AlertBodyMessage,'error');  
      
  } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // this.parseApiErrorResponse(errorReponse);
      if (apiRequestCallId === this.apiGetOrderHistoryCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          await this.setState({ dataSource: responseJson.data });
        } else if (responseJson && responseJson.message) {
          await this.setState({ dataSource: [] });
          this.props.showNotification(responseJson.message,'error');
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  modalShowHideProps = {
    onPress: () => {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };



  // Customizable Area Start

  getCurrentOrders(isFiltered: boolean) {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken
    };

    this.setState({ loading: true });
    this.props.showLoader();


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrderHistoryCallId = requestMessage.messageId;

    let getOrderHistoryAPIEndPoint = (isFiltered ? (this.state.selectedOrderStatus == 'all') ? (configJSON.getCurrentOrdersAPIEndPoint) : ('' + configJSON.getCurrentOrdersAPIEndPoint + '?filter[status]=' + this.state.selectedOrderStatus) : (configJSON.getCurrentOrdersAPIEndPoint));

    if (isFiltered) {
      if (this.state.selectedOrderStatus == 'all') {
        if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
          getOrderHistoryAPIEndPoint = `${getOrderHistoryAPIEndPoint}` + "?filter[time_from]=" + this.state.startDate + '&filter[time_to]=' + this.state.endDate
        }
      } else {
        if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
          getOrderHistoryAPIEndPoint = `${getOrderHistoryAPIEndPoint}` + "&filter[time_from]=" + this.state.startDate + '&filter[time_to]=' + this.state.endDate
        }
      }
    } else {
      if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
        getOrderHistoryAPIEndPoint = `${getOrderHistoryAPIEndPoint}` + "?filter[time_from]=" + this.state.startDate + '&filter[time_to]=' + this.state.endDate
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getOrderHistoryAPIEndPoint)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
