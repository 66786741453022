// @ts-nocheck
import React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { APP_FONTS } from './AppFonts';
import { APP_COLORS } from './AppColorConstants';
import Scale from './Scale';
interface IState {
    otp: [string];
    getOtp: (otp: string) => void;
}

class OTPInputView extends React.Component<IState> {
    constructor(props: IState) {
        super(props);
        this.state = {
            otp: [],
        };
    }

    otpTextInput: Array<TextInput> = [];
    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps.isResetValue ?
         {
            otp: nextProps.otp,
        } : null
      }

    componentWillUnmount() {
       // this.otpTextInput[0].focus();
       this.otpTextInput = [];
    }
    // resetOTP = () => {
    //     this.setState({otp : ["","","","","",""]},()=>{
    //         this.props.reset();
    //     })
    // }
    renderInputs() {
        const inputs = Array(6).fill(0);
        const txt = inputs.map((i, j) => (
            <View key={j} style={styles.inputView}>
                <TextInput
                    style={styles.input}
                    secureTextEntry={this.props.isSecureType ? true : false}
                    keyboardType="numeric"
                    onChangeText={(v) => this.focusNext(j, v)}
                    onKeyPress={(e) => this.focusPrevious(e.nativeEvent.key, j)}
                    ref={(ref: TextInput) => (this.otpTextInput[j] = ref)}
                    value={this.state.otp[j]}
                />
            </View>
        ));
        return txt;
    }

    focusPrevious(key: string, index: number) {
        if (key === 'Backspace' && index !== 0)
            this.otpTextInput[index - 1].focus();
    }

    focusNext(index: number, value: string) {
        if (index < this.otpTextInput.length - 1 && value) {
            this.otpTextInput[index + 1].focus();
        }
        if (index === this.otpTextInput.length - 1) {
            this.otpTextInput[index].blur();
        }
        const otp = this.state.otp;
        otp[index] = value.charAt(value.length - 1);
        this.setState((prev)=> ({ otp : prev.otp }));
        if(!this.props.isResetValue){
            this.props.getOtp(otp.join(''));
        }
        
       // console.log(otp.join(''));
    }

    render() {
        return <View style={styles.container}>{this.renderInputs()}</View>;
    }
}
OTPInputView.defaultProps = { isSecureType: false,isResetValue : false,otp : [] }
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    inputView: {
        width: Scale(10.8),
        height: Scale(11.3),
        borderWidth: 1.5,
        borderRadius: 4,
        marginEnd:Scale(2),
        borderColor: '#8f92a1',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: Scale(3.5),
      height:'100%',
        width:'100%',
        color: APP_COLORS.label_color,
        fontFamily: APP_FONTS.SofiaProRegular
    },
});

export default OTPInputView;
