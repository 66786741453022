//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
import { RouterProps } from "react-router";
export type Props = RouterProps & {
  id: string;
  classes: any;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  documentSelected: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  orderDetail: any;
  orderId: number;
  authToken: string;
  orderAttachment: any;
  loading: boolean;
  orderNumber: number;
  documentsCount: number;
  isDownloadClicked: number;
  customerId: any;
  OTP: string;
  counter: number;
  restartTimer: boolean;
  otpVerified: boolean;
  openModel: boolean;
  openAllPrintModel: boolean;
  isPrintingFinished: boolean;
  printAllDocumentStarted: boolean;
}

interface SS {
  id: any;
}

export default class UserOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetOrderDetailsCallId: String = "";
  apiUpdateOrderStatusCallId: String = "";
  apiUpdateOrderPrintingFromScannedCallId: String = "";
  apiUpdateOrderPrintingCompletedCallId: String = "";
  apiVerifyOTPCallId: String = "";
  state = {
    orderDetail: {},
    orderAttachment: [],
    loading: false,
    orderNumber: 0,
    documentsCount: 0,
    isDownloadClicked: false,
    authToken: "",
    counter: 120,
    orderId: 0,
    customerId: null,
    restartTimer: false,
    otpVerified: false,
    restartTimer: false,
    openModel: false,
    openAllPrintModel: false,
    isPrintingFinished: false,
    printAllDocumentStarted: false,
    documentSelected: {}
  };
  apiGetOTPLCallId: String = "";
  apiVerifyOTPCallId: String = "";
  apiUpdateOrderStatusComplete: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, "error");
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiUpdateOrderStatusComplete) {
        await this.setState({ loading: false });

        if (responseJson && responseJson.data) {
          this.props.hideLoader();
          this.props.showNotification(
            "Order Status updated to Completed!!!",
            "success"
          );
          this.props.history.push(
            "/MerchantOrderManagement/OrderHistory/History"
          );
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          // this.sendAPIFailMessage();
        }
      } else if (
        apiRequestCallId === this.apiUpdateOrderPrintingCompletedCallId
      ) {
        await this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          // this.setState({ isPrintingFinished: true }, () => {
            this.props.showNotification(
              "Order Status updated to Printing Completed!!!",
              "success"
            );
            setTimeout(() => {
              this.props.history.push(
                "/MerchantOrderManagement/OrderHistory/History"
              );
            }, 1000);
          // });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (
        apiRequestCallId === this.apiUpdateOrderPrintingFromScannedCallId
      ) {
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          // this.setState({ openAllPrintModel: true }, () => {
            this.props.showNotification(
              "Order status has been updated as with printing",
              "success"
            );
            this.getOrderDetailsApiCall();
          // });
        } else if (responseJson && responseJson.message) {
          this.props.showNotification(responseJson.message, "warn");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiVerifyOTPCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.message) {
          this.props.showNotification(responseJson.message, "success");
          this.setState({ otpVerified: true },()=>{
            
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiGetOTPLCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.props.showNotification("OTP sent successfully to the user", "success");
          this.setState({
            restartTimer: true,
          });
        } else if (responseJson && responseJson.message) {
          this.props.showNotification(responseJson.message, "warn");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiGetOrderDetailsCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.handleDocumentsState(responseJson?.data?.attributes?.order_attachments?.attachment_details.length)
          await this.setState({
            customerId: responseJson?.data?.attributes?.customer_details?.id,
            orderDetail: responseJson?.data?.attributes,
            orderAttachment:
              responseJson?.data?.attributes?.order_attachments
                ?.attachment_details,
                openAllPrintModel : responseJson?.data?.attributes.status === 'Printing',
                isPrintingFinished : responseJson?.data?.attributes.status === 'Printingcompleted'
          });
        } else if (responseJson && responseJson.message) {
          await this.setState({ orderDetail: {} });
          await this.setState({ orderAttachment: [] });
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiUpdateOrderStatusCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
            this.props.showNotification(
              "Order status has been updated as with printing",
              "success")
        } else if (responseJson && responseJson.message) {
          this.props.showNotification(responseJson.message, "error");
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  updateOrderStatusToPrinting = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateOrderPrintingFromScannedCallId = requestMessage.messageId;
    let getOrderDetailsAPIEndPoint = (
      "" + configJSON.getOrderDetailsAPIEndPoint
    )
      .split("orderId")
      .join("" + this.state.orderId);
    getOrderDetailsAPIEndPoint =
      getOrderDetailsAPIEndPoint + "?data[status]=Printing";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getOrderDetailsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async updateOrderStatus(
    isFinish: boolean,
    isAskedRefund: boolean,
    reasonForRefund: string
  ) {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderStatusComplete = requestMessage.messageId;

    let getOrderDetailsAPIEndPoint = (
      "" + configJSON.getOrderDetailsAPIEndPoint
    )
      .split("orderId")
      .join("" + this.state.orderId);

    getOrderDetailsAPIEndPoint =
      getOrderDetailsAPIEndPoint + "?data[status]=Completed";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getOrderDetailsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async printingFinshedOrderUpdate(from: number) {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderPrintingCompletedCallId = requestMessage.messageId;

    let updateOrderStatusAPIEndPoint = (
      "" + configJSON.getOrderDetailsAPIEndPoint
    )
      .split("orderId")
      .join("" + this.state.orderId);

    updateOrderStatusAPIEndPoint =
      updateOrderStatusAPIEndPoint + "?data[status]=Printingcompleted";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateOrderStatusAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start

  getOrderDetailsApiCall() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });
    this.props.showLoader();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrderDetailsCallId = requestMessage.messageId;

    let getOrderDetailsAPIEndPoint = (
      "" + configJSON.getOrderDetailsAPIEndPoint
    )
      .split("orderId")
      .join("" + this.state.orderId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getOrderDetailsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOtpToDownloadDocs(from: number) {
    this.getOtpFrom = from;
    const data = {
      customer_id: this.state.customerId,
    };
    const httpBody = {
      data: data,
    };

    this.setState({ loading: true });
    this.props.showLoader();
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOTPToDownloadDocsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return;
  }
  validateOtpCall = async () => {
    let OTP: any = this.state.OTP;
    let error: any = "";
    error = this.validateOTPNumber(OTP);

    if (error) {
      this.props.showNotification(error, "error");
      return;
    }

    this.setState({ loading: true });
    this.props.showLoader();
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    const httpBody = {
      pin: this.state.OTP,
      customer_id: this.state.customerId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVerifyOTPToDownloadDocsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateOTPNumber(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "OTP " + "can't be blank";
    } else if (!this.isValid4DigitPOTP(otp)) {
      error = "OTP is not valid";
    }
    return error;
  }
  isValid4DigitPOTP(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 4
    );
  }

  // Customizable Area End
}
