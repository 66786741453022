// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Grid, Typography, Button,InputBase,Collapse } from '@material-ui/core';
import { withRouter } from "react-router";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from '../../../components/src/AppColorConstants';
// Customizable Area End

import FooterController, {
  Props,
  configJSON
} from "./FooterController.Web";
import './css/Header.css'
import {
  facebookIcon,
  githubIcon,
  linkedinIcon,
  twitterIcon,
  instgramIcon,
} from './assets'

class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
  }

   async componentDidMount() {
     this.getFooterContent()
   }


  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.parentCont}
      >
        <Grid item xs={12} className={classes.footerCont}>
          <div className={classes.footerIconsCont} style={{marginTop:40}}>
            <img src={facebookIcon} alt="" className={classes.footerIcon}/>
            <img src={linkedinIcon} alt="" className={classes.footerIcon}/>
            <img src={githubIcon} alt="" className={classes.footerIcon}/>
            <img src={instgramIcon} alt="" className={classes.footerIcon}/>
            <img src={twitterIcon} alt="" className={classes.footerIcon}/>
          </div>
          <div className={classes.footerIconsCont} style={{marginTop:40}}>
            <Typography onClick={()=>this.props.history.push("/Privacy-policy")} className={classes.footerText}>{this.state.footer?.menu?.item1}</Typography>
            <Typography onClick={()=>this.props.history.push("/Terms-of-service")} className={classes.footerText}>{this.state.footer?.menu?.item2}</Typography>
          </div>
          <div className={classes.footerIconsCont} style={{marginBottom:40,marginTop:20}}>
            <Typography className={classes.footerText}>{this.state.footer?.menu?.item3}</Typography>
          </div>
        </Grid>
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },

  footerCont:{
    backgroundColor:APP_COLORS.app_theme_green_color,
    borderRadius:32,
    boxShadow: "0 -1px 6px 0 rgba(0, 0, 0, 0.5)",
  },
  TestsCont:{
    minHeight:400,
    backgroundColor:"#f7f7f7",
    display:"flex",
    padding:"30px 10px",
    alignItems:'center',
    justifyContent:"space-evenly"
  },
  footerIconsCont:{
    display:"flex",
    width:"fit-content",
    margin:"auto"
  },

  footerIcon:{
    width:30,
    height:30,
    objectFit:"contain",
    marginRight:10,
    marginLeft:10
  },
  //Text stlyling
  footerText:{
    fontSize:16,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'white',
    marginLeft:10,
    marginRight:10,
    cursor: "pointer"
  },
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(Footer));
