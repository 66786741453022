// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import OrderManagementController, {
  Props,
  configJSON
} from "./OrderManagmentController.Web";
import { Grid, Typography } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider";
import { withRouter,Redirect,Route,Switch } from "react-router";
import  UserOrdersHistory  from "./UserOrdersHistory.Web";
import OrderDetails from "./OrderDetailsQR.Web";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";
import MerchantOrderHistory from "../../OrderManagement/src/MerchantorderHistory.Web"
import MerchantOrderHistoryDetail from "./ScannedOrderDetails.Web";
class OrderManagement extends OrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name,
          merchantName: loginData.shop_name,
          profileImagePath: loginData.profile_image_path,
        });
      }
    });
  }
  
  render() {
    const { classes } = this.props;
       return (
        <Grid container item xs={12} className={classes.rightSideCont}>
          <Grid container className={classes.totalCont}>
            <Grid item xs={12} className={classes.navBar}>
              <Typography className={classes.userName}>
                {this.state.username }
                {this.state.merchantName}
              </Typography>
              <span className={classes.navSpan}>
                {this.state.profileImagePath ? (
                  <img
                    src={this.state.profileImagePath}
                    className={classes.navSpanImg}
                  />
                ) : (
                  <img
                    src={require("../assets/profile_icon.png")}
                    className={classes.navSpanImg}
                  />
                )}
              </span>
            </Grid>
            <Switch>
            <PrivateRoute role="Customer" path={`/OrderManagement/OrderHistory/History`}>
                <UserOrdersHistory status={this.props.status}/>
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/OrderManagement/QRCode/History`}>
                <UserOrdersHistory status={this.props.status}/>
            </PrivateRoute>
            <PrivateRoute role="Customer" path={`/OrderManagement/OrderHistory/OrderDetail`}>
                <OrderDetails />
            </PrivateRoute>
            <PrivateRoute role="Merchant" path={`/MerchantOrderManagement/OrderHistory/History`}>
                <MerchantOrderHistory />
            </PrivateRoute>
            <PrivateRoute role="Merchant" path={`/MerchantOrderManagement/OrderHistory/OrderDetail`}>
                <MerchantOrderHistoryDetail />
            </PrivateRoute>
            <Redirect exact from={'/MerchantOrderManagement/OrderHistory'} to={'/MerchantOrderManagement/OrderHistory/History'}></Redirect>
             <Redirect exact from={'/OrderManagement/OrderHistory'} to={'/OrderManagement/OrderHistory/History'}></Redirect>
             <Redirect exact from={'/OrderManagement/QRCode'} to={'/OrderManagement/QRCode/History'}></Redirect>
            </Switch>
          </Grid>
        </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    rightSideCont: {
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 0px",
    },
    navSpan: {
      height: '45px',
      width: '45px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: '14px',
      marginLeft: '15px',
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
  });

export default withRouter(withStyles(styles, { withTheme: true })(OrderManagement));
