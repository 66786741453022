//@ts-nocheck
import React, { Component, useRef } from "react";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import Select from "@material-ui/core/Select";
import { APP_FONTS } from "../../../components/src/AppFonts";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid, Typography, Button, Modal, InputBase } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider";
import { withRouter } from "react-router";
import PagePrintSettingsController, {
  Props,
  configJSON,
} from "./SelectedDocumentsController.Web";
import DropZone from "./DropZone.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  userLogo,
  settingsImg,
  deleteImg,
  docExcel,
  docImage,
  docWord,
  maskIcon,
  docPdf,
  binIcon,
  docPowerPoint,
  uploadImg,
  colored,
  greyScale,
  portrait,
  textFileIcon,
  landScape, settingsSelectedIcon,
} from "./assets";
const pageSizeArr = [
  { label: "A4", value: "A4" },
  { label: "A2", value: "A2" },
  { label: "A3", value: "A3" },
  { label: "Letter Paper", value: "Letter paper" },
  { label: "Bond Paper", value: "Bond paper" },
];

const scaleArr = [
  { label: "Print all pages", value: "Print all pages", hidden: true },
  { label: "Large pages", value: "Large pages" },
  { label: "Multiple Pages", value: "Multiple Pages" },
];

const printSidesArr = [
  { label: "One sided", value: "One", hidden: true },
  { label: "Both sided", value: "Both" },
];

class SelectedDocuments extends PagePrintSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  _backTapped = () => {
    this.props.history.goBack();
  };

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        console.log("loginData::: ", loginData);
        this.setState({
          authToken: loginData.token,
          id: loginData.id,
        });
      }
    });
    await StorageProvider.get("selectedFiles").then((res) => {
      let selectedAtt = JSON.parse(res);
      if (selectedAtt) {
    //    selectedAtt = this.setDefaultPropertiesForFile(selectedAtt);
        this.setState({
          selectedFileList: selectedAtt,
        });
      }
    });
  }

  _saveChnagesTapped = (isAll: boolean) => {
    const {id : attachmentId }  = this.state.pagePropertiesForSelectedFile;
    const selectedFileList = [...this.state.selectedFileList];
    const editSelectedFileIndex = selectedFileList.findIndex((file)=> {return file.id == attachmentId});
    const editSelectedFile = {...selectedFileList[editSelectedFileIndex],attributes : {...this.state.pagePropertiesForSelectedFile?.attributes}}
    selectedFileList[editSelectedFileIndex] = editSelectedFile;
    this.setState({selectedFileList : selectedFileList,pagePropertiesModal : false},()=>{
      this.updatePrintProperties(isAll);
    })
  };
  BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 5,
      borderRadius: 15,
    },
    colorPrimary: {
      backgroundColor: "#CFDDD8",
    },
    bar: {
      borderRadius: 15,
      backgroundColor: "#32795f",
    },
  }))(LinearProgress);

  _saveAndApplyToAllDocs = (isAll: boolean) => {
    const selectedFileList = [...this.state.selectedFileList];
    for(let i=0;i<selectedFileList.length;i++){
      const editSelectedFile = {...selectedFileList[i],attributes : 
        {...selectedFileList[i].attributes,
        colour : this.state.pagePropertiesForSelectedFile?.attributes?.colour,
        layout : this.state.pagePropertiesForSelectedFile?.attributes?.layout,
        page_size : this.state.pagePropertiesForSelectedFile?.attributes?.page_size,
        print_pages_from : this.state.pagePropertiesForSelectedFile?.attributes?.print_pages_from,
        print_pages_to : this.state.pagePropertiesForSelectedFile?.attributes?.print_pages_to,
        print_sides : this.state.pagePropertiesForSelectedFile?.attributes?.print_sides,
        scale : this.state.pagePropertiesForSelectedFile?.attributes?.scale,
       }}
      selectedFileList[i] = editSelectedFile;
    }
    this.setState({selectedFileList : selectedFileList,pagePropertiesModal : false},()=>{
      this.updatePrintProperties(isAll);
    })
  };

  handleImageChange = (files) => {
    this.setState(
      { uploadedFiles: [ ...files,...this.state.uploadedFiles],
      },
      () => {
        this.uploadFile();
      }
    );
  }

  _handlePageSettingModal = (file,e) => {
    e.preventDefault();
    this.setState({ pagePropertiesModal: !this.state.pagePropertiesModal,pagePropertiesForSelectedFile : file })
  };
  _handleContinueModal = (e) => {
    e.preventDefault();
    this.setState({ continueModal: !this.state.continueModal })
  };

   handleDelete = (id,event) => {
    event.preventDefault();
    const {openDialog} = this.props;
    const onClose = (msg)=>{
        if(msg === 'Yes'){
          this.deleteAttachMent(id);
        }
    }
    this.props.openDialog('Delete','Are you sure, you want to delete?',onClose);
  }

  handleInputChange = (event,value) => {
     if (event.target.name==="print_pages_from" && event.target.value.length>4){}
     else if (event.target.name==="print_pages_to" && event.target.value.length>4){}
     else {
       const newitem = {...this.state.pagePropertiesForSelectedFile,attributes : {...this.state.pagePropertiesForSelectedFile?.attributes,[event.target.name] : (event.target.value || value)}};
       this.setState({pagePropertiesForSelectedFile : newitem});
     }

  }
  handleContinueToPayment=()=>{
     if (this.props?.location?.state?.calledFrom==="NearByShopsToPay" || this.props?.location?.state?.calledFrom==="PaymentScreen"){
       this.props.history.push('/UserSidebarNavigation/UploadDashBoard/Payment');
     }
     else {
       this.createOrder()
     }
  }
  handleContinue = ()=> {
    const {openDialog} = this.props;
    const onClose = async (msg)=>{
        if(msg === 'Yes'){
          let data: any = {};
          data.show_pay_button = true;
          await StorageProvider.remove("showPay");
          await StorageProvider.set("showPay", JSON.stringify(data));
          const options={
            pathname: '/UserSidebarNavigation/UploadDashBoard/SelectShop',
            state: { calledFrom: "SelectedDocumentsScreen" }
          }
          this.props.history.push(options)
        }
    }
    if(this.isSettingAppliedToAllFile()){
      this.props.openDialog('Continue to print','Are you sure, you want to continue?',onClose);
    }
  }
  isSettingAppliedToAllFile = () => {
    let isAppliedToAll = false;
    let unAppliedSettingFileName = ""
    for (let index = 0; index < this.state.selectedFileList.length; index++) {
      const file = this.state.selectedFileList[index];
      if(file && file.attributes){
        if(file?.attributes.colour && file?.attributes.layout && file?.attributes.scale && file?.attributes.print_sides && file?.attributes.print_pages_from && file?.attributes.print_pages_to){
          isAppliedToAll = true;
        } else {
          unAppliedSettingFileName = file?.attributes.file_name;
          isAppliedToAll = false;
          break;
        }
      }
    }
    // this.setState({disableButton: isAppliedToAll});
    if(!isAppliedToAll){
      this.props.showNotification("Please apply setting for "+unAppliedSettingFileName,"warn")
    }
    
    return isAppliedToAll; 
  }
  fileType(fileType: String) {
    const ImageallowedExtensions = /(jpg|jpeg|png|gif|svg)$/i;
    const pptAllowedExtension = /(ppt|pptx)$/i;
    const docAllowedExtension = /(doc|docx)$/i;
    const pdfAllowedExtension = /(pdf)$/i;
    const excelAllowedExtension = /(xlsx|xls|csv)$/i;
    if (ImageallowedExtensions.exec(fileType)) {
      return docImage;
    } else if (pptAllowedExtension.exec(fileType)) {
      return docPowerPoint;
    } else if (docAllowedExtension.exec(fileType)) {
      return docWord;
    } else if (pdfAllowedExtension.exec(fileType)) {
      return docPdf;
    } else if (excelAllowedExtension.exec(fileType)) {
      return docExcel;
    }
    return docExcel;
  }

  render() {
    const { classes } = this.props;
    return (
         <>
            <Grid container className={classes.rightContent}>
              <Grid item xs={12} className={classes.headingCont}>
                <Typography className={classes.selectFilesText}>
                  {configJSON.selectFilesText}
                </Typography>
              </Grid>
              <Grid container spacing={4} className={classes.docsCont}>
              {this.state.uploadedFiles.length > 0 ? this.state.uploadedFiles.map((file) => {
                  return (
                    <Grid key={file.id} item xs={12} sm={6} md={3}>
                      <Grid item xs={12} className={classes.eachDocs}>
                      {(file.type === 'application/msword') || (file.type === 'application/vnd.oasis.opendocument.text') || (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? (<img src={require('../assets/docword.png')} className={classes.docImg} />) :
                    (file.type === 'image/gif') || (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/jpeg') ? (<img src={require('../assets/image_placeholder.jpg')} className={classes.docImg} />) :
                        (file.type === 'application/pdf') ? (<img src={require('../assets/pdf_placeholder.png')} className={classes.docImg} />) :
                            (file.type === 'application/vnd.ms-powerpoint') || (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') || (file.type === 'application/vnd.oasis.opendocument.presentation') ? (<img src={require('../assets/ppt.png')} className={classes.docImg} />) :
                              (file.type === 'text/plain') ? (<img src={textFileIcon} className={classes.docImg} />) :
                                (file.type === 'application/vnd.ms-excel') || (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.type === 'application/vnd.oasis.opendocument.spreadsheet') ? (<img src={require('../assets/docexcel.png')} className={classes.docImg} />) :
                                    (<img src={require('../assets/pdf_placeholder.png')} className={classes.docImg} />)}
              
                        <Typography className={classes.docTitle}>
                          {file?.attributes?.file_name}
                        </Typography>
                        <Typography className={classes.docTitle}>
                          {this.state.uploading ? "Uploading..." : null}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {this.state.uploading} &nbsp; % 
                        </Typography>
                        <div className={classes.progressCont}>
                          <this.BorderLinearProgress
                            variant="determinate"
                            value={this.state.uploading}
                            className={classes.progressBar}
                          />
                        </div>
              
                      </Grid>
                    </Grid>
                  );
                }) : null}
                {this.state.selectedFileList.map((file) => {
                  return (
                    <Grid key={file.id} item xs={12} sm={6} md={3}>
                      <Grid item xs={12} className={file?.attributes?.colour ? classes.eachDocs : classes.eachDocsSettingNotApply }>
                        <div className={classes.docNav}>
                          <img
                            src={maskIcon}
                            alt=""
                            className={classes.docNavImg}
                            onClick={this._handlePageSettingModal.bind(this,file)}
                          />
                          <img
                            src={binIcon}
                            alt=""
                            className={classes.docNavImg}
                            onClick={this.handleDelete.bind(this,file.id)}
                          />
                        </div>
                        {(file.attributes.content_type === 'application/msword') || (file.attributes.content_type === 'application/vnd.oasis.opendocument.text') || (file.attributes.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? (<img src={require('../assets/docword.png')} className={classes.docImg} />) :
                      (file.attributes.content_type === 'image/gif') || (file.attributes.content_type === 'image/png') || (file.attributes.content_type === 'image/jpg') || (file.attributes.content_type === 'image/jpeg') ? (<img src={require('../assets/image_placeholder.jpg')} className={classes.docImg} />) :
                          (file.attributes.content_type === 'application/pdf') ? (<img src={require('../assets/pdf_placeholder.png')} className={classes.docImg} />) :
                              (file.attributes.content_type === 'application/vnd.ms-powerpoint') || (file.attributes.content_type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') || (file.attributes.content_type === 'application/vnd.oasis.opendocument.presentation') ? (<img src={require('../assets/ppt.png')} className={classes.docImg} />) :
                                (file.attributes.content_type === 'text/plain') ? (<img src={textFileIcon} className={classes.docImg} />) :
                                  (file.attributes.content_type === 'application/vnd.ms-excel') || (file.attributes.content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.attributes.content_type === 'application/vnd.oasis.opendocument.spreadsheet') ? (<img src={require('../assets/docexcel.png')} className={classes.docImg} />) :
                                      (<img src={require('../assets/pdf_placeholder.png')} className={classes.docImg} />)}
              
                        <Typography className={classes.docTitle}>
                          {file?.attributes?.file_name ? file?.attributes?.file_name : ""}
                        </Typography>
                        <Typography className={classes.docContent}>
                        All Pages - {file?.attributes?.colour ? file?.attributes?.colour : "Please select settting"}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {file?.attributes?.layout && file?.attributes?.layout} - { file?.attributes?.page_size && file?.attributes?.page_size}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {file?.attributes?.print_pages_to && file?.attributes?.print_pages_to} copy
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
                
              </Grid>
              <Grid
                item
                xs={11}
                style={{cursor:"pointer"}}
              >
                <DropZone
                  onSelectFile={this.handleImageChange}
                  multiple={true}
                />
              </Grid>
              <Grid item xs={11} className={classes.btnCont}>
              {this.state.selectedFileList.length > 0 && <Button
                  className={classes.findPrintersBtn}
                  style={{ backgroundColor: "#32795f" }}
                  onClick={this._handleContinueModal}
                >
                  {configJSON.continuePrintBtnTxt}
                </Button>}  
              </Grid>
            </Grid>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.pagePropertiesModal}
          onClose={this._handlePageSettingModal.bind(this,this.state.pagePropertiesForSelectedFile)}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                {configJSON.printProperties}
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
              <Typography className={classes.modalContentText}>
                {configJSON.pagesToPrint}
              </Typography>
              <div className={classes.colorModalDiv}>
                <div className={classes.pageInputCont}>
                  <InputBase 
                  onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                  className={classes.pageInputModal} type={"Number"}  value={this.state?.pagePropertiesForSelectedFile?.attributes?.print_pages_from} name="print_pages_from" onChange={(event)=>{this.handleInputChange(event)}}/>
                </div>
                <Typography className={classes.modalToText}>
                  {configJSON.pagesTo}
                </Typography>
                <div className={classes.pageInputCont}>
                  <InputBase
                   onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()} 
                  className={classes.pageInputModal} type={"Number"}  value={this.state?.pagePropertiesForSelectedFile?.attributes?.print_pages_to} name="print_pages_to" onChange={(event)=>{this.handleInputChange(event)}}/>
                </div>
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.Colors}
              </Typography>
              <div className={classes.colorModalDiv}>
                <span className={classes.spanModalImg}>
                  <img src={colored} name="colour" value={'Colored'} alt="" style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Colored' ? {  border: "1px solid rgba(143, 146, 161, 1)" } : null } className={classes.colorCopy} onClick={(event)=>{this.handleInputChange(event,'Colored')}}/>
                 <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Colored' ? {  color: "#32795F"} : null } >
                   Colored
                  </p>
                </span>
                <span className={classes.spanModalImg}>
                  <img src={greyScale} name="colour" value={'Greyscale'} alt="" className={classes.colorCopy} style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Greyscale' ? {  border: "1px solid rgba(143, 146, 161, 1)" } : null } onClick={(event)=>{this.handleInputChange(event,'Greyscale')}}/>
                  <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Greyscale' ? {  color: "#32795F"} : null } >
                  Greyscale
                  </p>
                </span>
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.Layout}
              </Typography>
              <div className={classes.colorModalDiv}>
                <span className={classes.spanModalImg}>
                  <img src={portrait} alt="" name="layout" style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Portrait' ? {  border: "1px solid rgba(143, 146, 161, 1)" } : null } value={'Portrait'} className={classes.paperCopy} onClick={(event)=>{this.handleInputChange(event,'Portrait')}}/>
                  <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Portrait' ? {  color: "#32795F"} : null } >
                  Portrait
                  </p>
                </span>
                <span className={classes.spanModalImg}>
                  <img src={landScape} alt="" name="layout" style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Landscape' ? {  border: "1px solid rgba(143, 146, 161, 1)" } : null } value={'Landscape'} className={classes.paperCopy} onClick={(event)=>{this.handleInputChange(event,'Landscape')}}/>
                  <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Landscape' ? {  color: "#32795F"} : null } >
                  Landscape
                  </p>
                </span>
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.pageSize}
              </Typography>
              <Select value={this.state?.pagePropertiesForSelectedFile?.attributes?.page_size} name="page_size" onChange={(event)=>{this.handleInputChange(event)}}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {pageSizeArr.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <Typography className={classes.modalContentText}>
                {configJSON.Scale}
              </Typography>
              <Select value={this.state?.pagePropertiesForSelectedFile?.attributes?.scale} name="scale" onChange={(event)=>{this.handleInputChange(event)}}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {scaleArr.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <Typography className={classes.modalContentText}>
                {configJSON.printSlides}
              </Typography>
              <Select value={this.state?.pagePropertiesForSelectedFile?.attributes?.print_sides} name="print_sides" onChange={(event)=>{this.handleInputChange(event)}}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {printSidesArr.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <Button
                className={classes.closeModalBtn}
                style={{ backgroundColor: "#32795f", marginTop: 20 }}
                onClick={() => {this._saveChnagesTapped(false)}}
              >
                {configJSON.saveBtn}
              </Button>
              <Button
                className={classes.closeModalBtn}
                onClick={() => {this._saveAndApplyToAllDocs(true)}}
                style={{
                  backgroundColor: "transparent",
                  color: "#32795f",
                  marginTop: 10,
                  marginBottom: 50,
                  
                }}
              >
                {configJSON.applyBtn}
              </Button>
            </div>
          </div>
        </Modal>
           <Modal
             disablePortal
             disableEnforceFocus
             disableAutoFocus
             open={this.state.continueModal}
             onClose={this._handleContinueModal}
             aria-labelledby="server-modal-title"
             aria-describedby="server-modal-description"
             className={classes.modalParent}
           >
             <div className={classes.modalContent}>
               <div className={classes.continueModalContentCont}>
                 <div className={classes.btnModalCont}>
                   <Button
                     className={classes.closeModalBtn}
                     style={{ backgroundColor: "#32795f"}}
                     onClick={this.handleContinueToPayment}
                   >
                     {configJSON.continuePayBtn}
                   </Button>
                   <Button
                     className={classes.closeModalBtn}
                     onClick={this.handleContinue}
                     style={{
                       backgroundColor: "transparent",
                       color: "#32795f",
                       marginTop:20
                     }}
                   >
                     {configJSON.findShopsBtn}
                   </Button>
                 </div>
               </div>
             </div>
           </Modal>
         </>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    rightSideCont: {
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      zIndex: "10",
      minHeight: "80%",
      width: "100%",
      backgroundImage: `url(${require("../assets/bg.jpeg")})`,
      borderRadius: 12,
      marginBottom: 40,
    },
    backImg: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: "0",
    },
    headingCont: {
      width: "100%",
    },
    selectFilesText: {
      fontSize: 22,
      margin: "20px 0 30px 20px",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      // fontWeight: "bold",
      color: "#1e1f20",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    backgroundImg: {
      background: "#f7f7f7",
      // height:'100%'
      // backgroundImage:`url(require('../assets/background.png'))`,
      // backgroundRepeat:"repeat"
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docsCont: {
      width: "95%",
      margin: "20px auto",
    },
    eachDocs: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      // height:250,
      // width:'90%',
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    eachDocsSettingNotApply: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      // height:250,
      // width:'90%',
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      opacity : '0.5'
    },
    docNav: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    docNavImg: {
      height: 20,
      width: 20,
      cursor:"pointer",
      marginRight: 5,
      objectFit: "contain",
    },
    descCont: {
      display: "flex",
      borderRadius: 12,
      backgroundColor: "white",
      padding: "10px 20px",
      width: "90%",
      margin: "auto",
    },
    eachDesc: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "auto",
      marginTop: "20px",
    },
    uploadCont: {
      margin: "auto",
      marginTop: "20px",
      border: "2px dotted #77c285",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 12,
    },
    uploadLabel: {
      width: "100%",
    },
    uploadInput: {
      color: "transparent",
    },
    uploadImg: {
      height: 130,
      width: 130,
      margin: "auto",
      objectFit: "contain",
    },
    uploadText: {
      fontSize: 22,
      width: "80%",
      margin: "20px auto",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    docTitle: {
      fontWeight: "bold",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
      margin: "10px 0",
      wordBreak: 'break-all'
    },
    docImg: {
      height: 50,
      width: 65,
      margin: "10px auto",
      objectFit: "contain",
    },
    docContent: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
    },
    btnCont: {
      margin: "auto",
      marginTop: "20px",
      borderRadius: 12,
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },

    //Modal Styling

    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      height: "auto",
      width: "25%",
      minWidth: 270,
      height: "100vh",
      // padding: "20px 0",
      overflowY: "auto",
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    modalContentCont: {
      display: "flex",
      // marginLeft:30,
      flexDirection: "column",
      alignItems: "flex-start",
      // justifyContent:'center'
    },
    continueModalContentCont:{
      // background:"red",
      height:"100%",
      display: "flex",
      // marginLeft:30,
      flexDirection: "column",
      alignItems:'center',
      justifyContent:'center'
    },
    btnModalCont:{
      width:"100%",
      display: "flex",
      alignItems:'center',
      flexDirection: "column",
      justifyContent:'center'
    },
    modalHeadingCont: {
      display: "flex",
      margin: "20px 0",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    modalToText: {
      fontSize: 13,
      fontFamily: APP_FONTS.SofiaProRegular,
      textTransform: "uppercase",
      color: "#1e1f20",
      marginLeft: 20,
    },
    modalContentText: {
      fontSize: 13,
      letterSpacing: 1,
      margin: "20px 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      textTransform: "uppercase",
      color: "#1e1f20",
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: " auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    spanModalImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    colorModalDiv: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    pageInputCont:{
      border: "1px solid rgba(143, 146, 161, 0.4)",
      width: 88,
      height: 48,
      borderRadius: 4,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      marginLeft: 20,
      justifyContent: "center",
    },
    pageInputModal: {
      border:"none",
      outline:"none",
      width:"50%",
      margin:"auto",
      fontSize: "1.2rem",
      color: "#1e1f20",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    selectInputModal: {
      width: "90%",
      // height:48,
      padding: "5px 10px",
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    paperCopy: {
      height: 45,
      width: 60,
      cursor:"pointer",
      marginBottom: 7,
      padding : '5px',
      objectFit: "contain",
    },
    colorCopy: {
      height: 50,
      cursor:"pointer",
      marginBottom: 7,
      width: 50,
      padding : '5px',
      objectFit: "contain",
    },
    borderImage : {
      border: "1px solid rgba(143, 146, 161, 0.4)"
    },
    progressCont: {
      width: "calc(100% - 14px)",
      display: "flex",
      alignItems: "center",
      height: 30,
      justifyContent: "space-between",
    },
    progressCancel: {
      width: 18,
      height: 18,
      objectFit: "contain",
      marginLeft: 10,
      cursor: "pointer",
    },
    progressBar: {
      width: "90%",
      margin: "10px auto",
    },
  });
  const SelectTedDocumentWithConfirmBox = withConfirmBox(SelectedDocuments);
  const SelectTedDocumentWithLoader = withLoader(SelectTedDocumentWithConfirmBox)
  const SelectTedDocumentWithToast = withToast(SelectTedDocumentWithLoader)
  export default withRouter(withStyles(styles, { withTheme: true })(SelectTedDocumentWithToast));

