//@ts-nocheck
import React, { Component } from 'react'
import {  Alert } from 'react-native'
import { Grid, Typography } from "@material-ui/core";
import DropDownPicker from 'react-native-dropdown-picker';
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import { APP_FONTS } from '../../../components/src/AppFonts';

import Scale from "../../../components/src/Scale";
import StorageProvider from '../../../framework/src/StorageProvider';


import OrderPaymentDetailsController, {
  Props,
  configJSON
} from "./OrderPaymentDetailsController.Web";
import HeaderComponent from '../../../components/src/HeaderComponent';
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import {withRouter} from "react-router";

class OrderPaymentDetails extends OrderPaymentDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  _backTapped = () => { this.props.navigation.goBack() };



  confirmationDialog = () => {
    Alert.alert(
      "Place Order!!!",
      "Are you sure want to place order?",
      [
        {
          text: "No",
          style: "cancel"
        },

        { text: "Yes", onPress: () => this.placeOrderApiCall() }
      ],
      { cancelable: true }
    );
  }


  async componentDidMount() {
    // Customizable Area Start
    this.props.showLoader()
    await StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        console.log("loginData::: ", loginData);
        this.setState({
          authToken: loginData.token,
          id: loginData.id
        });
      }
    });
    await StorageProvider.get('orderId').then((res) => {
      const orderIdData = JSON.parse(res);
      if (orderIdData) {
        console.log("orderId:::", orderIdData);
        this.setState({
          orderId: orderIdData.orderId,
          goBack: orderIdData.goBack,
        });

      }
    });

    this.getOrderAmountDetailsApiCall();

  }
  _handleBackClicked=()=>{
    if (this.props.location?.state?.calledFrom==="orderHistory") {
      this.props.history.push('/OrderManagement/OrderHistory/History')
    }
    else {
      const options={
        pathname: '/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen',
        state: { calledFrom: "PaymentScreen" }
      }
      this.props.history.push(options)
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.rightContent}>
        <Grid item xs={12} className={classes.headingCont}>
          {/* <img src={require('../assets/backbtn.svg')} alt="" className={classes.backImg}
               onClick={this._handleBackClicked}
          /> */}
          <Typography className={classes.selectFilesText}>Select Payment Method</Typography>
        </Grid>
        <Grid container  className={classes.paymentCont}>
          <Grid item xs={12} sm={6} md={3} className={classes.upiCont}>
            <div className={classes.upiContent} onClick={()=>this.placeOrderApiCall()}>
              <img src={require('../assets/group-3.svg')} alt="" className={classes.upiImg}/>
              <div>
                <Typography className={classes.payByText}>Pay By</Typography>
                <Typography className={classes.payMethodText}>UPI</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.upiCont}>
            <div className={classes.upiContent} onClick={()=>this.placeOrderApiCall()}>
              <img src={require('../assets/card.svg')} alt="" className={classes.upiImg}/>
              <div>
                <Typography className={classes.payByText}>Pay By</Typography>
                <Typography className={classes.payMethodText}>Other Methods</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.detailsCont}>
            <div className={classes.detailsText}>
              <Typography className={classes.detailsAmountText}>Order Amount</Typography>
              <Typography className={classes.detailsAmountText}>{'Rs.' + (this.state.orderAmount ? parseFloat(this.state.orderAmount).toFixed(2) : "00.00")}</Typography>
            </div>
            <div className={classes.detailsText}>
              <Typography className={classes.detailsAmountText}>Taxes</Typography>
              <Typography className={classes.detailsAmountText}>{'Rs.' + (this.state.taxAmount ? parseFloat(this.state.taxAmount).toFixed(2) : "00.00")}</Typography>
            </div>
            <div className={classes.detailsText}>
              <Typography className={classes.detailsTotalText}>Total Amount</Typography>
              <Typography className={classes.detailsTotalText}>{'Rs.' + (this.state.totalAmount ? parseFloat(this.state.totalAmount).toFixed(2) : "00.00")}</Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    rightSideCont:
      {
        // height:'100%',
        background:'#f7f7f7',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      },
    totalCont:{
      width:'90%',
      margin:"auto"
    },
    rightContent:{
      position:"relative",
      width:'100%',
      background:`#f7f7f7`,
      borderRadius:12,
      marginBottom:40
    },
    headingCont:{
      width:'90%',
      display:"flex",
      margin:"20px",
      alignItems:"center"
    },
    paymentCont:{
      width:'90%',
      display:"flex",
      margin:"20px",
      alignItems:"center",
      justifyContent:"space-between",
    },
    upiCont:{
      height:220,
      marginTop:20,
      cursor:"pointer"
    },
    upiContent:{
      width:'90%',
      display:"flex",
      height:'100%',
      borderRadius:12,
      flexDirection:"column",
      justifyContent:"space-evenly",
      margin:"auto",
      backgroundColor:"#ffffff",
    },
    detailsText:{
      width:'70%',
      margin:"5px auto",
      display:"flex",
      justifyContent:"space-between",
    },
    selectFilesText:{
      fontSize:22,
      marginLeft:20,
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color:'#1e1f20'
    },
    payByText:{
      fontSize:16,
      marginLeft:'20%',
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color:'#8f92a1',
      marginTop:8
    },
    payMethodText:{
      fontSize:17,
      marginLeft:'20%',
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color:'#1e1f20',
      marginTop:8
    },
    detailsAmountText:{
      fontSize:16,
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color:'#8f92a1',
      marginTop:8
    },
    detailsTotalText:{
      fontSize:17,
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign: 'left',
      color:'#1e1f20',
      marginTop:20
    },
    navBar:{
      display:'flex',
      justifyContent:'flex-end',
      alignItems:'center',
      margin:"20px 30px"
    },
    navSpan:{
      height:45,
      width:45,
      backgroundColor:'#32795f',
      display:'flex',
      alignItems: 'center',
      justifyContent:'center',
      borderRadius: 14,
      marginLeft: 15,
      marginRight:30
    },
    userName:{
      fontFamily:APP_FONTS.SofiaProRegular,
      color:'#1e1f20'
    },
    navSpanImg:{
      height:20,
      width:20,
      objectFit:'contain'
    },
    backImg:{
      height:40,
      width:40,
      cursor:"pointer",
      objectFit:'contain'
    },
    upiImg:{
      height:45,
      width:45,
      marginLeft:'20%',
      objectFit:'contain'
    }
});

const OrderPaymentDetailsWithConfirmBox = withConfirmBox(OrderPaymentDetails);
const OrderPaymentDetailsWithLoader = withLoader(OrderPaymentDetailsWithConfirmBox)
const OrderPaymentDetailsWithToast = withToast(OrderPaymentDetailsWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(OrderPaymentDetailsWithToast));