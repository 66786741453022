// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
import { RouterProps  } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider";

export type Props = RouterProps & {
  id: string;
  showNotification : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  currentCountryCode: any;
  currentPasscode: string;
  newPasscode: string;
  confirmednNewPasscode: string;
  loading: boolean;
  id: string;
  authToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ChangePasscodeController extends BlockComponent<
  Props,
  S,
  SS
  > {

  labelPhoneNumber: string;
  labelSaveBtn: string;
  labelLogin: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  apiChangePasscodeCallId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage)
    ]

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "🇮🇳 +91",
      enableField: false,
      currentPasscode: "",
      newPasscode: "",
      confirmednNewPasscode: "",
      loading: false,
      id: "",
      authToken: "",
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelSaveBtn = configJSON.labelSaveBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.phoneReg = new RegExp(/^[0-9]+$/);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });

    // }
    if(getName(MessageEnum.AlertMessage) === message.id){
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage,'error');  
     } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      runEngine.debugLog("selectedCode", selectedCode);
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiChangePasscodeCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
          //TODO: Need to uncomment this to go with the login flow
          const keys=  await StorageProvider.getKeys();
          await StorageProvider.removeMultiple(keys)
          this.props.showNotification("Passcode changed Successfully","success")
          
          setTimeout(() => {
            this.props.history.push("/PhoneLoginWeb");    
          }, 2000);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
          this.sendAPIFailMessage();
        }

        // this.parseApiCatchErrorResponseNew(errorReponse);
        //this.setState({isError: true, errorMessage:  this.parseApiCatchErrorResponseNew(errorReponse)})
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // Customizable Area End
  //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
  validateChangePasscodeCall() {
    let currentPasscode: any = this.state.currentPasscode;
    let error: any = "";
    
    const validateCurrnetPasscodeError = this.validatePasscode(currentPasscode, "Current Passcode");
    if (validateCurrnetPasscodeError) {
      this.setState({CurrnetPasscodeError: validateCurrnetPasscodeError});
      return;
    }

   const newPasscodeError = this.validateNewPasscode(this.state.newPasscode, "New Passcode");
    if (newPasscodeError) {
      this.setState({newPasscodeError: newPasscodeError});
      return;
    }

    const validateConfirmedPasscodeError= this.validateConfirmPasscode(this.state.confirmednNewPasscode, "Confirmed New Passcode");
    if (validateConfirmedPasscodeError) {
      this.setState({confirmedPasscodeError: validateConfirmedPasscodeError});
      return;
    }

    if (this.state.newPasscode != this.state.confirmednNewPasscode) {
      this.setState({matchPasscodeError: "Both passcode doesn't match"});
      return;
    }

    this.setState({ loading: true });
    this.props.showLoader();

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser
    };

    const data = {
      current_password: this.state.currentPasscode,
      new_password: this.state.newPasscode
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePasscodeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (configJSON.changePasscodeAPIEndPoint + this.state.id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }
  validateNewPasscode(otp: string, passcodeType: string){
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "Enter your new 6 digit passcode"
    } else if (!this.isValid6DigitPasscode(otp)) {
      error = "Passcode is not valid. Please enter valid your new 6 digit Passcode";
    }
    return error;
  }

  validatePasscode(otp: string, passcodeType: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "Enter your old 6 digit passcode"
    } else if (!this.isValid6DigitPasscode(otp)) {
      error = "Passcode is not valid. Please enter valid your old 6 digit Passcode";
    }
    return error;

  }
  validateConfirmPasscode(otp: string, passcodeType: string){
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "Confirm your new 6 digit passcode"
    } else if (!this.isValid6DigitPasscode(otp)) {
      error = "Passcode is not valid. Please enter valid your confirm new 6 digit Passcode";
    }
    return error;
  }

  isValid6DigitPasscode(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 6
    );
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  notifyMessage(msg: string) {
    // if (Platform.OS === 'android') {
    //   ToastAndroid.show(msg, ToastAndroid.SHORT)
    // }
    this.showAlert("Alert!!!", msg);
  }
}
