//@ts-nocheck
import React from "react";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import { APP_FONTS } from "../../../components/src/AppFonts";

import MerchantDashboradHomeController, {
  Props,
  configJSON,
} from "./MerchantDashboradHomeController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import {
  shopQrImg,
  attachIcon,
  checkIcon,
  navBarImg,
  arrowRight,
} from "./assets";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider";
import moment from "moment";
import QrReader from "react-qr-scanner";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
class MerchantDashboradHome extends MerchantDashboradHomeController {
  constructor(props: Props) {
    super(props);
    // this.state = {
    //   delay: 100,
    //   result: null,
    // }
  }
  async componentDidMount() {
    let businessDetailsData = await StorageProvider.get("businessDetailsData");
    if(businessDetailsData){
       businessDetailsData = JSON.parse(businessDetailsData);
       this.setState({
        merchantId: businessDetailsData.merchantId,
      });
    }
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          authToken: loginData.token,
          id: loginData.id,
        });
      }
    });
    await this.getLastOrder();
  }
  navigateToOrderDetail = async (order) => {
    if (order.status!=="Completed"){
      await StorageProvider.set("scannedOrderDetail", JSON.stringify(order));
      this.props.history.push("/MerchantSidebarNavigation/DashBoard/OrderDetail");
    }
  };

  openScanner = () => {
    this.setState({ isOpenScanner: true });
  };
  handleScan = (data) => {
    this.setState(
      {
        orderId: data,
      },
      () => {
        console.log(this.state.orderId);
        if (this.state.orderId) {
          this.setState({ isOpenScanner: false }, () => {
            this.updateOrderMerchant();
          });
        }
      }
    );
  };
  handleError = (err) => {
    console.error(err);
    this.setState({scannerAccessError : err},()=>{
      this.setState({ isOpenScanner: false })
    })
  };
  handleClose = () => {
    this.setState({scannerAccessError : null,isOpenScanner: false})
  }
  render() {
    const { classes } = this.props;
    const previewStyle = {
      height: "100%",
      width: "100%",
    };
    return this.state.isOpenScanner ? (
      <>
      <div style={{ width: "70%", margin: "auto",display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center' }}>
        <img src={require('../assets/close@3x.png')} style={{cursor:"pointer"}} onClick={this.handleClose} />
      </div>
      <div style={{ width: "70%", margin: "20px auto" }}>
        <QrReader
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
        />
      </div>
      </>
    ) : this.state.scannerAccessError ? <Dialog
    open={this.state.scannerAccessError}
    onClose={this.handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Access to Camera?"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        We Are Not Able to access your Camera.Please use mobile to scan customer QR code.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={this.handleClose} color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog> : (
      <Grid container className={classes.rightContent}>
        <Grid item xs={12} className={classes.headingCont}>
          <div className={classes.headingLeft}>
            <Typography className={classes.selectFilesText}>
              {configJSON.pageTitle}
            </Typography>
            <img
              src={shopQrImg}
              alt=""
              className={classes.backIcon}
              onClick={this.openScanner}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.recentOrdersCont}>
          <Typography className={classes.recentOrdersText}>
            {configJSON.recentOrders}
          </Typography>
          {(this.state.lastOrder && this.state.lastOrder.length > 0) && 
            <Typography className={classes.seeAll} onClick={()=>{this.props.history.push(
              "/MerchantOrderManagement/OrderHistory/History"
            );}}>
            {configJSON.seeAll}
            <img src={arrowRight} style={{ marginLeft: 7 }} alt="" />
          </Typography>
          }
        </Grid>
        <Grid container className={classes.completeOrdersCont}>
          {(this.state.lastOrder && this.state.lastOrder.length > 0) ?
          this.state.lastOrder.map((order) => (
            <Grid
              key={order.order_number}
              item
              md={4}
              sm={6}
              xs={12}
              className={classes.eachReadyOrder}
              onClick={() => {
                this.navigateToOrderDetail(order);
              }}
            >
              <div className={classes.eachOrderDiv}>
                <div
                  className={classes.leftBorder}
                  style={{ background: "#77c285" }}
                />
                <div className={classes.rightReadyCont}>
                  <Typography className={classes.pOrderNumber}>{`Order # ${
                    order.order_number
                  }`}</Typography>
                  <Typography className={classes.pOrderTime}>
                    {moment(order.created_at).format("DD/MM/yyyy hh:mm A")}
                  </Typography>
                  <Typography className={classes.pOrderExpiry}>
                    {this.state.orderUserName}
                  </Typography>
                  <Typography className={classes.pOrderAmount}>{`Rs.${
                    parseFloat(order.total_amount.toString()).toFixed(2)
                  }`}</Typography>
                  <div className={classes.readyEndCont}>
                    <span className={classes.spanImgReady1}>
                      <img
                        className={classes.readyImg}
                        src={attachIcon}
                        alt=""
                      />
                      {order.total_docs} Docs
                    </span>
                    <span className={classes.spanImgReady2}>
                      <img
                        className={classes.readyImg}
                        src={checkIcon}
                        alt=""
                      />
                      {order.status}
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
          )) : <Grid item xs={4} style={{width : '40%',margin : 'auto',fontFamily:APP_FONTS.SofiaProRegular}}>No recent order for this merchant</Grid>}
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      margin:"0 auto 40px",
      // background:`#ffffff`,
      borderRadius: 12,
    },
    headingCont: {
      width: "90%",
      margin: "auto",
      padding: "20px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    recentOrdersCont: {
      display: "flex",
      alignItems: "center",
      marginTop: 15,
      justifyContent: "space-between",
    },
    readyOrdersCont: {
      margin: "10px auto",
    },
    completeOrdersCont: {
      margin: "30px auto",
    },
    eachReadyOrder: {
      marginTop: 20,
    },
    eachOrderDiv: {
      width: "90%",
      margin: "auto",
      display: "flex",
      justifyContent: "flex-start",
      backgroundColor: "#ffffff",
      borderRadius: 12,
      padding: "10px 5px",
    },
    leftBorder: {
      width: 5,
      marginLeft: 5,
    },
    rightReadyCont: {
      width: "calc(100% - 5px)",
      marginLeft: 10,
      paddingLeft: 7,
    },
    readyEndCont: {
      display: "flex",
      alignItems: "center",
      // padding:"0 10px",
      marginTop: 20,
      marginBottom: 10,
      justifyContent: "space-between",
    },
    mapsCont: {
      width: "100%",
      margin: "auto",
      position: "relative",
      minHeight: "80vh",
      display: "flex",
      alignItems: "flex-end",
    },
    mapContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    recentOrdersText: {
      fontSize: 18,
      fontFamily: APP_FONTS.SofiaProRegular,
      // fontWeight: "bold",
      color: "#1e1f20",
    },
    selectFilesText: {
      fontSize: 22,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      // fontWeight: "bold",
      color: "#1e1f20",
    },
    seeAll: {
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      cursor:"pointer",
      fontFamily: APP_FONTS.SofiaProRegular,
      // fontWeight: "bold",
      color: "#1e1f20",
    },
    pOrderNumber: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      marginTop: 10,
    },
    pOrderTime: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      marginTop: 15,
      color: "#8f92a1",
    },
    pOrderExpiry: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      marginTop: 7,
      color: "#1e1f20",
    },
    pOrderAmount: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      marginTop: 7,
      textAlign: "right",
      marginRight: 5,
      color: "#1e1f20",
    },
    spanImgReady1: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      display: "flex",
      alignItems: "center",
      color: "#8f92a1",
    },
    spanImgReady2: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      display: "flex",
      padding: 5,
      marginRight: 5,
      alignItems: "center",
      color: "#1e1f20",
      backgroundColor: "#f7f7f7",
      borderRadius: 4,
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 30px 30px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    backIcon: {
      width: 160,
      height: 160,
      marginLeft: 15,
      marginTop: 15,
      cursor: "pointer",
      objectFit: "contain",
    },
    readyImg: {
      width: 20,
      height: 20,
      marginRight: 8,
      objectFit: "contain",
    },

    //Modal Styling
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      width: "25%",
      minWidth: 270,
      height: "100vh",
      padding: "20px 0",
      overflowY: "scroll",
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    modalContentCont: {
      display: "flex",
      // marginLeft:30,
      flexDirection: "column",
      alignItems: "flex-start",
      // justifyContent:'center'
    },
    modalHeadingCont: {
      display: "flex",
      margin: "20px 0",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    printerName: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      marginTop: 10,
      marginBottom: 10,
    },
    printerAddress: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginBottom: 10,
    },
    modalContentText: {
      fontSize: 13,
      letterSpacing: 1,
      margin: "20px 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      textTransform: "uppercase",
      color: "#1e1f20",
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: " auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    spanModalImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    colorModalDiv: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    pageInputModal: {
      width: 48,
      height: 48,
      paddingLeft: 15,
      textAlign: "center",
      fontSize: "1.2rem",
      color: "#1e1f20",
      borderRadius: 4,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
    },
    selectInputModal: {
      width: "90%",
      // height:48,
      padding: "5px 10px",
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    paperCopy: {
      height: 45,
      width: 60,
      marginBottom: 7,
      objectFit: "contain",
    },
    colorCopy: {
      height: 50,
      marginBottom: 7,
      width: 50,
      objectFit: "contain",
    },
    eachShop: {
      width: "80%",
      margin: "15px auto",
      backgroundColor: "white",
      borderRadius: 12,
      padding: 10,
    },
    navigateCont: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    navigateImg: {
      height: 15,
      width: 15,
      marginRight: 10,
      objectFit: "contain",
    },
    navigateText: {
      background: "#fdd001",
      borderRadius: 5,
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      fontWeight: "bold",
      color: "white",
      cursor: "pointer",
    },
  });
// Customizable Area Start
const MerchantDashboradHomeWithConfirmBox = withConfirmBox(
  MerchantDashboradHome
);
const MerchantDashboradHomeWithLoader = withLoader(
  MerchantDashboradHomeWithConfirmBox
);
const MerchantDashboradHomeWithToast = withToast(
  MerchantDashboradHomeWithLoader
);
export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantDashboradHomeWithToast)
);