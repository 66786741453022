import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider";

export type Props = RouterProps & {
  id: string;
  classes: any;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  email: string;
  authToken: string;
  message: string;
  loading: boolean;
  orderId: any;
  id: string;
  lastOrder: any;
  orderUserName: any;
  merchantId : any;
}

interface SS {
  id: any;
}

export default class MerchantDashboradHomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGenerateQrCodeCallId: String = "";
  apiUpdateOrderMerchantCallId : String = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)];

    this.state = {
      email: "",
      authToken: "",
      message: "",
      loading: false,
      orderId: "",
      id: "",
      lastOrder: [],
      orderUserName: "",
      merchantId : null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, "error");
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("@@Data Coming Recived", message.id);
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGenerateQrCodeCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.setState({ orderId: responseJson.data.id });
          this.setState({ lastOrder: [responseJson.data.attributes] });
          this.setState({
            orderUserName: responseJson.data.attributes.customer_details.name,
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiUpdateOrderMerchantCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          await StorageProvider.set("scannedOrderDetail",JSON.stringify(this.state.orderId))
          this.props.history.push('/MerchantSidebarNavigation/DashBoard/OrderDetail')
        } else if (responseJson && responseJson.message) {
            this.showAlert("Response Alert!", responseJson.message);
        }
        else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
        }
      }
    }
  }

  getLastOrder() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGenerateQrCodeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.lastOrderEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateOrderMerchant() {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderMerchantCallId = requestMessage.messageId;

    let updateOrderAPIEndPoint = ("" + configJSON.getOrderDetailsAPIEndPoint)
      .split("orderId")
      .join("" + this.state.orderId);
      
      

    updateOrderAPIEndPoint =
      updateOrderAPIEndPoint + "?data[merchant_id]=" + this.state.merchantId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateOrderAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
}
