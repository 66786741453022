// @ts-nocheck
import React from "react";
import { withRouter } from "react-router";
import { Grid, Typography, Button, Dialog, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { 
  userLogo, 
  storeLogo,
  colorLogo, 
  groupLogo,
  group,
  group2,
  group3,
  group4,
  group5,
  group6,
  group8,
} from "./assets";
import OTPInputView from "../../../components/src/OTPInputView6Digit";
import StorageProvider from '../../../framework/src/StorageProvider';
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import PhoneLoginControllerWeb, {
  Props,
  configJSON
} from "./PhoneLoginController.Web";
import "./css/style.css";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { APP_FONTS } from "../../../components/src/AppFonts";


class PhoneLoginWeb extends PhoneLoginControllerWeb {
  otpReference = React.createRef(null);
  constructor(props: Props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = { 
      otp: [] ,
      currentCountryCode: "91",
      newValue: "",
      value: "Customer",
      alertMessage:"",
      alertOpen:false,
    };
  }

  handleChangePasscode = (otp:any) => {
    this.setState({otp});
  }
  scrollToTop=()=>{
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }
  getOtp(otp: string) {
    this.setState({otpError: false})
    this.setState({ otp });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("user_type").then((user_type) => {
      if (user_type) {
        this.setState({
          value : user_type
        });
      }
    });
  }

  handleAlert=(txt)=>{
    this.setState({alertMessage:txt},()=>
    {
      this.setState({alertOpen:true})
    })
  }

  closeAlert=()=>{
    this.setState({alertOpen:false})
  }
  reset = () => {
    // this.resendOTP();
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
     <Grid
      container
      direction="row"
      justify="center"
      className={`container-box`}
      ref={this.scrollRef}
     >
      <Grid container className="bg-auth">
        <Grid item xs={12} sm={6} className="left-side pb-230">
          <Typography className={`main-head mt-25 ${classes.sofiaFont}`}>
            {configJSON.labelLogin}
          </Typography>
          {/*<Typography className={`main-content mt-25 ${classes.sofiaFont}`} >*/}
          {/*  {configJSON.labelLoginContent}*/}
          {/*</Typography>*/}
          <Typography className={`label-name ${classes.sofiaFont}`} style={{marginTop:20}}>
            {configJSON.labelLoginAs}
          </Typography>
          <Button onClick={(event) => {
                this.handleChange(event, "Customer");
              }}
              className={
                value === "Customer"
                  ? `auth-btn light ${classes.sofiaFont}`
                  : `auth-btn  ${classes.sofiaFont}`
              }
              variant="contained"
             >
              {configJSON.labelIamUser}
              <img src={userLogo} />
            </Button>
            <Button onClick={(event) => {
              this.handleChange(event, "Merchant");
            }}
            className={
              value === "Merchant"
                ? `auth-btn light ${classes.sofiaFont}`
                : `auth-btn  ${classes.sofiaFont}`
            }
            variant="contained"
            > 
              {configJSON.labelIamShoOwner}
              <img src={storeLogo} />
            </Button>
          <Grid>
            <Typography className={`enter-your-phone mt-25 ${classes.sofiaFont}`}>
              {configJSON.labelEnterYourPhone}
            </Typography>
            <Grid className="phoneNumberCont">
              <Typography className={`label-name ${classes.sofiaFont}`}>
                {configJSON.labelPhoneNumber}
              </Typography>
              <div className="login_number_cont">
              <CountryCodeSelector
              isAllFlagAvailable={true}
              allowPropChange={true}
              id={"CountryCodeSelector"} //Merge Engine::From BDS
              placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
              disable={false}  //Merge Engine::From BDS
              value={this.state.currentCountryCode} //Merge Engine::From BDS
              />
              <TextField
                type="number"
                variant="outlined"
                // className="inputNumberBox"
                maxLength={10}
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
               }}
                value={this.state.phoneNumber}
                onChange={(e) => {this.onChangePhoneNumber(e)
                this.setState({phoneNumberError: false})
                }}
              />
              </div>
            </Grid>
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.phoneNumberError}</p>

            <Typography className={`label-name mt-25 ${classes.sofiaFont}`}>
              {configJSON.labelEnterPasscode}
            </Typography>
            <Grid className="otp-input-box">
           <OTPInputView isResetValue={true} isClearCalled={this.state.isClearCalled} otp={this.state.otp} key={this.state.loginError ? new Date().getTime() : "orignal"} isSecureType={true} className="pass-code-field" getOtp={(otp:string) => 
                this.getOtp(otp)} />  
            </Grid>
            <p className={`error-label  ${classes.sofiaFont}`}>
              {this.state.otpError}
              {this.state.errorMessage && this.state.errorMessage.failed_login}
            </p>

            <p className={`have-acc mt-0  ${classes.sofiaFont}`}
             onClick={() => {
               this.scrollToTop()
               this.props.history.push("ForgotPasscode")
             }}
             >
              {configJSON.labelForgotPassword}
            </p>
            
            <Grid className="btn-box">
              <Button variant="contained" className={`site-lg-btn  ${classes.sofiaFont}`}
              onClick={() => this.loginAPICall()} 
              >
                {configJSON.labelbtnNext}
              </Button>
            
              <Button variant="contained" className={`site-wht-btn ${classes.sofiaFont}`}
               onClick={() => {
                 this.scrollToTop()
                 this.props.history.push("/SignUp")
               }}
              >
                {configJSON.labelbtnCreateAccount}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className="right-side">
          <img src={colorLogo} onClick={() => this.props.history.push("/")}  className="site-logo" />
          <Grid className="right-icons mt-70">
            <div className={"icon_cont"}>
              <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
              <img src={group3}  className={"right-alert-img"} />
            </div>
            <div className={"icon_cont"}>
              <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
              <img src={group2}  className={"right-alert-img"} />
            </div>
            <div className={"icon_cont"}>
              <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
              <img src={group6}  className={"right-alert-img"} />
            </div>
          </Grid>
          <Grid className="right-icons">
            <div className={"icon_cont"}>
              <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
              <img src={group4}  className={"right-alert-img "} />
            </div>
            <div className={"icon_cont "}>
              <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
              <img src={group5}  className={"right-alert-img"} />
            </div>
            <div className={"icon_cont"}>
              <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
              <img src={group}  className={"right-alert-img"} />
            </div>
          </Grid>
          <img src={group8} className="group-logo bt-80" />
        </Grid>
      </Grid>
    </Grid>
    )}
}

const PhoneLoginWebWithConfirmBox = withConfirmBox(PhoneLoginWeb);
const PhoneLoginWebWithLoader = withLoader(PhoneLoginWebWithConfirmBox)
const PhoneLoginWebWithToast = withToast(PhoneLoginWebWithLoader)
const styles = (theme: Theme) => createStyles({
  sofiaFont:{
    fontFamily:APP_FONTS.SofiaProRegular
  }
})
export default withRouter(withStyles(styles,{ withTheme: true })(PhoneLoginWebWithToast));