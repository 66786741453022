// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import renderHTML from 'react-render-html';
import LicenseAgreementContoller, {
  Props,
  configJSON
} from "./LicenseAgreementContoller.Web";
import {createStyles, Theme,withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import {Grid} from "@material-ui/core";

class LicenseAgreementBlock extends LicenseAgreementContoller {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        console.log("loginData::: ", loginData);
        this.setState({
          authToken: loginData.token
        });
      }
    });
    let { location } = this.props;
    const path = location.pathname.toString();
    if (path.includes("MerchantSettings")) {
      await this.getPrivacyPolicyApiCall("merchant");
    }
    else {
      await this.getPrivacyPolicyApiCall("customer");
    }
  }
  render() {
    const { classes } = this.props;
    const _this = this;
    return (
      //Merge Engine DefaultContainer
      <Grid container style={{width:"100%",fontFamilty:APP_FONTS.SofiaProRegular,padding:"15px 0 0 20px"}}>
        {/*{renderHTML(this.state.webContent)}*/}
        <p className={classes.paraFont} >{this.state.webContent}</p>
      </Grid>
      //Merge Engine End DefaultContainer

    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    paraFont:{
      fontSize:16,
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign:"center",
      color:"#1e1f20"
    }
  })
export default withRouter(
  withStyles(styles, { withTheme: true })(withConfirmBox(LicenseAgreementBlock))
);
// Customizable Area End