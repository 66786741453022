// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Grid, Typography, Button, OutlinedInput } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider";
import { cameraIcon, profileIcon, deleteIcon,mapsIcons } from "./assets";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withRouter } from "react-router";
import EditUserProfileController, {
  Props,
  configJSON,
} from "./EditUserProfileController.Web";
import { APP_FONTS } from "../../../components/src/AppFonts";

class EditUserProfile extends EditUserProfileController {
  constructor(props: Props) {
    super(props);
  }

  onUsernameChanged = (event: any) => {
    this.setState({ username: event.target.value });
  };

  onAddressChanged = (event: any) => {
    this.setState({ address: event.target.value });
  };

  onEmailChanged = (event: any) => {
    this.setState({ email: event.target.value });
  };

  

  handleDeleteImage = () => {
    if (this.state.profileImagePath) {
      this.deleteProfileImageApiCall();
    } else if (this.state.imageUri) {
      this.deleteProfileImageApiCall();
      // this.setState({
      //   filePath: null,
      //   fileName: "",
      //   fileType: "",
      //   imageUri: "",
      // });
    }
  };

  // this is to get current geolocation and address from geolocation
  requestLocationPermission = async () => {
    this.getOneTimeLocation();
  };
  

  getOneTimeLocation = () => {
    this.setState({ loading: true });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLongitude = JSON.stringify(position.coords.longitude);
        const currentLatitude = JSON.stringify(position.coords.latitude);
        this.setState(
          {
            lat: currentLatitude,
            lng: currentLongitude,
          },
          () => {
            !(this.state.address) && this.getAddress(currentLatitude,currentLongitude);
          },{maximumAge:10000, timeout:5000, enableHighAccuracy: true}
        );
      },
      (error) => {
        this.setState({ loading: false });
        this.props.hideLoader();
        console.log("error.message:==>", error.message);
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  };

  getAddress = (lat: any, lng: any) => {
    this.props.showLoader();
    var request = new XMLHttpRequest();
    var method = "GET";
    var url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&sensor=true&key=AIzaSyBI5xkmKUlPoVouVxtAY2prU4ghXF5V0kc";
    var async = true;
    request.open(method, url, async);
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        var data = JSON.parse(request.responseText);
        this.props.hideLoader();
        var address = data.results[0].formatted_address || "";
        this.setState({ address: address });
      }
    };
    request.send();
  };

  handleImageChange = (event) => {
    this.setState({
      imageUri: event.target.files[0],
      fileName: event.target.files[0].name,
      fileType: event.target.files[0].type,
      file: event.target.files,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        filePath: reader.result,
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name || "",
          id: loginData.id,
          email: loginData.email,
          address: loginData.address || "",
          authToken: loginData.token,
          profileImagePath: loginData.profile_image_path,
          userType: loginData.user_type,
        });
      }
    });
    await this.requestLocationPermission();
  }
  _onProfileImageDelete = () => {
    const {openDialog} = this.props;
    const onClose = (msg)=>{
        if(msg === 'Yes'){
          this.handleDeleteImage();
        }
    }
    this.props.openDialog('Remove profile picture','Are you sure want to delete profile image',onClose);
  };
  render() {
    const { classes } = this.props;
    let image = <img src={profileIcon} style={{cursor:"pointer"}} className={classes.profileImage} />;
    if(this.state.profileImagePath){
      image = <img
      src={this.state.profileImagePath}
      className={classes.profileImage}
      />
    } else if(this.state.filePath){
      image = <img src={this.state.filePath} className={classes.profileImage} />
    }
   
    return (
      <Grid container direction="column" spacing={2} className={classes.parentCont}>
        <Grid item xs={10}>
          <p className={classes.accountInformationsTxt}>
            {configJSON.labelAccountInformation}
          </p>
          <p className={classes.edittProfileDetailTxt}>
            {configJSON.labelEditProfileDetail}
          </p>
        </Grid>

        <Grid
          xs={10}
          item
          container
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
          className={classes.textMargin}
        >
          <Grid style={{ margin: "auto 15px" }}>
            <div className={classes.inputContainer}>
              <input
                type="file"
                onChange={this.handleImageChange}
                className={classes.cameraIcon}
              />
              <img src={cameraIcon} onChange={this.handleImageChange} />
            </div>
          </Grid>
          <Grid style={{margin:"auto 15px"}}>
            {
                image
            }
          </Grid>
          <Grid style={{ margin: "auto 15px" }}>
            <div>
              <img
                src={deleteIcon}
                style={{cursor:"pointer"}}
                onClick={() => this._onProfileImageDelete()}
              />
            </div>
          </Grid>
        </Grid>
        <Grid xs={10} item>
          <p className={`${classes.sofiaFont} ${classes.textMargin}`}>
            {configJSON.labelBasicInformation}
          </p>
        </Grid>

        <Grid
          xs={10}
          item
          container
          direction="row"
          wrap={"wrap"}
          spacing={1}
          className={classes.textMargin}
        >
          <Grid item xs={6}>
            <Typography className={classes.textFieldLabel}>
              {configJSON.labelYourName}
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => this.onUsernameChanged(e)}
              value={this.state.username}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textFieldLabel}>
              {configJSON.labelEmail}
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => this.onEmailChanged(e)}
              value={this.state.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.textFieldLabel}>
              {configJSON.labelEnterAddress}
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => this.onAddressChanged(e)}
              endAdornment={
                <InputAdornment position="end">
                  <img
                    src={mapsIcons}
                    alt=""
                  />
                </InputAdornment>
              }
              value={this.state.address}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-around" item xs={10}>
          <Grid xs={10} item>
            <Button
              className={classes.saveButton}
              onClick={() => this.updateUserProfileCall()}
            >
              <Typography className={classes.saveButtonText}>
                Save Changes
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "95%",
      margin: "auto",
      overflowY:"hidden"
    },
    printIcon: {
      width: "50px",
    },
    sofiaFont:{
      fontFamily:APP_FONTS.SofiaProRegular
    },
    accountInformationsTxt: {
      marginTop: "50px",
      marginLeft: "30px",
      fontFamily: "SofiaPro",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    edittProfileDetailTxt: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      marginLeft: "30px",
      color: "#8f92a1",
    },
    textMargin: {
      marginLeft: "30px",
    },
    profileImage: {
      height: "90px",
      width: "90px",
      objectFit:"contain"
    },
    textField: {
      marginTop: "15px",
      marginLeft: "30px",
    },
    textFieldLabel: {
      fontFamily: "SofiaPro",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20;",
    },
    saveButton: {
      width: "319px",
      height: "58px",
      marginTop: "30px",
      bordeRadius: "12px",
      backgroundColor: "#32795f",
      padding: "10px",
      borderRadius: "12px",
      "&:hover": {
        backgroundColor: "#32795f",
      },
    },
    saveButtonText: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#fff",
    },
    cameraIcon: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    inputContainer: {
      position: "relative",
    },
  });
  const EditUserProfileWithConfirmBox = withConfirmBox(EditUserProfile);
  const EditUserProfileWithLoader = withLoader(EditUserProfileWithConfirmBox)
  const EditUserProfileWithToast = withToast(EditUserProfileWithLoader)
  export default withRouter(withStyles(styles, { withTheme: true })(EditUserProfileWithToast));
 