// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area Start
//import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import { Alert, ToastAndroid, Platform } from "react-native";

export const configJSON = require("./config");
import {
  withStyles,
  Theme,
  createStyles,
  StyledProps,
} from "@material-ui/core/styles";
import { RouterProps } from "react-router";

export type Props = StyledProps &
  RouterProps & {
    id: string;
    openDialog: any;
    showNotification: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  fullPhoneNumber: string;
  token: string;
  email: string;
  passcode: string;
  customerType: string;
  OTP: string;
  loading: boolean;
  timer: any;
  counter: number;
  otp: any;
  verifyOtpError: string;
  validateApiError: string;
  errorMessage: void;
  restartTimer : boolean;
  mobileNo : string,
  countryCode : string
  alertMessage:string,
  alertOpen:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VerifyOtpController extends BlockComponent<Props, S, SS> {
  labelVerifyOtp: string;
  labelNextBtn: string;
  labelOtp: string;
  labelVerifyOtpMessage: string;
  labelDidntGotOtp: string;
  labelOtpresendIn: string;
  apiVerifyOTPLCallId: String = "";
  apiSignUpLCallId: String = "";
  attrs: {};
  apiType: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage),
      // getName(MessageEnum.AccoutLoginSuccess)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      OTP: "",
      fullPhoneNumber: "",
      token: "",
      email: "",
      passcode: "",
      customerType: "",
      timer: null,
      counter: 120,
      loading: false,
      enableField: false,
      otp: "",
      verifyOtpError: "",
      validateApiError: "",
      errorMessage: undefined,
      restartTimer : false,
      mobileNo : "",
      countryCode : "",
      alertMessage:"",
      alertOpen:false,
    };
    // Customizable Area End
    this.labelVerifyOtp = configJSON.labelVerifyOtp;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelOtp = configJSON.labelOtp;
    this.labelVerifyOtpMessage = configJSON.labelVerifyOtpMessage;
    this.labelDidntGotOtp = configJSON.labelDidntGotOtp;

    this.labelOtpresendIn = configJSON.labelOtpresendIn;
    this.labelDidntGotOtp = configJSON.labelDidntGotOtp;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, "error");
    } else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.apiGetOTPLCallId === apiRequestCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.setState({ restartTimer: true });
          this.props.showNotification("OTP Send Successfully", "success");
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({errorMessage:  responseJson.errors[0]})
          this.sendAPIFailMessage();
        }
      } else if (apiRequestCallId === this.apiVerifyOTPLCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.registerUserAPICall(responseJson.meta.token);
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({errorMessage:  responseJson.errors[0]})
          this.sendAPIFailMessage();
        }
      } else if (apiRequestCallId === this.apiSignUpLCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.props.hideLoader();
          let data: any = {};
          data.token = responseJson.meta.token;
          data.phone = responseJson.data.attributes.full_phone_number;
          data.email = responseJson.data.attributes.email;
          data.name = responseJson.data.attributes.name;
          data.id = responseJson.data.id;
          data.address = responseJson.data.attributes.address;
          data.user_type = responseJson.data.attributes.user_type;

          data.business_details = responseJson.data.attributes.business_details;
          data.bank_details = responseJson.data.attributes.bank_details;

          let business_details_done = "";
          let bank_details_done = "";
          if (
            typeof responseJson.data.attributes.business_detail !=
              "undefined" &&
            responseJson.data.attributes.business_detaile
          )
            business_details_done = "yes";
          else business_details_done = "no";

          if (
            typeof responseJson.data.attributes.bank_details != "undefined" &&
            responseJson.data.attributes.bank_details
          )
            bank_details_done = "yes";
          else bank_details_done = "no";

          await StorageProvider.remove("loginData");
          await StorageProvider.set("loginData", JSON.stringify(data));

          await StorageProvider.remove("businessDetails");
          await StorageProvider.set("businessDetails", "no");

          await StorageProvider.remove("payoutDetails");
          await StorageProvider.set("payoutDetails", "no");
          if (responseJson.data.attributes.user_type == "Customer"){
            setTimeout(()=>{
                this.props.history.push("UserSettings");
            },1000)  
            this.props.showNotification("Registered Successfully", "success")
          }
          else if (responseJson.data.attributes.user_type == "Merchant"){
            setTimeout(()=>{
                this.props.history.push("Merchant/AddMerchantBusinessDetails")
            },1000) 
            this.props.showNotification("Registered Successfully", "success");   
          }
          else {
            setTimeout(()=>{
                this.props.history.push("UserSettings");
            },1000) 
            this.props.history.push("UserSettings");
            this.props.showNotification("Registered Successfully", "success")
          }
        } else {
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }
    }

    // Customizable Area End
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  async resendOTP() {
    this.setState({ loading: true });
    this.props.showLoader();
    this.attrs = {
      full_phone_number: this.state.fullPhoneNumber,
    };

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const data = {
      attributes: this.attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return;
  }
  // Customizable Area End
  //API TO VERIFY PHONE NUMBER
  validateOTPAndVerifyMobileCall() {
    let OTP: any = this.state.OTP;
    let error: any = "";
    const validateOtpError = this.validateOTPNumber(OTP);

    if (validateOtpError) {
      this.setState({ verifyOtpError: validateOtpError });
      return;
    }

    this.setState({ loading: true });
    this.props.showLoader();
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const httpBody = {
      // data: data
      full_phone_number: this.state.fullPhoneNumber,
      token: this.state.token,
      pin: this.state.OTP,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return;
  }

  //FINAL API CALL TO REGISTER USER
  async registerUserAPICall(finalToken: string) {
    this.setState({ loading: true });
    this.props.showLoader();
    this.apiType = "sms_account";
    let userType = "";
    const res = await StorageProvider.get("signUpData");
    if (res) {
      const uTData = JSON.parse(res);
      userType = uTData.user_type;
    }
    this.attrs = {
      full_phone_number: this.state.fullPhoneNumber,
      password: this.state.passcode,
      email: this.state.email,
      user_type: this.state.customerType,
    };
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const data = {
      type: this.apiType,
      attributes: this.attrs,
    };

    const httpBody = {
      data: data,
      token: finalToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSignUpLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerSignUpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return;
  }

  validateOTPNumber(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "OTP " + configJSON.errorBlankField;
    } else if (!this.isValid4DigitPOTP(otp)) {
      error = configJSON.errorOTPNotValid;
    }
    return error;
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid4DigitPOTP(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 4
    );
  }
  notifyMessage(msg: string) {
    if (Platform.OS === "android") {
      ToastAndroid.show(msg, ToastAndroid.SHORT);
    }
  }
}
