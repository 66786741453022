// @ts-nocheck
import React from "react";
import { Grid, Typography, Button,Dialog } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  colorLogo, 
  group, 
  group2, 
  group3, 
  group4, 
  group5, 
  group6, 
  group8, 
} from "./assets";
// Customizable Area End
import { APP_FONTS } from "../../../components/src/AppFonts";
import OTPInputView from "../../../components/src/OTPInputView";
import StorageProvider from "../../../framework/src/StorageProvider";
import VerifyOtpController, {
  Props,
  configJSON,
} from "./VerifyOtpController.Web";
import Scale from "../../../components/src/Scale";
import { withRouter } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";

class VerifyOtp extends VerifyOtpController {
  timer = null;
  otpReference = React.createRef(null);
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    
  }

  // Customizable Area Start

  getOtp(OTP: string) {
    this.setState({ verifyOtpError: false });
    this.setState({ OTP });
  }

  async componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.timer = setInterval(this.manageTimer, 1000);

    StorageProvider.get("signUpData").then((res) => {
      const verifyToken = JSON.parse(res);
      if (verifyToken) {
        this.setState({
          token: verifyToken.token,
          fullPhoneNumber: verifyToken.phone,
          email: verifyToken.email,
          passcode: verifyToken.passcode,
          customerType: verifyToken.user_type,
          mobileNo : verifyToken.mobile,
          countryCode : verifyToken.countryCode
        });
      }
    });
    // Customizable Area End
  }

  manageTimer = () => {
    var states = this.state;
    if (states.counter === 0) {
      clearInterval(this.timer);
    } else {
      this.setState({
        counter: this.state.counter - 1,
      });
    }
  };

  setToken(token: string) {
    this.setState({ token: token });
  }

  setFullPhoneNumber(fullPhoneNumber: string) {
    this.setState({ fullPhoneNumber: fullPhoneNumber });
  }
  reDirectToLogin = () => {
    this.props.history.push("PhoneLoginWeb")
  }
  resetAndResendOTP(){
    if(this.otpReference.current){
      this.otpReference.current.resetOTP();
    }
  }
  reset = () => {
    this.resendOTP();
  }

  handleAlert=(txt)=>{
    this.setState({alertMessage:txt},()=>
    {
      this.setState({alertOpen:true})
    })
  }

  closeAlert=()=>{
    this.setState({alertOpen:false})
  }


  render() {
    const { classes } = this.props;
    if (this.state.restartTimer) {
      this.setState({
        counter: 120,
      });
      this.timer = setInterval(this.manageTimer, 1000);
      this.setState({
        restartTimer: false,
      });
    }
    return (
      <Grid container direction="row" justify="center">
        <Grid container className={classes.background}>
          <Grid item xs={6} className={classes.leftSide}>
            <Typography className={classes.signup}>
              {configJSON.signUpLabel}
            </Typography>
            <Typography className={classes.VerifyOTP}>
              {configJSON.labelVerifyOtp}
            </Typography>
            <Grid>
              <Typography className={classes.weHaveSentAnOne}>
                {this.labelVerifyOtpMessage + "+" + this.state.countryCode + " " + this.state.mobileNo}
              </Typography>
              <Typography className={classes.didntGetTheOTP}>
                {configJSON.labelDidntGotOtp}
              </Typography>
              {this.state.counter == 0 ? null : 
                <div className={classes.didntGetTheOTP}>
                  <Typography className={classes.didntGetTheOTP} style={{textDecoration:'underline'}}>
                    Resend In 0{Math.floor(this.state.counter / 60)}:
                    {(this.state.counter % 60).toString().length > 1
                      ? this.state.counter % 60
                      : "0" + (this.state.counter % 60)}
                  </Typography>
                </div>
              }
              {this.state.counter == 0 ? (
                <div className={classes.didntGetTheOTP}>
                  <Typography
                    className={classes.didntGetTheOTP}
                    onClick={() => this.resetAndResendOTP()}
                    style={{textDecoration:'underline'}}
                  >
                    Resend OTP
                  </Typography>
                  
                </div>
              ) : null}
            </Grid>
            <Typography className={classes.OTP}>
              {configJSON.labelOtp}
            </Typography>
            <OTPInputView reset={this.reset} ref={this.otpReference} getOtp={(otp: string) => this.getOtp(otp)} />
            <p className={classes.errorLabel}>
              {this.state.verifyOtpError}
              {this.state.validateApiError}
              {this.state.errorMessage && this.state.errorMessage.pin}
              {this.state.errorMessage && this.state.errorMessage.phone}
            </p>
            <Button
              variant="contained"
              className={classes.signUpButton}
              onClick={() => this.validateOTPAndVerifyMobileCall()}
            >
              <p className={classes.signUpButtonTitle}>
                {configJSON.btnNextTitle}
              </p>
            </Button>
            <p className={classes.alreadyHaveAnAcco}>
              {configJSON.alreadyHaveAccount}
              <Typography
                    className={classes.didntGetTheOTP}
                    onClick={() => this.reDirectToLogin()}
                    style={{textDecoration:'underline'}}
                  >
                    Log In
                  </Typography>
            </p>
                  
                  
          </Grid>
          <Grid item xs={12} sm={6} className="right-side">
            <img src={colorLogo} className="site-logo" />
            <Grid className="right-icons mt-70">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
                <img src={group3}  className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
                <img src={group2}  className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
                <img src={group6}  className={"right-alert-img"} />
              </div>
            </Grid>
            <Grid className="right-icons">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
                <img src={group4}  className={"right-alert-img "} />
              </div>
              <div className={"icon_cont "}>
                <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
                <img src={group5}  className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
                <img src={group}  className={"right-alert-img"} />
              </div>
            </Grid>
            <img src={group8} className="group-logo bt-80" />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    containerBox: {
      position: "relative",
    },
    bgImage: {
      position: "absolute",
      zIndex: "-1",
      left: "0",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
    background: {
      width: "1110px",
      borderRadius: "32px",
      background: "#fff",
      margin: "100px 0",
    },
    leftSide: {
      padding: "60px 50px",
    },
    rightSide: {
      height: "730px",
      padding: "40px",
      borderRadius: "32px",
      backgroundColor: "#fdd001",
      position: "relative",
    },
    signup: {
      marginBottom: "40px",
      fontFamily: "SofiaPro",
      fontSize: "22px",
      color: "#1e1f20",
    },
    VerifyOTP: {
      fontFamily: "SofiaPro",
      fontSize: "15px",
      color: "#1e1f20",
      marginBottom: "10px",
    },
    sentInfo: {
      width: "320px",
      maxWidth: "100%",
    },
    weHaveSentAnOne: {
      margin: "7px 0 0",
      fontFamily: "SofiaPro",
      fontSize: "14px",
      color: "#8f92a1",
      display: "inline",
    },
    OTP: {
      marginTop: "30px",
      fontFamily: "SofiaPro",
      fontSize: "12px",
      color: "#1e1f20",
      marginBottom: "10px",
    },
    didntGetTheOTP: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      color: "#565656",
      display: "inline",
      paddingLeft : "0.5em",
      cursor: "pointer"
    },
    otpInputBox: {
      "& > div > div": {
        width: "48px",
        height: "48px",
        border: "1px solid rgb(143 146 161 / .4)",

        "& > input": {
          color: "#8F92A1",
          fontSize: "14px",
        },
      },
    },
    rectangle: {
      width: "106px",
      height: "32px",
      margin: "0px 18px 0px 0px",
      padding: "5px 10px !important",
      borderRadius: "4px !important",
      backgroundColor: "#8f92a1 !important",
    },
    label: {
      margin: "0 5px 0 0",
      fontFamily: "SFProDisplay",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "#ffffff",
      textTransform: "lowercase",
    },
    userImg: {
      width: "14px",
      height: "14px",
      margin: "3px 0 3px 5px",
      objectFit: "contain",
    },
    storeImg: {
      width: "14px",
      height: "14px",
      margin: "3px 0 3px 5px",
      objectFit: "contain",
    },
    colorLogoTransparent: {
      width: "153px",
      height: "47px",
      objectFit: "contain",
      float: "right",
    },
    groupLogo: {
      width: "438px",
      position: "absolute",
      bottom: "0px",
      left: "37px",
    },
    digitPasscod: {
      width: "163px",
      height: "20px",
      margin: "14px 175px 10px 0",
      fontFamily: "SofiaPro",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20",
    },
    signUpButton: {
      width: "410px",
      height: "58px",
      margin: "50px 0 20px 0px",
      padding: "20px",
      borderRadius: "12px",
      backgroundColor: "#32795f !important",
    },
    signUpButtonTitle: {
      width: "35px",
      height: "20px",
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    alreadyHaveAnAcco: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.35px",
      color: "#565656",
    },
    otpField: {
      opacity: 0.2,
      padding: "1px 16px 3px",
      borderRadius: "4px",
    },
    errorLabel: {
      color: "red",
    },
  });
const VerifyOtpWithLoader = withLoader(VerifyOtp);
const VerifyOtpWithToast = withToast(VerifyOtpWithLoader);
export default withRouter(
  withStyles(styles, { withTheme: true })(VerifyOtpWithToast)
);
