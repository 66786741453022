// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
export const configJSON = require("./config");
import { withStyles, Theme, createStyles,StyledProps } from "@material-ui/core/styles";
import { RouteProps } from "react-router";

export type Props = StyledProps & RouteProps & {
  id: string;
  openDialog : any;
  showNotification : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  email: any;
  isVisible: boolean;
  whyEmail: boolean;
  currentCountryCode: any;
  isCheckBoxChecked: boolean;
  passcode: string;
  loading: boolean;
  confirmedPasscode: string;
  otp:any;
  confirmotp: any;
  error: any;
  phoneNumberError: any;
  emailError: any;
  passcodeError: any;
  confirmPasscodeError: any;
  termConditionError: any;
  matchPasscodeError: any;
}

interface SS {
  id: any;
}

export default class SignUpController extends BlockComponent<
  Props,
  S,
  SS
  > {

  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelEmail: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  labelSingUp: string;
  phoneReg: RegExp;
  emailReg: RegExp;
  apiType: string;
  attrs: {};

  apiGetOTPLCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage)
      // getName(MessageEnum.AccoutLoginSuccess)
    ]
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      enableField: false,
      isVisible: false,
      whyEmail: false,
      email: "",
      passcode: "",
      confirmedPasscode: "",
      currentCountryCode: "91",
      isCheckBoxChecked: false,
      loading: false,
      otp: "",
      confirmotp: "",
      error:{},
      phoneNumberError: "",
      emailError: "",
      passcodeError: "",
      confirmPasscodeError: "",
      termConditionError: "",
      matchPasscodeError: ""
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelEmail = configJSON.labelEmail;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.phoneReg = new RegExp(/^[0-9]+$/);
    this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.AlertMessage) === message.id){
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage,'error');  
     } if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode: selectedCode
        });
      }
    } else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
        );
        
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            
            runEngine.debugLog("errorReponse===>", errorReponse);
            
            if (apiRequestCallId === this.apiGetOTPLCallId) {
              this.setState({ loading: false });
              if (responseJson && responseJson.meta && responseJson.meta.token) {
                //TODO: Need to uncomment this to go with the login flow
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                
                // this.saveLoggedInUserData(responseJson);
                // this.sendLoginSuccessMessage();
                // this.openInfoPage();
                // Alert.alert(responseJson.meta.message);
                
                // await StorageProvider.remove('loginToken');
                // await StorageProvider.remove('phonenumber');
                // await StorageProvider.remove('email');
                // await StorageProvider.remove('name');
                // await StorageProvider.set('loginToken', JSON.stringify(responseJson.meta.token));
          // await StorageProvider.set('phonenumber', JSON.stringify(responseJson.meta.account.data.attributes.full_phone_number));
          // await StorageProvider.set('email', JSON.stringify(responseJson.meta.account.data.attributes.email));
          // await StorageProvider.set('name', JSON.stringify(responseJson.meta.account.data.attributes.name));
          // this.notifyMessage(responseJson.meta.message);
          
          // await StorageProvider.remove('verifyToken')
          // await StorageProvider.set('verifyToken', JSON.stringify(responseJson.meta.token));
          
          // await StorageProvider.remove('signUpPhone')
          // await StorageProvider.set('signUpPhone', JSON.stringify(responseJson.data.attributes.full_phone_number));
          
          // await StorageProvider.remove('signUpEmail')
          // await StorageProvider.set('signUpEmail', JSON.stringify(this.state.email));

          let verifyToken = await StorageProvider.get('signUpData');
          if(verifyToken){
            verifyToken = JSON.parse(verifyToken);
          }
          let data: any = {...verifyToken,token : responseJson?.meta?.token};
          
          await StorageProvider.remove('signUpData')
          await StorageProvider.set('signUpData', JSON.stringify(data));
          
          // this.props.navigation.navigate('VerifyOTP', {
            //   token: responseJson.meta.token,
            //   full_phone_number: responseJson.data.attributes.full_phone_number
            // });
            this.props.history.push("VerifyOtp");
            // Alert.alert(responseJson);
          } else {
            //Check Error Response
            // Alert.alert(responseJson);
            this.parseApiErrorResponse(responseJson);
            // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
            this.sendAPIFailMessage();
          }
          
          // this.parseApiCatchErrorResponseNew(errorReponse);
          //this.setState({isError: true, errorMessage:  this.parseApiCatchErrorResponseNew(errorReponse)})
        }
      }
      
      // Customizable Area End
    }
    
    sendAPIFailMessage() {
      const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
      this.send(msg);
    }
    
    onChangePhoneNumber = (event:any) => {
      this.setState({phoneNumber: event.target.value});
    }
    
    onChangeEmail = (event:any) => {
      this.setState({email: event.target.value});
    }
    
    // Customizable Area Start
    txtInputWebProps = {
      onChange: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };
  
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };
  
  txtInputPhoneNumberlWebProps = {
    onChange: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };
  
  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };
  
  txtInputPhoneNumberProps = this.isPlatformWeb()
  ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;
    
    txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;


    txtInputEmailWebProps = {
      onChange: (text: string) => {
        if (this.txtInputEmailProps.editable) {
          this.setState({ email: text })
          //@ts-ignore
          this.txtInputEmailProps.value = text
        }
      },
      editable: true
    }
    
  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }
  
  txtInputEmailProps = this.isPlatformWeb()
  ? this.txtInputEmailWebProps
  : this.txtInputEmailMobileProps;
  
  
  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
    ? imgPasswordVisible
    : imgPasswordInVisible
  };
  
  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };
  
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
      );
      this.send(msg);
    }
    // Customizable Area End
  async validatePhoneAndGetOtpCall() {
      let mobileNo: any = this.state.phoneNumber;
      const validatePhoneError = this.validatePhoneNumber(mobileNo);
      if (validatePhoneError) {
        this.setState({phoneNumberError: validatePhoneError});
        // this.showAlert(configJSON.errorTitle, error);
        return;
      }
      
      let emailID: any = this.state.email;
      if (emailID && emailID.length > 0) {
       const validateEmailError = this.validateEmail(emailID);
        if (validateEmailError) {
          this.setState({emailError: validateEmailError});
          return;
        }
      }

    let passcode: any = this.state.passcode;
    const validatePasscodeError = this.validatePasscode(passcode);
    if (validatePasscodeError) {
      this.setState({passcodeError: validatePasscodeError});
      // this.showAlert(configJSON.errorTitle, error);
      return;
    }

    let confirmedPasscode: any = this.state.confirmedPasscode;
    const validateConfirmPasscodeError = this.validateConfirmPasscode(confirmedPasscode);
    if (validateConfirmPasscodeError) {
      this.setState({confirmPasscodeError: validateConfirmPasscodeError});
      // this.showAlert(configJSON.errorTitle, error);
      return;
    }

    const validateMatchPasscodeError = this.matchPasscodeValidation(passcode, confirmedPasscode)
    if (validateMatchPasscodeError) {
      this.setState({matchPasscodeError: validateMatchPasscodeError});
      // this.showAlert(configJSON.errorTitle, error);
      return;
    }

    let isChecked: boolean = this.state.isCheckBoxChecked;
    if (!isChecked) {
      this.setState({termConditionError: configJSON.errorTermsAndCondition});
      return;
    }

    this.setState({ loading: true });
    //here we need to call GET OTP API CALL
    let countryCode: any = this.state.currentCountryCode;
    countryCode = countryCode.indexOf("+") > 0
      ? countryCode.split("+")[1]
      : countryCode


    let signUpData: any = {}
    signUpData.phone = countryCode + mobileNo;
    signUpData.mobile = mobileNo;
    signUpData.countryCode = countryCode;
    signUpData.email = this.state.email;
    signUpData.passcode = this.state.passcode;
    signUpData.user_type = this.state.value;
    
    await StorageProvider.remove('signUpData');
    await StorageProvider.set('signUpData', JSON.stringify(signUpData));

    //this.apiType = "phone_account"
    this.attrs = {
      full_phone_number: countryCode + mobileNo,
    }

    const header = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return;
  }

  validatePhoneNumber(phoneNumber: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(phoneNumber)) {
      error = "Phone number " + configJSON.errorBlankField;
    } else if (!this.isValid10DigitPhoneNumber(phoneNumber)) {
      error = configJSON.errorMobileNoNotValid;
    }
    return error;

  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid10DigitPhoneNumber(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 10
    );
  }
  validateEmail(email: string) {
    let error = null;
    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }
    return error;
  }
  
  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  validatePasscode(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = configJSON.errorBlankPasscode;
    } else if (!this.isValid6DigitPasscode(otp)) {
      error = configJSON.errorPasscodeNotValid;
    }
    return error;

  }

  validateConfirmPasscode(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = configJSON.errorBlankConfirmPasscode;
    } else if (!this.isValid6DigitPasscode(otp)) {
      error = configJSON.errorPasscodeNotValid;
    }
    return error;

  }

  isValid6DigitPasscode(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 6
    );
  }

  matchPasscodeValidation(passcode: string, confirmPasscode: string) {
    let error = null;
    if (passcode != confirmPasscode) {
      error = configJSON.errorPasscodeNotMatched;
    }
    return error;

  }
}
