//@ts-nocheck
import React, { useState } from "react";
// Customizable Area Start
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Grid,
  Typography,
  Button,
  Modal,
  TextField,
  Checkbox,
  InputBase,
  Select,
  MenuItem,
} from "@material-ui/core";
import "./css/src.css"
// Customizable Area End
import MerchantOrderHistoryController, {
  Props,
  configJSON,
} from "./MerchantOrderHistoryController.Web";
import moment from "moment";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import {
  attachIcon,
  backIcon,
  detailsIcon,
  mapsIcon,
  markerIcon,
  navBarImg,
  colorIcon,
  checkIcon,
  historyIcon,
} from "./assets";
import { withRouter } from "react-router";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
const orderStatusValues = [
  { label: "All", value: "all", hidden: true },
  // { label: "Pending", value: "Pending" },
  { label: "Payment Completed", value: "Paymentcompleted" },
  // { label: "Printing", value: "Printing" },
  { label: "Printing Completed", value: "Printingcompleted" },
  { label: "Completed", value: "Completed" },
  // { label: "Refunded", value: "Refunded" },
  { label: "Auto Cancelled", value: "Autocancel" },
];

class MerchantOrdersHistory extends MerchantOrderHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  filterOrdersTapped = () => {
    this.setState({
      showFilter: !this.state.showFilter,
      showDatePicker: false,
    });
  };

  closeModal = () => {
    this.setState({
      showFilter: !this.state.showFilter,
      showDatePicker: false,
      isVisibleDropDown: false,
    });
  };

  saveTapped = () => {};
  // Customizable Area Start
  _tagTapped = (val: string) => {
    this.setState({
      selectedTag: val,
    });
  };

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        id: loginData.id,
        authToken: loginData.token,
      });
    }
    await this.getCurrentOrders(false);
    // Customizable Area End
  }

  _refreshData = async () => {
    await this.getCurrentOrders(false);
  };
  _filterAppliedClicked = () => {
    if (
      (this.state.selectedOrderStatus &&
        this.state.selectedOrderStatus !== "none") ||
      (this.state.startDate.length > 0 && this.state.endDate.length > 0)
    ) {
      this.setState({ allShopsModal: false }, () => {
        this.getCurrentOrders(true);
      });
    } else {
      this.props.showNotification("Select any filter to apply", "warn");
    }
  };

  _onRangeTagTapped = (val: string) => {
    this.setState(
      {
        selectedRangeTag: val,
      },
      () => {
        if (val === "today") {
          let startDate = moment().format("yyyy-MM-DD");
          let endDate = startDate;
          this.setState({
            startDate: startDate,
            endDate: endDate,
          });
        } else if (val === "this_week") {
          let startDate = moment()
            .startOf("week")
            .format("yyyy-MM-DD");
          let endDate = moment()
            .endOf("week")
            .format("yyyy-MM-DD");
          this.setState({
            startDate: startDate,
            endDate: endDate,
          });
        } else if (val === "this_month") {
          let startDate = moment()
            .startOf("month")
            .format("yyyy-MM-DD");
          let endDate = moment()
            .endOf("month")
            .format("yyyy-MM-DD");
          this.setState({
            startDate: startDate,
            endDate: endDate,
          });
        }
      }
    );
  };
  _handleAllShopsModal = () => {
    this.setState({ allShopsModal: !this.state.allShopsModal });
  };
  // Customizable Area End
  handleChangeStatus = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  navigateToOrderDetail = async (order) => {
    await StorageProvider.set("scannedOrderDetail", JSON.stringify(order.id));
    this.props.history.push("/MerchantSidebarNavigation/DashBoard/OrderDetail");
  };
  getExpiryDateOfDoc = (createdAt: any) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    let countDownTime = new Date(createdAt);
    countDownTime = countDownTime.setHours(countDownTime.getHours() + 4);
    var now = new Date().getTime();
    var distance = countDownTime - now;

    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    if (hours > 0) {
      hours = hours.toString().length > 1 ? hours : "0" + hours;
      minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
      const time = 'Expiry in ' + hours + ":" + minutes + " hours";
      return time;
    } else if (hours === 0) {
      hours = hours.toString().length > 1 ? hours : "0" + hours;
      minutes = minutes.toString().length > 1 ? minutes : "0" + minutes;
      const time = 'Expiry in ' + hours + ":" + minutes + " minutes";
      return time;
    }
  };
  render() {
    const { classes, status } = this.props;
    const pendigOrder =
      this.state.dataSource.length > 0
        ? this.state.dataSource.filter((order) => {
            return order.attributes.status === "Pending";
          })
        : [];
    const completedOrder =
      this.state.dataSource.length > 0
        ? this.state.dataSource.filter((order) => {
            return order.attributes.status !== "Pending";
          })
        : [];
    return (
      <>
        <Grid container className={classes.rightContent}>
          <Grid item xs={12} className={classes.headingCont}>
            <div className={classes.headingLeft}>
              <Typography className={classes.selectFilesText}>
                {status && status === "Pending"
                  ? "Current order"
                  : configJSON.allOrdersText}
              </Typography>
              {!status && status !== "Pending" && (
                <img
                  src={detailsIcon}
                  alt=""
                  className={classes.backIcon}
                  onClick={this._handleAllShopsModal}
                />
              )}
            </div>
          </Grid>
          <Grid container className={classes.readyOrdersCont}>
            {status && status === "Pending" && pendigOrder.map((order) => {
              return (
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.eachReadyOrder}
                  onClick={() => {
                    this.navigateToOrderDetail(order);
                  }}
                >
                  <div className={classes.eachOrderDiv}>
                    <div
                      className={classes.leftBorder}
                      style={{
                        backgroundColor:
                          order.attributes.status == "Completed"
                            ? APP_COLORS.completed_order
                            : order.attributes.status == "Pending"
                            ? APP_COLORS.app_theme_yellow_color
                            : APP_COLORS.cancelled_order,
                      }}
                    />
                    <div className={classes.rightReadyCont}>
                      <Typography className={classes.pOrderNumber}>
                      Order #{order.attributes.order_number}
                      </Typography>
                      <Typography className={classes.pOrderTime}>
                        {moment(order.attributes.created_at).format(
                          "DD/MM/yyyy hh:mm A"
                        )}
                      </Typography>
                      <Typography className={classes.pOrderExpiry}>
                         {this.getExpiryDateOfDoc(order?.attributes?.created_at)}
                      </Typography>
                      <div className={classes.readyEndCont}>
                        <span className={classes.spanImgReady1}>
                          <img
                            className={classes.readyImg}
                            src={attachIcon}
                            alt=""
                          />
                          {order.attributes.total_docs} Docs
                        </span>
                        <span className={classes.spanImgReady2}>
                          <img
                            className={classes.readyImg}
                            src={
                              order.attributes.status == "Completed"
                                ? checkIcon
                                : order.attributes.status == "Printingcompleted"
                                ? checkIcon
                                : colorIcon
                            }
                            alt=""
                          />
                          {order.attributes.status}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
            {!(this.state.dataSource.length > 0) &&
              !status && status !== "Pending" && (
                <Grid
                  container
                  alignItems="center"
                  justify="space-around"
                  className={classes.completeOrdersCont}
                >
                  <Grid item xs={4} className={classes.pOrderNumber}>
                    {this.state.loading
                      ? "Orders are loading"
                      : "No Order for this Merchant"}
                  </Grid>
                </Grid>
              )}
            {!(pendigOrder.length > 0) && status && status === "Pending" && (
              <Grid
                container
                alignItems="center"
                justify="space-around"
                className={classes.completeOrdersCont}
              >
                <Grid item xs={4} className={classes.pOrderNumber}>
                  {this.state.loading
                    ? "Orders are loading"
                    : "No Current Order for this Merchant"}
                </Grid>
              </Grid>
            )}
          </Grid>
          {!status && status !== "Pending" && (
            <Grid container className={classes.completeOrdersCont}>
              {this.state.dataSource.map((order) => {
                return (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    className={classes.eachReadyOrder}
                    onClick={() => {
                      this.navigateToOrderDetail(order);
                    }}
                  >
                    <div className={classes.eachOrderDiv}>
                      <div
                        className={classes.leftBorder}
                        style={{
                          backgroundColor:
                            order.attributes.status == "Completed"
                              ? APP_COLORS.completed_order
                              : order.attributes.status == "Pending" || order.attributes.status == "Paymentcompleted"
                              ? APP_COLORS.app_theme_yellow_color
                              : APP_COLORS.cancelled_order,
                        }}
                      />
                      <div className={classes.rightReadyCont}>
                        <Typography className={classes.pOrderNumber}>
                         Order #{order.attributes.order_number}
                        </Typography>
                        <Typography className={classes.pOrderTime}>
                        {moment(order.attributes.created_at).format(
                          "DD/MM/yyyy hh:mm A"
                        )}
                        </Typography>
                        <Typography className={classes.pOrderExpiry}>
                             {order?.attributes?.status === 'Pending' && this.getExpiryDateOfDoc(order?.attributes?.created_at)}
                         </Typography>
                         <Typography className={classes.pOrderExpiry}>
                          With {order?.attributes?.customer_details?.name}
                         </Typography>
                        <div className={classes.readyEndCont}>
                          {order.attributes.status == "Completed" ?
                          <span className={classes.spanImgReady1}>
                          </span>
                          : 
                          <span className={classes.spanImgReady1}>
                          <img
                            className={classes.readyImg}
                            src={attachIcon}
                            alt=""
                          />
                          {order.attributes.total_docs} Docs
                           </span>
                          }
                          <span className={classes.spanImgReady2}>
                            <img
                              className={classes.readyImg}
                              src={
                                order.attributes.status == "Completed"
                                  ? checkIcon
                                  : order.attributes.status ==
                                    "Printingcompleted"
                                  ? checkIcon
                                  : colorIcon
                              }
                              alt=""
                            />
                            {order.attributes.status == "Printingcompleted"
                              ? "Printing Completed"
                              : order.attributes.status == "Paymentcompleted"
                              ? "Payment Completed"
                              : (order.attributes.status == 'Pending')? 
                              "Ready For Print" : order.attributes.status
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>

        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.allShopsModal}
          // open={true}
          onClose={this._handleAllShopsModal}
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                {configJSON.filterHeading}
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
              <Typography className={classes.modalContentText}>
                {configJSON.byStatus}
              </Typography>
              <Select
                name="selectedOrderStatus"
                value={this.state.selectedOrderStatus}
                onChange={this.handleChangeStatus}
                input={<InputBase className={classes.selectInputModal} />}
              >
                <MenuItem disabled value="none">
                  <em>Select An Item</em>
                </MenuItem>
                {orderStatusValues.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <Typography className={classes.modalContentText}>
                {configJSON.byTime}
              </Typography>
              <div className={classes.inputDateCont}>
                <Typography className={classes.modalFromText}>
                  {configJSON.fromText}
                </Typography>
                <InputBase
                  id="date"
                  type="date"
                  className={classes.fromDate}
                  value={this.state.startDate}
                  onChange={(e) => this.setState({ startDate: e.target.value })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.inputDateCont}>
                <Typography className={classes.modalFromText}>
                  {configJSON.toText}
                </Typography>
                <InputBase
                  id="date"
                  type="date"
                  className={classes.fromDate}
                  value={this.state.endDate}
                  onChange={(e) => this.setState({ endDate: e.target.value })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.timeSelectCont}>
                <Button
                  className={classes.timeSelectBtn}
                  style={{ backgroundColor: "#888888",opacity : this.state.selectedRangeTag === 'today' ? 1 : 0.5 }}
                  onClick={() => this._onRangeTagTapped("today")}
                >
                  <img src={historyIcon} style={{ marginRight: 7 }} alt="" />
                  {configJSON.today}
                </Button>
                <Button
                  className={classes.timeSelectBtn}
                  style={{ backgroundColor: "#888888",opacity : this.state.selectedRangeTag === 'this_week' ? 1 : 0.5 }}
                  onClick={() => this._onRangeTagTapped("this_week")}
                >
                  <img src={historyIcon} style={{ marginRight: 7 }} alt="" />
                  {configJSON.thisWeek}
                </Button>
                <Button
                  className={classes.timeSelectBtn}
                  style={{ backgroundColor: "#888888",opacity : this.state.selectedRangeTag === 'this_month' ? 1 : 0.5 }}
                  onClick={() => this._onRangeTagTapped("this_month")}
                >
                  <img src={historyIcon} style={{ marginRight: 7 }} alt="" />
                  {configJSON.thisMonth}
                </Button>
              </div>
              <Button
                className={classes.closeModalBtn}
                style={{ backgroundColor: "#32795f", marginTop: 20 }}
                onClick={() => this._filterAppliedClicked()}
              >
                {configJSON.applyBtnText}
              </Button>
            </div>
          </div>
        </Modal>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
    },
    leftSideCont: {
      backgroundColor: APP_COLORS.app_theme_yellow_color,
      // height:'100%',
    },
    rightSideCont: {
      // height:'70%',
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      // background:`#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    headingCont: {
      width: "90%",
      margin: "auto",
      padding: "20px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    readyOrdersCont: {
      margin: "10px auto",
    },
    completeOrdersCont: {
      margin: "30px auto",
    },
    eachReadyOrder: {
      marginTop: 20,
      cursor : 'pointer'
    },
    eachOrderDiv: {
      width: "90%",
      margin: "auto",
      display: "flex",
      justifyContent: "flex-start",
      backgroundColor: "#ffffff",
      borderRadius: 12,
      padding: "10px 5px",
    },
    leftBorder: {
      width: 5,
      marginLeft: 5,
    },
    rightReadyCont: {
      width: "calc(100% - 5px)",
      marginLeft: 10,
      paddingLeft: 7,
    },
    readyEndCont: {
      display: "flex",
      alignItems: "center",
      // padding:"0 10px",
      marginTop: 20,
      marginBottom: 10,
      justifyContent: "space-between",
    },
    mapsCont: {
      width: "100%",
      margin: "auto",
      position: "relative",
      minHeight: "80vh",
      display: "flex",
      alignItems: "flex-end",
    },
    mapContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    selectFilesText: {
      fontSize: 22,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      // fontWeight: "bold",
      color: "#1e1f20",
    },
    pOrderNumber: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      marginTop: 10,
    },
    pOrderTime: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      marginTop: 15,
      color: "#8f92a1",
    },
    pOrderExpiry: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      marginTop: 7,
      color: "#1e1f20",
    },
    spanImgReady1: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      display: "flex",
      alignItems: "center",
      color: "#8f92a1",
    },
    spanImgReady2: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      display: "flex",
      padding: 5,
      marginRight: 5,
      alignItems: "center",
      color: "#1e1f20",
      backgroundColor: "#f7f7f7",
      borderRadius: 4,
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 30px 30px",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    backIcon: {
      width: 40,
      height: 40,
      marginLeft: 15,
      objectFit: "contain",
      cursor: "pointer"
    },
    readyImg: {
      width: 20,
      height: 20,
      marginRight: 8,
      objectFit: "contain",
    },

    //Modal Styling
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      width: "25%",
      minWidth: 270,
      height: "100vh",
      padding: "20px 0",
      overflowY: "scroll",
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    modalContentCont: {
      display: "flex",
      // marginLeft:30,
      flexDirection: "column",
      alignItems: "flex-start",
      // justifyContent:'center'
    },
    modalHeadingCont: {
      display: "flex",
      margin: "20px 0",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    printerName: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      marginTop: 10,
      marginBottom: 10,
    },
    printerAddress: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginBottom: 10,
    },
    modalContentText: {
      fontSize: 13,
      letterSpacing: 1,
      margin: "20px 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      textTransform: "uppercase",
      color: "#1e1f20",
    },
    modalFromText: {
      fontSize: 15,
      width: "20%",
      margin: "0 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: " auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    timeSelectBtn: {
      color: "white",
      textTransform: "none",
      borderRadius: 4,
      marginRight: "10px",
      marginTop: 10,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    spanModalImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    colorModalDiv: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    pageInputModal: {
      width: 48,
      height: 48,
      paddingLeft: 15,
      textAlign: "center",
      fontSize: "1.2rem",
      color: "#1e1f20",
      borderRadius: 4,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
    },
    selectInputModal: {
      width: "90%",
      // height:48,
      padding: "5px 10px",
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    inputDateCont: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      justifyContent: "space-between",
    },
    timeSelectCont: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      marginTop: "15px",
      marginLeft: "20px",
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    fromDate: {
      padding: "5px 10px",
      marginLeft: 20,
      width: "80%",
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    paperCopy: {
      height: 45,
      width: 60,
      marginBottom: 7,
      objectFit: "contain",
    },
    colorCopy: {
      height: 50,
      marginBottom: 7,
      width: 50,
      objectFit: "contain",
    },
    eachShop: {
      width: "80%",
      margin: "15px auto",
      backgroundColor: "white",
      borderRadius: 12,
      padding: 10,
    },
    navigateCont: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    navigateImg: {
      height: 15,
      width: 15,
      marginRight: 10,
      objectFit: "contain",
    },
    navigateText: {
      background: "#fdd001",
      borderRadius: 5,
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      fontWeight: "bold",
      color: "white",
      cursor: "pointer",
    },
  });
// Customizable Area Start

// Customizable Area End
const MerchantOrdersHistoryWithLoader = withLoader(MerchantOrdersHistory);
const MerchantOrdersHistoryWithToast = withToast(
  MerchantOrdersHistoryWithLoader
);
export default withRouter(
  withStyles(styles, { withTheme: true })(MerchantOrdersHistoryWithToast)
);