//@ts-nocheck
import React from "react";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { Scrollbars } from 'react-custom-scrollbars';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as Map,
  Marker,
  DirectionsRenderer
} from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import NearByShopsController, {
  Props,
  configJSON,
} from "./NearByShopsController.Web";
import './css/NearByShops.css';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Modal, Checkbox,MenuItem } from "@material-ui/core";
import { withRouter } from "react-router";
import { APP_FONTS } from "../../../components/src/AppFonts";
import {
  backIcon,
  navBarImg,
  detailsIcon,
  mapsIcon,
  markerIcon,
  userIcon
} from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";


const radiusValues = [
  { label: "5 Km", value: "5" },
  { label: "10 Km", value: "10" },
  { label: "15 Km", value: "15" },
  { label: "20 Km", value: "20" },
];
const containerStyle = {
  width: "100%",
  height: "100%",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const defaultMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl : false
};
const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <Map
    defaultZoom={props.zoom}
    defaultCenter={props.center}
    defaultOptions={defaultMapOptions}
  >
    {props.dataSource.length > 0 ? 
      props.dataSource.map((store) => {
          return (
            <MarkerWithLabel
              key={store.id}
              position={{
                lat: parseFloat(store?.attributes?.lat),
                lng: parseFloat(store?.attributes?.lng),
              }}
              labelAnchor={new google.maps.Point(50,5)}
              labelStyle={{backgroundColor: "white", fontSize: "12px", padding: "6px"}}
              icon={markerIcon}
            >
              <>
              <div>{store?.attributes?.shop_name}</div>
              <div>{store?.attributes?.shop_address}</div>
              </>
            </MarkerWithLabel>
          );
        })
  : null }   
  {props.directions? <DirectionsRenderer
          directions={props.directions} /> : null
  } 
  {(props.lat && props.lng) && <Marker
              position={{
                lat: props.lat,
                lng: props.lng,
              }}
              icon={userIcon}
            />
          }
  </Map>
));

class NearByShopsToPay extends NearByShopsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  _closeModal = () => {
    this.setState({
      showMapFilter: false,
      isVisibleDropDown: false,
    });
  };
  calculateDistance(lat1, lon1, lat2, lon2) 
    {
      var R = 6371; // km
      var dLat = this.toRad(lat2-lat1);
      var dLon = this.toRad(lon2-lon1);
      var lat1 = this.toRad(lat1);
      var lat2 = this.toRad(lat2);
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }

    // Converts numeric degrees to radians
    toRad(Value) 
    {
        return Value * Math.PI / 180;
    }
  

  _contineToPayTapped = () => {
    const {openDialog} = this.props;
    if (this.props.location?.state?.calledFrom==="orderHistory") {
      this.props.history.push('/UserSidebarNavigation/UploadDashBoard/Payment')
    }
    else {
      const onClose = (msg)=>{
        if(msg === 'Yes'){
          this.placeOrderApiCall();
        }
      }
      this.props.openDialog('Continue to pay','Are you sure, you want to place the order?',onClose);
    }
  };

  _filterTapped = () => {
    this.setState({
      showMapFilter: !this.state.showMapFilter,
    });
  };
  
  // Current Location //
  async componentDidMount() {
    // Customizable Area Start
    function getPreciseLocation() {
      return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            resolve([position.coords.latitude, position.coords.longitude]);
          },
          (error) => {
            reject("Geolocation is not supported by your browser");
          },{maximumAge:10000, timeout:5000, enableHighAccuracy: true}
        );
      });
    }
    
    let userData = await StorageProvider.get("loginData")
    let selectedFiles = await StorageProvider.get("selectedFiles")
    let showOrderButton = await StorageProvider.get("showPay")
    let location = await getPreciseLocation()
    const loginData = userData && JSON.parse(userData);
    const selectedAttachment = selectedFiles && JSON.parse(selectedFiles);
          showOrderButton = showOrderButton && JSON.parse(showOrderButton);
    if (selectedAttachment) {
    const attachmentIds = selectedAttachment.map(
        (attachment) => attachment.id
    );
    this.setState({
        attachmentIds: attachmentIds,
        id: loginData.id,
        authToken: loginData.token,
        lat : location[0],
        lng : location[1],
        showPayButton : showOrderButton.show_pay_button
    },     () => {
               this.getNearByServiceProvidrs();
             });
    }
  }
  handleInputChange = (event) => {
    this.setState({[event.target.name] : event.target.value})
  }
  applyRadiusFilter = () => {
      this.setState({radiusKm : this.state.radius, showMapFilter: false},()=>{
          this.getNearByServiceProvidrs();
      })  
  }
  _handleBackClicked=()=>{
    if (this.props.location?.state?.calledFrom==="orderHistory") {
      this.props.history.push('/OrderManagement/OrderHistory/History')
    }
    else {
      const options={
        pathname: '/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen',
        state: { calledFrom: "NearByShopsToPay" }
      }
      this.props.history.push(options)
    }
  }

  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <div className={classes.rightContent}>
          <div className={classes.headingCont}>
            <Grid item xs={12}>
              <div className={classes.headingLeft}>
                <img src={backIcon} onClick={()=>{this.state.showPayButton ? 
                      this._handleBackClicked() :
                      this.props.history.goBack()}
                    }
                 alt="" className={classes.backIcon} />
                <Typography className={classes.selectFilesText}>
                  {configJSON.findShopsHeading}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <img
                src={detailsIcon}
                alt=""
                className={classes.filterIcon}
                onClick={this._filterTapped}
              />
            </Grid>
          </div>
          <div className={"map_parent_cont"}>
            {this.state.lat && this.state.lng ? (
              <div className={"map_left_cont"}>
                <div className={"mapContainer"}>
                  {this.state.lat && this.state.lng &&  (<MapWithAMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBYo5s0uQPFgc8qafyO0Rzejpe78bi4ezw&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    center={{
                      lat: this.state.lat,
                      lng: this.state.lng,
                    }}
                    zoom={13}
                    dataSource={this.state.dataSource}
                    directions={this.state.directions}
                    lat={this.state.lat}
                    lng ={this.state.lng}
                  >
                  </MapWithAMarker>)
                  }
                </div>
                {
                  this.state.showPayButton && <Button
                    className={classes.findPrintersBtn}
                    onClick={()=>{this._contineToPayTapped()}}
                    style={{ backgroundColor: "#32795f", marginTop: 20 }}
                  >
                    {configJSON.continueBtn}
                  </Button>
                }
              </div>
            ) : (
              <div className={"map_left_cont"}>Geolocation is not supported by your browser</div>
            )}
            <div  className={"map_right_cont"}>
              <Scrollbars>
                <div className={classes.modalHeadingCont}>
                  <Typography className={classes.modalHeading}>
                    {configJSON.modalHeading}
                  </Typography>
                </div>
                <div className={classes.modalContentCont}>
                  {this.state.dataSource.length > 0
                    ? this.state.dataSource.map((marker) => (
                      <div key={marker.id} className={classes.eachShop}>
                        <Typography className={classes.printerName}>
                          {marker?.attributes?.shop_name}
                        </Typography>
                        <Typography className={classes.printerAddress}>
                          {marker?.attributes?.shop_address}
                        </Typography>
                        <div className={classes.navigateCont} onClick={()=>{this.navigateToMerchantLocation({lat: this.state.lat,lng : this.state.lng},marker.attributes)}}>
                      <span className={classes.navigateText} >
                        <img
                          src={mapsIcon}
                          alt=""
                          className={classes.navigateImg}
                        />
                        {configJSON.Navigate}
                      </span>
                          <span className={classes.printerAddress}>
                            {this.calculateDistance(this.state.lat,this.state.lng,marker?.attributes?.lat,marker?.attributes?.lng).toFixed(1)} Km
                      </span>
                        </div>
                      </div>
                    ))
                    : <Typography className={classes.modalHeading}>
                      {'No Merchant Available Near you'}
                      </Typography>}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>

        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.showMapFilter}
          onClose={this._filterTapped}
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                {configJSON.modal2Heading}
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                {configJSON.printProperties}
              </Typography>
            </div>
                <Typography className={classes.modalContentText}>
                {configJSON.printSlides}
              </Typography>
              <Select value={this.state?.radius} name="radius" onChange={(event)=>{this.handleInputChange(event)}}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {radiusValues.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <Button
                className={classes.closeModalBtn}
                style={{ backgroundColor: "#32795f", marginTop: 20 }}
                onClick={() => {this.applyRadiusFilter()}}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
    },
    leftSideCont: {
      backgroundColor: APP_COLORS.app_theme_yellow_color,
      // height:'100%',
    },
    rightSideCont: {
      // height:'70%',
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      minHeight: "80%",
      width: "100%",
      background: `#ffffff`,
      borderRadius: 12,
      marginBottom: 40,
    },
    headingCont: {
      width: "90%",
      margin: "auto",
      padding: "20px 30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    mapsCont: {
      width: "100%",
      margin: "10px 30px",
      padding: "0px 10px",
      position: "relative",
      minHeight: "80vh",
      display: "flex",
      alignItems: "flex-end",
    },
    mapContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    headingLeft: {
      width: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
    },
    selectFilesText: {
      fontSize: 22,
      marginLeft: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      position:"absolute",
      bottom:"7%",
      left:"5%",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    backIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor : "pointer"
    },
    filterIcon: {
      width: 40,
      height: 40,
      objectFit: "contain",
      cursor : "pointer",
      float: "right"
    },

    //Modal Styling
    modalParent: {
      // height:'100%',
      width: "100%",
      position: "relative",
    },
    LocationListContainer: {
      // position: "absolute",
      // right: 0,
      borderRadius: "32px 0 0 32px",
      // width: "25%",
      // minWidth: 270,
      // height: "100vh",
      padding: "20px 0",
      // overflowY: "scroll",
      backgroundColor: "#32795f",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      width: "27%",
      minWidth: 270,
      height: "100vh",
      padding: "20px 0",
      overflowY: "scroll",
      backgroundColor: "#fdd001",
    },
    modalContentCont: {
      display: "flex",
      // marginLeft:30,
      flexDirection: "column",
      alignItems: "flex-start",
      // justifyContent:'center'
    },
    modalHeadingCont: {
      display: "flex",
      margin: "20px 0",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    printerName: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      marginTop: 10,
      marginBottom: 10,
    },
    printerAddress: {
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginBottom: 10,
    },
    modalContentText: {
      fontSize: 13,
      letterSpacing: 1,
      margin: "20px 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      textTransform: "uppercase",
      color: "#1e1f20",
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: " auto",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    spanModalImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    colorModalDiv: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    pageInputModal: {
      width: 48,
      height: 48,
      paddingLeft: 15,
      textAlign: "center",
      fontSize: "1.2rem",
      color: "#1e1f20",
      borderRadius: 4,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
    },
    selectInputModal: {
      width: "90%",
      // height:48,
      padding: "5px 10px",
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    paperCopy: {
      height: 45,
      width: 60,
      marginBottom: 7,
      objectFit: "contain",
    },
    colorCopy: {
      height: 50,
      marginBottom: 7,
      width: 50,
      objectFit: "contain",
    },
    eachShop: {
      width: "80%",
      margin: "15px auto",
      backgroundColor: "white",
      borderRadius: 12,
      padding: 10,
    },
    navigateCont: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    navigateImg: {
      height: 15,
      width: 15,
      marginRight: 10,
      objectFit: "contain",
    },
    navigateText: {
      background: "#fdd001",
      borderRadius: 5,
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      fontSize: 15,
      fontFamily: APP_FONTS.SofiaProRegular,
      fontWeight: "bold",
      color: "white",
      cursor: "pointer",
    },
  });
// Customizable Area End
const NearByShopsToPayWithConfirmBox = withConfirmBox(NearByShopsToPay);
  const NearByShopsToPayWithLoader = withLoader(NearByShopsToPayWithConfirmBox)
  const NearByShopsToPayWithToast = withToast(NearByShopsToPayWithLoader)
  export default withRouter(withStyles(styles, { withTheme: true })(NearByShopsToPayWithToast));
