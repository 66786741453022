//@ts-nocheck
import React from 'react'
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import { APP_FONTS } from '../../../components/src/AppFonts';

import ShopDashboardController, {
  Props,
  configJSON
} from "./ShopDashboardController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography} from '@material-ui/core';
import {shopQrImg, attachIcon, checkIcon, navBarImg, arrowRight} from './assets'
import { withRouter } from "react-router";
import { Switch, Route, Link, NavLink } from "react-router-dom";
import { Redirect} from "react-router";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";

import MerchantDashboradHome   from "./MerchantDashboradHome.Web";
import UserSupport from "../../user-profile-basic/src/UserSupport.Web";
import StorageProvider from "../../../framework/src/StorageProvider";
import ScannedOrderDetails from "../../OrderManagement/src/ScannedOrderDetails.Web";

class ShopDashboard extends ShopDashboardController {

  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    await StorageProvider.get("loginData").then((res) => {
      if (res) {
        let loginData = JSON.parse(res);
        this.setState({
          username: loginData.shop_name,
          profileImagePath: loginData.profile_image_path,
        });
      }
     });
  }

  render() {
    const { classes } = this.props;
       return (
        <Grid container item xs={12} className={classes.rightSideCont}>
          <Grid container className={classes.totalCont}>
            <Grid item xs={12} className={classes.navBar}>
              <Typography className={classes.userName}>
                {this.state.username}
              </Typography>
              <span className={classes.navSpan}>
                {this.state.profileImagePath ? (
                  <img
                    src={this.state.profileImagePath}
                    className={classes.navSpanImg}
                  />
                ) : (
                  <img
                    src={require("../assets/profile_icon.png")}
                    className={classes.navSpanImg}
                  />
                )}
              </span>
            </Grid>
            <Switch>
            <PrivateRoute exact role="Merchant" path={`/MerchantSidebarNavigation/DashBoard/Home`}>
                <MerchantDashboradHome />
            </PrivateRoute>
            <PrivateRoute exact role="Merchant" path={`/MerchantSidebarNavigation/DashBoard/OrderDetail`}>
              <ScannedOrderDetails />
            </PrivateRoute>
            <PrivateRoute  exact role="Merchant" path={`/MerchantSupport/Support/Support`}>
              <UserSupport />
            </PrivateRoute>
            <Redirect exact from={'/MerchantSupport/Support'} to={'/MerchantSupport/Support/Support'}></Redirect>
            <Redirect exact from={'/MerchantSidebarNavigation/DashBoard'} to={'/MerchantSidebarNavigation/DashBoard/Home'}></Redirect>
            </Switch>
          </Grid>
        </Grid>)
     }
}
const styles = (theme: Theme) =>
  createStyles({
    rightSideCont: {
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 0px",
    },
    navSpan: {
      height: '45px',
      width: '45px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: '14px',
      marginLeft: '15px',
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
  });

export default withRouter(withStyles(styles, { withTheme: true })(ShopDashboard));


