//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { RouterProps  } from "react-router";

export type Props = RouterProps & {
    id: string;
    showNotification : any;
    showLoader : any;
    hideLoader : any;
    // Customizable Area Start
    // Customizable Area End
  }

interface S {
  // Customizable Area Start
  submenu:boolean,
  mobilemenu:boolean,
  header:any,
  footer:any,
  email: string,
  message: string,
  emailError: string;
  messageError: string;
  loading: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetHeaderCallId: String = "";
  emailReg: RegExp;
  apiSupportsCallId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.AlertMessage)
    ]


    this.state = {
      submenu:false,
      mobilemenu:false,
      header:[],
      footer:[],
      loading: false,
      email: "",
      message: "",
      emailError: "",
      messageError: ""
    };
    // Customizable Area End
    this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetHeaderCallId) {
        // console.log("response2",responseJson)
        if (responseJson && responseJson.data && responseJson.data.id) {
          //TODO: Need to uncomment this to go with the login flow
          console.log("response",responseJson)
          this.setState({header:JSON.parse(responseJson.data.attributes.header_content),footer:JSON.parse(responseJson.data.attributes.footer_content)},()=>
          {
            // console.log("footer",this.state.footer)
            // console.log("header",this.state.header)
          })
          
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }

      if (apiRequestCallId === this.apiSupportsCallId) {
        this.setState({ loading: false })
        this.props.hideLoader();
      if (responseJson && responseJson.data && responseJson.data.id) {
          this.props.showNotification(responseJson.meta.message,"success")
          this.setState({
              email : '',
              message : ''
          })
        }
      } else {
        this.parseApiErrorResponse(responseJson);
        this.sendAPIFailMessage();
      }
    }
  }


  getHeaderFooterContent(){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };
    this.apiGetHeaderCallId = requestMessage.messageId;
    console.log("messageId",requestMessage.messageId)

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHeaderFooterEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  supportsAPICall() {

    let email: any = this.state.email;
    let error: any = "";
    const validateEmailError = this.validateEmail(email);
    if (validateEmailError) {
        this.setState({emailError: validateEmailError});
        return;
    }

    let message: any = this.state.message;
    const validateMessageError = this.validateInput(message);
    if (validateMessageError) {
        this.setState({messageError: validateMessageError});
        return;
    }

    const header = {
        "Content-Type": configJSON.contentType
    };

    const data = {
        email: this.state.email,
        message: this.state.message
    };


    const httpBody = {
        data: data,
    };
    // this.props.showLoader();    
    this.setState({ loading: true })
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSupportsCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsApiEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

}
//END --here we are validating the phone number and country code and making API call to get OTP to verify user 

validateInput(inputMsg: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(inputMsg)) {
        error = "Message " + configJSON.errorBlankField;
    }
    return error;
}

isNonNullAndEmpty(value: String) {
    return (
        value !== undefined &&
        value !== null &&
        value !== "null" &&
        value.trim().length > 0
    );
}

validateEmail(email: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(email)) {
      error = "Email " + configJSON.errorBlankField;
    } else if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }
    return error;
}

isValidEmail(email: string) {
    return this.emailReg.test(email);
}

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  // Customizable Area End
}
