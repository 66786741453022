import React, { PureComponent } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import StorageProvider from "../../framework/src/StorageProvider";

export type Props = RouteProps & {
  role: string;
};
export interface S {
  id: any;
  token: any;
  role: any;
  loading: boolean;
}
export default class PrivateRoute extends PureComponent<Props, S> {
  state = {
    id: null,
    token: null,
    role: null,
    loading: true,
  };
  async componentDidMount() {
    let userData = await StorageProvider.get("loginData");
    if (userData) {
      userData = JSON.parse(userData);
      this.setState({
        id: userData.id,
        token: userData.token,
        role: userData.user_type,
        loading: false,
      });
    } else {
        this.setState({loading: false})
    }
  }
  render() {
    return !this.state.loading ? (
      this.state.role !== this.props.role || this.state.token === null ? (
        <Redirect to="/PhoneLoginWeb" />
      ) : (
        <Route {...this.props} />
      )
    ) : null;
  }
}
