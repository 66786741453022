// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
import {
    Alert, ToastAndroid,
    Platform
} from "react-native";
// Customizable Area Start
import { withStyles, Theme, createStyles,StyledProps } from "@material-ui/core/styles";
import { RouteProps } from "react-router";
// Customizable Area End

export const configJSON = require("./config");

export type Props = StyledProps & RouteProps & {
    id: string;
    openDialog : any;
    showNotification : any;
    showLoader : any;
    hideLoader : any;
    mode : 'create' | 'edit';
    // Customizable Area Start
    // Customizable Area End
  }

interface S {
    // Customizable Area Start
    loading: boolean;
    email: string;
    lat: string;
    lng: string;
    id: string;
    authToken: string;
    filePath: null,
    fileName: string;
    fileType: any;
    imageUri: any,
    profileImagePath: string,
    shopName: string;
    ownersName: string;
    shopAddress: string;
    gstNumber: string;
    bhimUPI: string;
    printColors: Array<string>;
    pagesAvailables: Array<string>;
    pagesAvailable: string;
    pageA1Available: boolean;
    pageA2Available: boolean;
    pageA3Available: boolean;
    pageA4Available: boolean;
    pageLetterAvailable: boolean;
    pageAllAvailable: boolean;
    bothPrintAvailable: boolean;
    selectedPrintColor: string;
    selectedPageSize : []
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class EditMerchantBusinessDetailsController extends BlockComponent<
    Props,
    S,
    SS
    > {
    phoneReg: RegExp;
    apiType: string;
    attrs: {};
    emailReg: RegExp;
    renderFunction:any;
    gstReg: RegExp;
    upiReg: RegExp;
    apiUpdateProfileCallId: String = "";
    apiDeleteProfileImageCallId: string = "";
    pagesAvailables =  [] 
    printColors =  []
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage)
        ]

        this.state = {
            loading: false,
            email: "",
            address: "",
            lat: "",
            lng: "",
            id: "",
            authToken: "",
            filePath: null,
            fileName: "",
            fileType: "",
            imageUri: undefined,
            profileImagePath: "",
            shopName: "",
            ownersName: "",
            shopAddress: "",
            gstNumber: "",
            bhimUPI: "",
            printColors: [],
            pageA1Available: false,
            pageA2Available: false,
            pageA3Available: false,
            pageA4Available: false,
            pageLetterAvailable: false,
            pageAllAvailable: false,
            pagesAvailables: [],
            pagesAvailable: "all",
            bothPrintAvailable: false,
            selectedPrintColor: '',
            selectedPageSize : []
        };
        // Customizable Area End
        this.phoneReg = new RegExp(/^[0-9]+$/);
        this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.gstReg = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/;
        this.upiReg = /^\w.+@\w+$/;
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if(getName(MessageEnum.AlertMessage) === message.id){
            const title = message.getData(
              getName(MessageEnum.AlertTitleMessage)
            );
    
            var AlertBodyMessage = message.getData(
              getName(MessageEnum.AlertBodyMessage)
            );
            this.props.showNotification(AlertBodyMessage,'error');  
            
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiUpdateProfileCallId) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.meta && responseJson.meta.message) {
                    let data: any = {}
                    data.shop_name = this.state.shopName;
                    data.owner_name = this.state.ownersName;
                    data.shop_address = this.state.shopAddress;
                    data.gst_number = this.state.gstNumber;
                    data.bhim_upi_number = this.state.bhimUPI;
                    data.email = this.state.email;
                    data.lat = this.state.lat;
                    data.lng = this.state.lng;
                    data.pages = this.pagesAvailables;
                    data.print_colours = this.printColors;
                    let logindata: any = {}
                    logindata=JSON.parse( await StorageProvider.get('loginData'))
                    if(Array.isArray(responseJson.data)){
                        data.merchantId = responseJson.data[0].attributes.id ? responseJson.data[0].attributes.id : "";
                        data.profile_image_path = responseJson.data[0].attributes.profile_image_path ? responseJson.data[0].attributes.profile_image_path : "";
                        logindata.profile_image_path = responseJson.data[0].attributes.profile_image_path ? responseJson.data[0].attributes.profile_image_path : "";
                        logindata.shop_name = responseJson.data[0].attributes.shop_name ? responseJson.data[0].attributes.shop_name : "";
                        await StorageProvider.remove('loginData')
                        await StorageProvider.set('loginData', JSON.stringify(logindata));
                    } else {
                        data.profile_image_path = responseJson.data.attributes.profile_image_path ? responseJson.data.attributes.profile_image_path : "";
                        data.merchantId = responseJson.data.attributes.id ? responseJson.data.attributes.id : "";
                        logindata.merchantId = responseJson.data.attributes.id ? responseJson.data.attributes.id : "";
                        await StorageProvider.remove('loginData')
                        await StorageProvider.set('loginData', JSON.stringify(logindata));
                    }


                    await StorageProvider.remove('businessDetailsData');
                    await StorageProvider.set('businessDetailsData', JSON.stringify(data));
                    await StorageProvider.remove('businessDetails');
                    await StorageProvider.set('businessDetails', "yes");
                    if(this.props.mode === 'create'){
                        this.props.showNotification("Service provider profile details updated successfully","success");
                        setTimeout(() => {
                            if(this.state.bhimUPI){
                                this.props.history.push("/MerchantSettings")    
                            } else {
                                this.props.history.push("/Merchant/AddMerchantPayoutDetails")
                            }
                        }, 1200);
                    } else {
                        this.props.showNotification("Service provider profile details updated successfully","success");
                    }
                } else {
                    if(Array.isArray(responseJson.errors)){
                        this.parseApiErrorResponse(responseJson);
                    } else {
                       this.props.showNotification(responseJson.errors,'error');
                    }
                }
                if(this.props.mode !== 'create'){
                    this.renderFunction()
                }
            }
            else if (apiRequestCallId === this.apiDeleteProfileImageCallId) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.data && responseJson.data.id) {
                    //TODO: Need to uncomment this to go with the login flow
                    let newdata: any = {}
                    newdata=JSON.parse( await StorageProvider.get('loginData'))
                    newdata.profile_image_path = "";

                    await StorageProvider.remove('loginData')
                    await StorageProvider.set('loginData', JSON.stringify(newdata));

                    this.props.showNotification(responseJson.meta.message,"success");
                    //   Alert.alert();

                    this.setState({
                        profileImagePath: '',
                        filePath: null,
                        fileName: '',
                        fileType: '',
                        imageUri: '',
                    },()=>{
                        const requestMessage = new Message(
                          getName(MessageEnum.UserProfilePictureUpdateMessage)
                        );
                        requestMessage.addData(
                          getName(MessageEnum.UserProfilePictureDataMessage),
                          newdata.profile_image_path
                        );
                        runEngine.sendMessage(requestMessage.id, requestMessage);
                        if(this.props.mode !== 'create'){
                            this.renderFunction()
                        }
                    });
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    this.sendAPIFailMessage();
                }

            }
        }
        // Customizable Area End
    }
    deleteProfileImageApiCall(renderComp) {
        this.renderFunction=renderComp
        this.props.showLoader();
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.contentTypeApiUpdateUser
        };

        const data = {
            user_type: 'Merchant',
            account_id: this.state.id
        };

        const httpBody = {
            token: this.state.authToken,
            data: data
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiDeleteProfileImageCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          (configJSON.deleteProfileAPIEndPoint)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.patchAPiEndMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // renderComp()
    }
    //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
    async updateMerchantBusinsessDetailsCall(renderComp) {
        this.renderFunction=renderComp
        let shopName: any = this.state.shopName;
        let error: any = "";
        error = this.validateField(shopName, "Shop Name ");

        if (error) {
            this.props.showNotification(error,"error");
            return;
        }


        let ownersName: any = this.state.ownersName;

        error = this.validateField(ownersName, "Owner's Name ");

        if (error) {
            this.props.showNotification(error,"error");
            return;
        }

        let email: any = this.state.email;

        error = this.validateField(email, "Email ");

        if (error) {
            this.props.showNotification(error,"error");
            return;
        }

        let shopAddress: any = this.state.shopAddress;

        error = this.validateField(shopAddress, "Shop Address ");

        if (error) {
            this.props.showNotification(error,"error");
            return;
        }


        let gstNumber: any = this.state.gstNumber;
        if (gstNumber.length > 0) {
            error = this.validateGST(gstNumber);

            if (error) {
                this.props.showNotification(error,"error");
                return;
            }
        }
        let bhimUPI: any = this.state.bhimUPI;

        if (bhimUPI.length > 0) {
            error = this.validateBHIMUpi(bhimUPI);

            if (error) {
                this.props.showNotification(error,"error");
                return;
            }

        }
        let emailID: any = this.state.email;

        if (emailID.length > 0) {
            error = this.validateEmail(emailID);
            if (error) {
                this.props.showNotification(error,"error");
                return;
            }
        }
        this.pagesAvailables =  [] 
        this.printColors =  []

        if (this.state.pageA1Available) {
            this.pagesAvailables.push('A1')
        }
        if (this.state.pageA2Available) {
            this.pagesAvailables.push('A2')
        }
        if (this.state.pageA3Available) {
            this.pagesAvailables.push('A3')
        }
        if (this.state.pageA4Available) {
            this.pagesAvailables.push('A4')
        }
        if (this.state.pageLetterAvailable) {
            this.pagesAvailables.push('Letter')
        }
        if (this.state.pageAllAvailable) {
            this.pagesAvailables.push('All')
        }
        if(this.state.bothPrintAvailable){
            this.printColors.push("Color","Grey");
        }
        if(this.state.selectedPrintColor){
            this.printColors.push(this.state.selectedPrintColor)
        }
        if (this.printColors.length == 0) {
            this.props.showNotification("Please choose print colours available","error");
            return;
        }
        if (this.pagesAvailables.length == 0) {
            this.props.showNotification("Please choose print pages available","error");
            return;
        }
        const formData = new FormData();
        formData.append('token', this.state.authToken);
        if(this.props.mode === 'create'){
           formData.append('data[account_id]', this.state.id);
        }
        formData.append('data[shop_name]', this.state.shopName);
        formData.append('data[owner_name]', this.state.ownersName);
        formData.append('data[shop_address]', this.state.shopAddress);
        formData.append('data[bhim_upi_number]', this.state.bhimUPI);
        formData.append('data[gst_number]', this.state.gstNumber);
        formData.append('data[email]', this.state.email);
        formData.append('data[lat]', this.state.lat);
        formData.append('data[lng]', this.state.lng);
        formData.append('data[print_colours]', JSON.stringify(this.printColors));
        formData.append('data[page_types]', JSON.stringify(this.pagesAvailables));

        if (typeof this.state.fileName != 'undefined' && this.state.fileName) {
            formData.append('data[image]', this.state.imageUri);
        }

        this.setState({ loading: true });
        this.props.showLoader();
        const header = {
            "accept": "application/json",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiUpdateProfileCallId = requestMessage.messageId;
        const apiEndPoint = this.props.mode === 'create' ? configJSON.updateBusinessDetailsAPIEndPoint : configJSON.updateBusinessDetailsAPIEndPoint + "/" + this.state.id;    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.props.mode === 'create'?configJSON.postAPiEndMethod:configJSON.patchAPiEndMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    validateField(name: string, fieldName: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(name)) {
            error = fieldName + configJSON.errorBlankField;
        } else if (!this.isValidName(name)) {
            error = fieldName + configJSON.errorNotValid;
        }
        return error;
    }

    validateName(name: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(name)) {
            error = "Name " + configJSON.errorBlankField;
        } else if (!this.isValidName(name)) {
            error = configJSON.errorNameNotValid;
        }
        return error;
    }

    isValidName(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 3
        );
    }

    validateAddress(name: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(name)) {
            error = "Address " + configJSON.errorBlankField;
        } else if (!this.isValidName(name)) {
            error = configJSON.errorNameNotValid;
        }
        return error;
    }

    isValidAddress(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length < 10
        );
    }

    isNonNullAndEmpty(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 0
        );
    }

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    validateEmail(email: string) {
        let error = null;
        if (!this.isValidEmail(email)) {
            error = configJSON.errorEmailNotValid;
        }
        return error;
    }
    isValidEmail(email: string) {
        return this.emailReg.test(email);
    }

    txtInputEmailWebProps = {
        value: "",
        editable: true,
        onChangeText: (text: string) => {
            if (this.txtInputEmailProps.editable) {
                this.setState({ email: text })
                this.txtInputEmailProps.value = text
            }
        }
    }

    txtInputEmailMobileProps = {
        ...this.txtInputEmailWebProps,
        keyboardType: "email-address",
    }

    txtInputEmailProps = this.isPlatformWeb()
        ? this.txtInputEmailWebProps
        : this.txtInputEmailMobileProps;


    notifyMessage(msg: string) {
        if (Platform.OS === 'android') {
            ToastAndroid.show(msg, ToastAndroid.SHORT)
        }
    }

    validateGST(gst: string) {
        let error = null;
        console.log("GST==>", gst);

        if (!this.isGstNonNullAndEmpty(gst)) {
            error = configJSON.errorGSTNotValid;
        } else if (!this.isValidGST(gst)) {
            error = configJSON.errorGSTNotValid;
        }
        return error;
    }

    validateBHIMUpi(bhimupi: string) {
        let error = null;
        console.log("bhimupi==>", bhimupi);

        if (!this.isNonNullAndEmpty(bhimupi)) {
            error = configJSON.errorBHIMUPINotValid;
        } else if (!this.isValidUPI(bhimupi)) {
            error = configJSON.errorBHIMUPINotValid;
        }
        return error;
    }

    isGstNonNullAndEmpty(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            (value.trim().length > 0 &&
                value.trim().length < 16)
        );
    }

    isValidGST(gst: string) {
        return this.gstReg.test(gst);
    }

    isValidUPI(bhimUpi: string) {
        return this.upiReg.test(bhimUpi);
    }


}
