// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Alert } from "react-native";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider";
export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  openDialog: any;
  classes: any;
  showNotification : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  username: string;
  profileImagUri: string;
  authToken: String,
  id: String
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MerchantSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      username: "",
      profileImagUri: "",
      authToken: "",
      id: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (apiRequestCallId === this.apiDeleteProfileCallId) {
          if (responseJson && responseJson.Message) {
              this.props.hideLoader();
              this.props.showNotification(responseJson.Message,'success')
              const keys=  await StorageProvider.getKeys();
              await StorageProvider.removeMultiple(keys)
              await StorageProvider.set("user_type",this.state.user_type)
              setTimeout(() => {
                this.props.history.push("/PhoneLoginWeb");  
              }, 1000);
    
          } else {
            //Check Error Response
              this.parseApiErrorResponse(responseJson);
          }
  
        }
      } else if(getName(MessageEnum.AlertMessage) === message.id){
        const title = message.getData(
          getName(MessageEnum.AlertTitleMessage)
        );

        var AlertBodyMessage = message.getData(
          getName(MessageEnum.AlertBodyMessage)
        );
        
        this.props.showNotification(AlertBodyMessage,'error');  
        
    }
    // Customizable Area End
  }

  async accountDeleteApiCall() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProfileCallId = requestMessage.messageId;

    let deleteAccountEndPoint = ('' + configJSON.deleteAccountEndPoint + '' + this.state.id)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (deleteAccountEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
}
