// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Grid, Typography, Button,InputBase,Collapse } from '@material-ui/core';
import { withRouter } from "react-router";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import Header from "./Header.Web";
import Footer from "./Footer.Web";

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./ContactUsController.Web";
import './css/Header.css'
import {
  facebookIcon,
  githubIcon,
  linkedinIcon,
  twitterIcon,
  instgramIcon,
 logo, homeicon, abouticon,
servicesicon, contactusicon, 
 usericon, arrowdown, closeicon, menuicon
} from './assets'

 class ContactUs extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  changeMenu=()=>{
    this.setState({mobilemenu:!this.state.mobilemenu})
    const mobileMenu=document.getElementById("navMobileDropdown")
    mobileMenu.classList.toggle("navMobileHeight")
  }


  async componentDidMount() {
    this.getHeaderFooterContent()
   }

   navigateTo = (pathName) => {
       this.props.history.push(pathName);
   }

   _contactUsMail = async () => {
    await this.supportsAPICall();
   }

   handleChangeInput = (event) => {
    this.setState({emailError: false});
    this.setState({messageError: false});
    this.setState({[event.target.name]: event.target.value})
  }

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.parentCont}
      >
        <Header />
        <Grid item xs={12} className={"navBarTopMargin"}/>

        <Grid item xs={12} container className={classes.rightContent}>
          <Grid item xs={11} className={classes.headingCont}>
            <Typography className={classes.selectFilesText}>Contact Us</Typography>
          </Grid>
          <Grid item xs={11} className={classes.inputDiv}>
            <Typography className={classes.inputLabel}>YOUR Email</Typography>
            <InputBase
              className={classes.emailInput}
              name="email"
              onChange={this.handleChangeInput}
              value={this.state.email}
              placeholder="Enter Your Email"
            />
          <p className={`error-label ${classes.sofiaFont}`}>
            {this.state.emailError}
          </p>
          </Grid>
          <Grid item xs={11} className={classes.inputDiv}>
            <Typography className={classes.inputLabel}>message</Typography>
            <InputBase
              className={classes.messageInput}
              multiline
              name="message"
              onChange={this.handleChangeInput}
              value={this.state.message}
              rows={10}
              placeholder="Enter your message here"
            />
            <p className={`error-label ${classes.sofiaFont}`}>
            {this.state.messageError}
            </p>
          </Grid>
          <Grid item xs={11} className={classes.btnCont}>
            <Button className={classes.findPrintersBtn} 
              onClick={this._contactUsMail}
            style={{backgroundColor:'#32795f',}}>SEND</Button>
          </Grid>
        </Grid>
        <Footer />
      </Grid>

      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
    // display:"flex",
    // flexDirection:"column"
  },
  navBar:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    boxShadow:"0 2px 7px 0 rgba(147, 147, 147, 0.5)"
    // minHeight:150,
    // background:"red"
  },
  navMiddle:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-evenly"
  },
  navLeft:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  navRight:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  dotsCont:{
    position:"absolute",
    bottom:"10%",
    width:"100%",
    display:"flex",
    justifyContent:'center',
    alignItems:"center"
  },
  footerCont:{
    backgroundColor:APP_COLORS.app_theme_green_color,
    borderRadius:32,
    boxShadow: "0 -1px 6px 0 rgba(0, 0, 0, 0.5)",
  },
  footerIconsCont:{
    display:"flex",
    width:"fit-content",
    margin:"auto"
  },

  footerIcon:{
    width:30,
    height:30,
    objectFit:"contain",
    marginRight:10,
    marginLeft:10
  },
  macbookImg:{
    width:"100%",
    height:"100%",
    objectFit:"contain",
  },
  addUserImg:{
    width:120,
    height:120,
    objectFit:"contain",
    marginRight:"auto"
  },
  group7Img:{
    width:100,
    height:100,
    objectFit:"contain",
    position:"absolute",
    top:'20%',
    zIndex:"-10",
    left:"3%"
  },
  bannerBackImg:{
    width:"100%",
    height:"100%",
    objectFit:"cover",
    position:"absolute",
    // left:0,
    zIndex:"-10",
    top:0
  },
  AppStore:{
    width:100,
    height:30,
    objectFit:"contain"
  },
  QRCodeImg:{
    width:60,
    height:60,
    objectFit:"contain"
  },
  banner2Img:{
    width:"30%",
    objectFit:"contain"
  },
  bannerRightImg:{
    width:"70%",
    objectFit:"contain"
  },
  PlayStore:{
    width:100,
    marginTop:10,
    height:30,
    objectFit:"contain",
  },
  logoImg:{
    width:90,
    height:90,
    margin:"5px auto",
    objectFit:"contain",
    cursor: 'pointer'
  },
  navMiddleImg:{
    width:45,
    height:45,
    marginRight:"10px",
    objectFit:"contain",
  },
  navDownArrowImg:{
    width:20,
    height:20,
    marginLeft:"7px",
    float:"right",
    objectFit:"contain",
  },
  loginUser:{
    width:20,
    height:20,
    objectFit:"contain",
    marginRight:7
  },
  group12Img:{
    width:350,
    height:350,
    objectFit:"contain",
    position:"absolute",
    zIndex:"-10",
    bottom:'0%',
    right:"5%"
  },

  //Text stlyling
  footerText:{
    fontSize:16,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'white',
    marginLeft:10,
    marginRight:10
  },
  navMiddleItem:{
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    display:"flex",
    alignItems:'center',
    cursor: 'pointer'
  },
  navMiddleMenuItem:{
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    margin:"10px auto",
    // padding:"0 20px",
    width:"100%",
    display:"flex",
    alignItems:'center',
    justifyContent:'space-between'
  },



  //Button styling
  getStartedBtn:{
    color:'white',
    borderRadius:12,
    height:50,
    textTransform:"none",
    width:200,
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },
  bannerThreeBtn:{
    color:'white',
    borderRadius:12,
    height:50,
    textTransform:"none",
    width:250,
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },
  navLoginBtn:{
    borderRadius:12,
    height:45,
    width:130,
    textTransform:"none",
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },
  navSignupBtn:{
    borderRadius:12,
    height:45,
    color:"white",
    width:130,
    textTransform:"none",
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },

  //Dot Styling
  dot:{
    width:15,
    height:15,
    borderRadius:"50%",
    marginRight:10,
    cursor:"pointer"
  },

  rightContent:{
    position:"relative",
    minHeight:'80%',
    width:'100%',
    background:`#ffffff`,
    borderRadius:12,
    marginBottom:40
  },
  headingCont:{
    width:'100%',
    margin:"50px auto 0"
  },
  inputDiv:{
    margin:'10px auto'
  },
  selectFilesText:{
    fontSize:22,
    margin:"20px 0 10px 0",
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    // fontWeight:'bold',
    color:'#1e1f20'
    },
  findPrintersBtn: {
    backgroundColor:'#32795f',
    color:'white',
    borderRadius:12,
    width:'90%',
    margin:'20px auto 30px 0',
    fontFamily:APP_FONTS.SofiaProRegular,
    height:55,
    maxWidth:300
    },
  btnCont:{
    margin:'auto',
    marginTop:'20px',
    borderRadius:12,
  },
  selectFilesText:{
    fontSize:22,
    margin:"20px 0 10px 0",
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    // fontWeight:'bold',
    color:'#1e1f20'
  },
//labels
    inputLabel:{
    fontSize:14,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    color:'#1e1f20',
    letterSpacing:1,
    textTransform:'uppercase',
    margin:"10px 0"
    },

    //input fields
    emailInput:{
    border:'1px solid rgba(143, 146, 161, 0.4)',
    padding:" 5px",
    borderRadius:4,
    // opacity:"0.4",
    width:'90%',
    color:"#8f92a1",
    maxWidth:450,
    fontFamily:APP_FONTS.SofiaProRegular,
    },
    messageInput:{
    border:'1px solid rgba(143, 146, 161, 0.4)',
    padding:"5px",
    borderRadius:4,
    color:"#8f92a1",
    // opacity:"0.4",
    width:'90%',
    maxWidth:450,
    fontFamily:APP_FONTS.SofiaProRegular,
    }

});
// Customizable Area End

const UserSupportWithConfirmBox = withLoader(ContactUs)
const UserSupportWithLoader = withToast(UserSupportWithConfirmBox)
export default withRouter(withStyles(styles, { withTheme: true })(UserSupportWithLoader));