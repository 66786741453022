import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
import { withStyles, Theme, createStyles,StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
export type Props = StyledProps & RouterProps & {
    id: string;
    openDialog : any;
    showNotification : any;
    showLoader : any;
    hideLoader : any;
    mode : 'create' | 'edit';
    // Customizable Area Start
    // Customizable Area End
  }

interface S {
    // Customizable Area Start
    loading: boolean;
    beneficiaryName: string;
    accountNumber: string;
    bankName: string;
    ifscCode: string;
    bankAddress: string;
    authToken: string;
    bhimUPI:string;
    id: number;
    confirmAccountNumber:string;
    error : string | null,
    merchantId : any,
    isPayoutDetailAvailable : boolean
}

interface SS {
    id: any;
}

export default class EditPayoutDetailsController extends BlockComponent<
    Props,
    S,
    SS
    > {
    phoneReg: RegExp;
    ifscReg: RegExp;
    apiType: string;
    attrs: {};
    emailReg: RegExp;
    upiReg: RegExp;
    apiUpdatePayoutDetailsCallId: String = "";
    apiUpdateProfileCallId:String="";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage)
        ]

        this.state = {
            loading: false,
            authToken: "",
            beneficiaryName: '',
            accountNumber: '',
            confirmAccountNumber:"",
            bankName: '',
            ifscCode: '',
            bhimUPI:"",
            bankAddress: '',
            id: 0,
            error : null,
            merchantId : null,
            isPayoutDetailAvailable : true

        };
        // Customizable Area End
        this.phoneReg = new RegExp(/^[0-9]+$/);
        this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.ifscReg = /^[A-Za-z]{4}\d{7}$/;
        this.upiReg = /^\w.+@\w+$/;
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if(getName(MessageEnum.AlertMessage) === message.id){
            const title = message.getData(
              getName(MessageEnum.AlertTitleMessage)
            );
    
            var AlertBodyMessage = message.getData(
              getName(MessageEnum.AlertBodyMessage)
            );
            this.props.showNotification(AlertBodyMessage,'error');  
            
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiUpdatePayoutDetailsCallId) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.data && responseJson.meta) {
                    let data: any = {}
                    data.beneficiary_name = this.state.beneficiaryName;
                    data.account_number = this.state.accountNumber;
                    data.bank_name = this.state.bankName;
                    data.ifsc_code = this.state.ifscCode;
                    data.bank_address = this.state.bankAddress;

                    await StorageProvider.remove('payoutDetailsData');
                    await StorageProvider.set('payoutDetailsData', JSON.stringify(data));

                    await StorageProvider.remove('payoutDetails')
                    await StorageProvider.set('payoutDetails', "yes");
                    setTimeout(() => {
                        this.props.mode === 'create' &&
                            this.props.history.push('/MerchantSettings');    
                    }, 1000);
                    this.props.showNotification("Payout details updated successfully",'success');

                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
                    // this.sendAPIFailMessage();
                }
 
            }
            if (apiRequestCallId===this.apiUpdateProfileCallId){
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.data && responseJson.meta) {
                    let businessData=JSON.parse(await StorageProvider.get('businessDetailsData'))
                    businessData.bhim_upi_number = this.state.bhimUPI;

                    await StorageProvider.remove('businessDetailsData');
                    await StorageProvider.set('businessDetailsData', JSON.stringify(businessData));

                    this.props.showNotification("BHIM UPI details updated successfully",'success');

                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
                    // this.sendAPIFailMessage();
                }
            }
        }
        // Customizable Area End
    }

    
    // Customizable Area End
    //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
    updatePayoutDetailsAPICall() {

        let beneficiaryName: any = this.state.beneficiaryName;
        let error: any = "";
        this.setState({error : error})
        error = this.validateField(beneficiaryName, "Beneficiary Name ");
        if (error){
            this.props.showNotification(error, "error");
            this.setState({error : error})
            return;
        }
        error=this.validateAccountNumbers()
        if (error) {
            this.props.showNotification(error, "error");
            this.setState({error : error})
            return;
        }

        let accountNumber: any = this.state.accountNumber;

        error = this.validateField(accountNumber, "Account Number ");

        if (error) {
            this.props.showNotification(error, "error");
            this.setState({error : error})
            return;
        }

        let bankName: any = this.state.bankName;

        error = this.validateField(bankName, "Bank Name ");

        if (error) {
            this.props.showNotification(error, "error");
            this.setState({error : error})
            return;
        }
        let ifsc: any = this.state.ifscCode;

        error = this.validateIFSC(ifsc);

        if (error) {
            this.setState({error : error})
            this.props.showNotification(error, "error");
            return;
        }

        let bankAddress: any = this.state.bankAddress

        error = this.validateField(bankAddress, "Bank Address ");

        if (error) {
            this.setState({error : error})
            this.props.showNotification(error, "error");
            return;
        }
        this.props.showLoader();
        this.setState({ loading: true });
        

        const header = {
            "Content-Type": configJSON.contentType
        };

        const data = {
            account_id: Number(this.state.id),
            bank_details: "hdfc",
            beneficiary_name: this.state.beneficiaryName,
            ifsc_code: this.state.ifscCode,
            account_number: this.state.accountNumber,
            bank_name: this.state.bankName,
            bank_address: this.state.bankAddress,
        };

        const httpBody = {
            token: this.state.authToken,
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiUpdatePayoutDetailsCallId = requestMessage.messageId;
        // const apiEndPoint = this.props.mode === 'create' ? configJSON.updatePayoutDetailsAPIEndPoint : ;    
        let url = '';
        if(this.props.mode === 'create'){
            url = configJSON.updatePayoutDetailsAPIEndPoint
        } else  {
            if(this.state.isPayoutDetailAvailable){
                url = configJSON.updatePayoutDetailsAPIEndPoint + "/" + this.state.id
            } else {
                url = configJSON.updatePayoutDetailsAPIEndPoint
            }
        }    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        let method = '';
        if(this.props.mode === 'create'){
            method = configJSON.postAPiEndMethod
        } else  {
            if(this.state.isPayoutDetailAvailable){
                method = configJSON.patchAPiEndMethod
            } else {
                method = configJSON.postAPiEndMethod
            }
        }   
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    async updateMerchantBusinsessDetailsCall() {
        let error: any = "";
        let bhimUPI: any = this.state.bhimUPI;

        if (bhimUPI.length > 0) {
            error = this.validateBHIMUpi(bhimUPI);

            if (error) {
                this.props.showNotification(error,"error");
                return;
            }

        }
        let businessDetailsData=await StorageProvider.get('businessDetailsData')
        if (businessDetailsData){
            businessDetailsData=JSON.parse(businessDetailsData)
            const formData = new FormData();
            console.log("businessDetailsData",businessDetailsData)
            formData.append('token', this.state.authToken);
            formData.append('data[shop_name]', businessDetailsData.shop_name);
            formData.append('data[owner_name]', businessDetailsData.owner_name);
            formData.append('data[shop_address]', businessDetailsData.shop_address);
            formData.append('data[bhim_upi_number]', this.state.bhimUPI);
            formData.append('data[gst_number]', businessDetailsData.gst_number);
            formData.append('data[email]', businessDetailsData.email);
            formData.append('data[lat]', businessDetailsData.lat);
            formData.append('data[lng]', businessDetailsData.lng);
            formData.append('data[print_colours]', JSON.stringify(businessDetailsData.print_colours));
            formData.append('data[page_types]', JSON.stringify(businessDetailsData.pages));
            this.setState({ loading: true });
            this.props.showLoader();
            const header = {
                "accept": "application/json",
            };

            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiUpdateProfileCallId = requestMessage.messageId;
            const apiEndPoint = this.props.mode === 'create' ? configJSON.updateBusinessDetailsAPIEndPoint : configJSON.updateBusinessDetailsAPIEndPoint + "/" + this.state.id;
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              apiEndPoint
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage), formData
            );

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              this.props.mode === 'create'?configJSON.postAPiEndMethod:configJSON.patchAPiEndMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }

    }
    validateName(name: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(name)) {
            error = "Name " + configJSON.errorBlankField;
        } else if (!this.isValidName(name)) {
            error = configJSON.errorNameNotValid;
        }
        return error;
    }


    validateField(name: string, fieldName: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(name)) {
            error = fieldName + configJSON.errorBlankField;
        } else if (!this.isValidName(name)) {
            error = fieldName + configJSON.errorNotValid;
        }
        return error;
    }

    isValidName(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 3
        );
    }


    isNonNullAndEmpty(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 0
        );
    }

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    notifyMessage(msg: string) {
        // if (Platform.OS === 'android') {
        //     ToastAndroid.show(msg, ToastAndroid.SHORT)
        // }

        this.props.showNotification("Alert!!!", msg);
    }

    validateIFSC(ifsc: string) {
        let error = null;
        if (!this.isIFSCNonNullAndEmpty(ifsc)) {
            error = configJSON.errorIFSCNotValid;
        } else if (!this.isValidIFSC(ifsc)) {
            error = configJSON.errorIFSCNotValid;
        }
        return error;
    }

    isIFSCNonNullAndEmpty(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            (value.trim().length > 0) &&
            (value.trim().length < 12)
        );
    }

    isValidIFSC(ifsc: string) {
        return this.ifscReg.test(ifsc);
    }
    validateBHIMUpi(bhimupi: string) {
        let error = null;
        console.log("bhimupi==>", bhimupi);

        if (!this.isNonNullAndEmpty(bhimupi)) {
            error = configJSON.errorBHIMUPINotValid;
        } else if (!this.isValidUPI(bhimupi)) {
            error = configJSON.errorBHIMUPINotValid;
        }
        return error;
    }
    isValidUPI(bhimUpi: string) {
        return this.upiReg.test(bhimUpi);
    }
    validateAccountNumbers(){
        if(this.state.accountNumber!==this.state.confirmAccountNumber){
            return configJSON.errorAccountNumber
        }
    }

}
