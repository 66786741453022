// @ts-nocheck
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { Grid } from '@material-ui/core';
import { withRouter } from "react-router";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
// Customizable Area End
import Header from "./Header.Web";
import Footer from "./Footer.Web";

class TermsOfService extends BlockComponent {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
      container
      direction="row"
      justify="center"
      className={classes.parentCont}
    >
      <Header />
      <Grid item xs={12} className={"navBarTopMargin"}/>
      <Grid item xs={12} className={classes.privacyContent}>
       Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
       when an unknown printer took a galley of type and scrambled it to make a type 
       specimen book. It has survived not only five centuries, but also the leap into 
       electronic typesetting, remaining essentially unchanged. It was popularised in 
       the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
       and more recently with desktop publishing software like Aldus PageMaker including 
       versions of Lorem Ipsum.
       
       Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
       when an unknown printer took a galley of type and scrambled it to make a type 
       specimen book. It has survived not only five centuries, but also the leap into 
       electronic typesetting, remaining essentially unchanged. It was popularised in 
       the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
       and more recently with desktop publishing software like Aldus PageMaker including 
       versions of Lorem Ipsum.

       Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
       Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
       when an unknown printer took a galley of type and scrambled it to make a type 
       specimen book. It has survived not only five centuries, but also the leap into 
       electronic typesetting, remaining essentially unchanged. It was popularised in 
       the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
       and more recently with desktop publishing software like Aldus PageMaker including 
       versions of Lorem Ipsum.
      </Grid>
      <Footer />
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
  },
  privacyContent: {
    padding:"20px 20px 20px 20px",
  }
});

export default withRouter(withStyles(styles, { withTheme: true })(TermsOfService));
