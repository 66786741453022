// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { Grid, Typography, Button, TextField,InputBase, Collapse } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import StorageProvider from "../../../framework/src/StorageProvider";
import EditPayoutDetailsController, {
  Props,
  configJSON,
} from "./EditPayoutDetailsController.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withRouter } from "react-router";
import {
  cameraIcon,
  profileIcon,
  deleteIcon,
  mapsIcons,
  userLogo,
} from "./assets";

class EditPayoutDetails extends EditPayoutDetailsController {
  constructor(props: Props) {
    super(props);
    this.state={
      tab:-1
    }
    // Customizable Area Start
    // Customizable Area End
  }
  _saveAndContinueTapped = () => {
    this.updatePayoutDetailsAPICall();
  };
  _saveBhimUPI = () => {
    this.updateMerchantBusinsessDetailsCall();
  };
  setTab=(num)=>{
    this.setState({tab: num})
  }
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  async componentDidMount() {
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        id: loginData.id,
        authToken: loginData.token,
      });
    }
    let businessDetailsData = await StorageProvider.get("businessDetailsData");
    if (businessDetailsData) {
      businessDetailsData = JSON.parse(businessDetailsData);
      this.setState({
        merchantId: businessDetailsData.merchantId,
        bhimUPI: businessDetailsData.bhim_upi_number,
      });
    }
    if (this.props.mode === "edit") {
      const payoutDetails = await StorageProvider.get("payoutDetails");
      if(payoutDetails === 'no'){
             this.setState({isPayoutDetailAvailable : false})  
      } else {
        this.setState({isPayoutDetailAvailable : true})  
      }
      let payoutDetailsData = await StorageProvider.get("payoutDetailsData");
      if (payoutDetailsData) {
        payoutDetailsData = JSON.parse(payoutDetailsData);
        this.setState({
          beneficiaryName: payoutDetailsData.beneficiary_name,
          accountNumber: payoutDetailsData.account_number,
          bankName: payoutDetailsData.bank_name,
          ifscCode: payoutDetailsData.ifsc_code,
          bankAddress: payoutDetailsData.bank_address,
        });
      }
    }
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const {mode} = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Grid item xs={12} sm={6}>
          <div className={classes.tabContentLeft} style={{paddingTop:30}}>
            <Typography className={classes.screenHeading}>
              {configJSON.labelUpdatePayoutDetails}
            </Typography>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.btnContainer}>
            <Button className={classes.editBtn} style={{backgroundColor:this.state.tab===1?"#FDD13C":"#d8d8d8"}} onClick={()=>this.setTab(1)}>Edit Upi Details</Button>
          </Grid>
          {/*{*/}
          {/*  this.state.tab === 1 ? (*/}
              <Collapse in={this.state.tab === 1} style={{width:"100%"}}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.tabContentLeft}>
                    <Typography variant="body2" className={classes.basicInfo} >Edit UPI Details</Typography>
                    <Typography className={classes.inputLabel}>
                      BHIM UPI*
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      placeholder={configJSON.enterBhimUpi}
                      name="bhimUPI"
                      value={this.state.bhimUPI}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}/>
                <Grid item xs={12} sm={6}>
                  <div className={classes.tabContentLeft}>
                    <Button
                      onClick={this._saveBhimUPI}
                      className={classes.saveChangesBtn}
                      style={{ backgroundColor: APP_COLORS.app_theme_green_color }}
                    >
                      {configJSON.btnTextSaveChanges}
                    </Button>
                  </div>
                </Grid>
              </Collapse>
            {/*) : ""*/}
          {/*}*/}
          <Grid item xs={12} className={classes.btnContainer}>
            <Button className={classes.editBtn} style={{backgroundColor:this.state.tab===2?"#FDD13C":"#d8d8d8"}} onClick={()=>this.setTab(2)}>Edit bank Details</Button>
          </Grid>
          {/*{*/}
          {/*  this.state.tab===2?(*/}
              <Collapse in={this.state.tab===2} style={{width:"100%"}}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.tabContentLeft}>
                    {mode === 'create' ? <>
                      <Typography className={classes.basicInfo} variant="body1">Before We Continue</Typography>
                      <Typography className={classes.basicInfo} variant="body2">Add Bank Detail(2/2)</Typography>
                      <Typography className={classes.basicInfo} variant="subtitle1">Basic Information</Typography></> : <Typography variant="body2" className={classes.basicInfo}>Edit Bank Details</Typography>}
                          <Typography className={classes.inputLabel}>
                            {configJSON.labelBeneficiaryName}
                          </Typography>
                          <InputBase
                            className={classes.inputTextBox}
                            // error={this.state.error ? true : false}
                            // helperText={this.state.error ? this.state.error : null}
                            placeholder={configJSON.enterBeneficiaryName}
                            value={this.state.beneficiaryName}
                            name="beneficiaryName"
                            onChange={this.handleInputChange}
                          />
                          <Typography className={classes.inputLabel}>
                            {configJSON.labelAccountNumber}
                          </Typography>
                          <InputBase
                            className={classes.inputTextBox}
                            // error={this.state.error ? true : false}
                            // helperText={this.state.error ? this.state.error : null}
                            placeholder={configJSON.enterAccountNumber}
                            value={this.state.accountNumber}
                            name="accountNumber"
                            onChange={this.handleInputChange}
                          />
                          <Typography className={classes.inputLabel}>
                            {configJSON.labelConfrimAccountNumber}
                          </Typography>
                          <InputBase
                            className={classes.inputTextBox}
                            // error={this.state.error ? true : false}
                            // helperText={this.state.error ? this.state.error : null}
                            placeholder={configJSON.confirmAccountNumber}
                            value={this.state.accountNumber}
                            name="confirmAccountNumber"
                            onChange={this.handleInputChange}
                          />
                          <Typography className={classes.inputLabel}>
                            {configJSON.labelBankName}
                          </Typography>
                          <InputBase
                            className={classes.inputTextBox}
                            // error={this.state.error ? true : false}
                            // helperText={this.state.error ? this.state.error : null}
                            placeholder={configJSON.enterBankName}
                            name="bankName"
                            value={this.state.bankName}
                            onChange={this.handleInputChange}
                          />
                          <Typography className={classes.inputLabel}>
                            {configJSON.labelIFSCCode}
                          </Typography>
                          <InputBase
                            className={classes.inputTextBox}
                            placeholder={configJSON.enterIFSCCode}
                            // error={this.state.error ? true : false}
                            // helperText={this.state.error ? this.state.error : null}
                            name="ifscCode"
                            value={this.state.ifscCode}
                            onChange={this.handleInputChange}
                          />
                          <Typography className={classes.inputLabel}>
                            Bank Address *
                          </Typography>
                          <InputBase
                            className={classes.inputTextBox}
                            placeholder={"Enter Bank Address"}
                            // error={this.state.error ? true : false}
                            // helperText={this.state.error ? this.state.error : null}
                            name="bankAddress"
                            value={this.state.bankAddress}
                            onChange={this.handleInputChange}
                          />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <div className={classes.tabContentLeft}>
                    <Button
                      onClick={this._saveAndContinueTapped}
                      className={classes.saveChangesBtn}
                      style={{ backgroundColor: APP_COLORS.app_theme_green_color }}
                    >
                      {configJSON.btnTextSaveChanges}
                    </Button>
                  </div>
                </Grid>
              </Collapse>
            {/*):""*/}
          {/*}*/}
        </Grid>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    tabContentLeft: {
      width: "85%",
      margin: "auto",
    },

    //Text Styling
    screenHeading: {
      fontSize: 22,
      // fontWeight:'bold',
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
      btnContainer:{
      display:"flex",
      justifyContent:"center",
      flexDirection:"column"
    },
      basicInfo: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginTop: 27,
    },
    inputLabel: {
      fontSize: 14,
      marginTop: 15,
      letterSpacing: 1,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },

    //Input Styling
    inputTextBox: {
      padding: "5px 10px",
      width: "100%",
      marginTop: 7,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },

    //Button styling
    saveChangesBtn: {
      color: "white",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "100%",
      height: 50,
      margin: "25px 0 20px 0",
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
      editBtn:{
      color: "#1e1f20",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "90%",
      height: 50,
      margin:"10px auto 0",
      // marginRight: "10px",
      // marginTop: 40,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    }
    });

      const EditPayoutDetailsWithConfirmBox = withConfirmBox(EditPayoutDetails);
      const EditPayoutDetailsWithLoader = withLoader(EditPayoutDetailsWithConfirmBox);
      const EditPayoutDetailsWithToast = withToast(EditPayoutDetailsWithLoader);
      export default withRouter(
      withStyles(styles, { withTheme: true })(EditPayoutDetailsWithToast)
      );

// Customizable Area End