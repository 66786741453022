// @ts-nocheck
import React from "react";
// Customizable Area Start
import { Grid, Typography, Button} from '@material-ui/core';
import { withRouter } from "react-router";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from '../../../components/src/AppColorConstants';
// Customizable Area End
import Header from "./Header.Web";
import Footer from "./Footer.Web";
import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController.Web";
import './css/Header.css'
import {
  macbookImg,
  macbookImg2,
  macbookImg3,
  addUser,
  group7,
  group12,
  slidesImages,
  prevIcon,
  nextIcon,
  storyIcon,
  banner,
  AppStore,
  PlayStore,
  QRcode,
  bannerResize,
  banner2Img1,
  banner2Img2,
  banner2Img3,
  banner3Img, 
} from './assets'

class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  changeMenu=()=>{
    this.setState({mobilemenu:!this.state.mobilemenu})
    const mobileMenu=document.getElementById("navMobileDropdown")
    mobileMenu.classList.toggle("navMobileHeight")
  }

   // Customizable Area Start
  // Customizable Area End
   async componentDidMount() {
     setInterval(()=>
     {
       this.updateBannerSlideOnTime()
     },3000)
     this.getHeaderFooterContent()
   }

   next = () => {
    if (this.state.currentSlide===this.state.reviews.length - 1)
    {
      this.setState((state) => ({
        currentSlide: 0,
      }));
    }
    else
    {
      this.setState((state) => ({
        currentSlide: state.currentSlide + 1,
      }));
    }

   };

   prev = () => {
     if (this.state.currentSlide===0)
     {
       this.setState((state) => ({
         currentSlide: state.reviews.length - 1,
       }));
     }
     else
     {
       this.setState((state) => ({
         currentSlide: state.currentSlide - 1,
       }));
     }
   };

   changeAutoPlay = () => {
     this.setState((state) => ({
       autoPlay: !state.autoPlay,
     }));
   };

   updateCurrentSlide = (index) => {
     const { currentSlide } = this.state;

     if (currentSlide !== index) {
       this.setState({
         currentSlide: index,
       });
     }
   };
   updateBannerSlideOnClick=(value)=>
   {
     this.setState((state) => ({
       banner: value
     }));
   }
   updateBannerSlideOnTime=()=>
   {
     if (this.state.banner===2)
     {
       this.setState((state) => ({
         banner: 0
       }));
     }
     else
     {
       this.setState((state) => ({
         banner: state.banner+1
       }));
     }

   }
   navigateTo = (pathName) => {
       this.props.history.push(pathName);
   }
  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.parentCont}
      >
       <Header />
        <Grid item xs={12} className={"navBarTopMargin"}/>
        <Grid item xs={12} className={classes.BannerCont} >
          <img src={bannerResize} alt="" className={classes.bannerBackImg}/>
          <div className={classes.dotsCont}>
            {
              [0,1,2].map(item=>
                <span className={classes.dot}
                      style={{backgroundColor:this.state.banner===item?APP_COLORS.app_theme_green_color:"white"}}
                      onClick={()=>this.updateBannerSlideOnClick(item)}
                />
              )
            }
            {/*<span className={classes.dot} style={{backgroundColor:this.state.banner===2?APP_COLORS.app_theme_green_color:"white"}}></span>*/}
            {/*<span className={classes.dot} style={{backgroundColor:this.state.banner===3?APP_COLORS.app_theme_green_color:"white"}}></span>*/}
          </div>
          {
            this.state.banner===0?(
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.BannerContentCont}
              >
                <Grid item xs={12} sm={6} className={classes.BannerLeft} >
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerOneText1}</Typography>
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerOneText2}</Typography>
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerOneText3}</Typography>
                  <Typography className={classes.bannerOneContent}>{configJSON.bannerOneContent}</Typography>
                  <div className={classes.bannerDownload}>
                    <div className={classes.bannerAppStore}>
                      <img src={AppStore} alt="" className={classes.AppStore}/>
                      <img src={PlayStore} alt="" className={classes.PlayStore}/>
                    </div>
                    <div className={classes.bannerScan}>
                      <div className={classes.qrCodes}>
                        <div className={classes.eachQR}>
                          <img src={QRcode} alt="" className={classes.QRCodeImg}/>
                          <Typography className={classes.AppStoreText}>{configJSON.PlayStore}</Typography>
                        </div>
                        <div className={classes.eachQR} style={{marginLeft:20}}>
                          <img src={QRcode} alt="" className={classes.QRCodeImg}/>
                          <Typography className={classes.AppStoreText}>{configJSON.AppStore}</Typography>
                        </div>
                      </div>
                      <Typography className={classes.ScanCode}>{configJSON.ScanToDownload}</Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.BannerRight} >
                  <img src={macbookImg} alt="" className={classes.bannerRightImg}/>
                </Grid>
              </Grid>
            ):""
          }
          {
            this.state.banner===1?(
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.BannerContentCont}
              >
                <Grid item xs={12} sm={6} className={classes.BannerLeft} >
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerTwoText1}</Typography>
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerTwoText2}</Typography>
                  <Typography className={classes.bannerOneContent}>{configJSON.bannerOneContent}</Typography>
                  <div className={classes.bannerDownload}>
                    <div className={classes.bannerAppStore}>
                      <img src={AppStore} alt="" className={classes.AppStore}/>
                      <img src={PlayStore} alt="" className={classes.PlayStore}/>
                    </div>
                    <div className={classes.bannerScan}>
                      <div className={classes.qrCodes}>
                        <div className={classes.eachQR}>
                          <img src={QRcode} alt="" className={classes.QRCodeImg}/>
                          <Typography className={classes.AppStoreText}>{configJSON.PlayStore}</Typography>
                        </div>
                        <div className={classes.eachQR} style={{marginLeft:20}}>
                          <img src={QRcode} alt="" className={classes.QRCodeImg}/>
                          <Typography className={classes.AppStoreText}>{configJSON.AppStore}</Typography>
                        </div>
                      </div>
                      <Typography className={classes.ScanCode}>{configJSON.ScanToDownload}</Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.BannerRight} >
                  <img src={banner2Img1[0]} srcSet={`${banner2Img1[0]} 300w, ${banner2Img1[1]} 768w, ${banner2Img1[2]} 1280w`} alt="" className={classes.banner2Img}/>
                  <img src={banner2Img2[0]} srcSet={`${banner2Img2[0]} 300w, ${banner2Img2[1]} 768w, ${banner2Img2[2]} 1280w`} alt="" className={classes.banner2Img}
                       style={{marginTop:"-22%",marginLeft:"-7%"}}
                  />
                  <img src={banner2Img3[0]} srcSet={`${banner2Img3[0]} 300w, ${banner2Img3[1]} 768w, ${banner2Img3[2]} 1280w`} alt="" className={classes.banner2Img}
                       style={{marginTop:"22%",marginLeft:"-15%"}}
                  />
                </Grid>
              </Grid>
            ):""
          }
          {
            this.state.banner===2?(
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.BannerContentCont}
              >
                <Grid item xs={12} sm={6} className={classes.BannerLeft} >
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerThreeText1}</Typography>
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerThreeText2}</Typography>
                  <Typography className={classes.bannerOneHeading}>{configJSON.bannerThreeText3}</Typography>
                  <Typography className={classes.bannerOneContent}>{configJSON.bannerOneContent}</Typography>
                  <div className={classes.bannerDownload}>
                  <Button className={classes.bannerThreeBtn} 
                    style={{backgroundColor:APP_COLORS.app_theme_green_color}}
                    onClick={()=>this.navigateTo("/PhoneLoginWeb")}
                    >
                     {configJSON.bannerThreeButtonText}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.BannerRight} >
                  <img src={banner3Img[0]} srcSet={`${banner3Img[0]} 300w, ${banner3Img[1]} 768w, ${banner3Img[2]} 1280w`} alt="" className={classes.bannerRightImg}/>
                </Grid>
              </Grid>
            ):""
          }
        </Grid>
        <Grid item xs={12} className={classes.whyParentCont} >
          <img src={group7} alt="" className={classes.group7Img}/>
          <img src={group12} alt="" className={classes.group12Img}/>
          <Typography className={classes.whyHeadingText}>{configJSON.WhyHeading}</Typography>
          <Typography className={classes.whyContentText}>{configJSON.WhyContentLine1}</Typography>
          <Typography className={classes.whyContentText}>{configJSON.WhyContentLine2}</Typography>
          <Grid item xs={10} className={classes.slidesParentCont}>
            <Grid
              container
              direction="row"
              justify="center"
              spacing={3}
              className={classes.slidesCont}
            >
              {
                this.state.reviews.map((item)=>
                  <Grid item xs={12} sm={6} md={4} className={classes.eachSlide}>
                    <div className={classes.eachSlideContent}>
                      <img src={slidesImages[item]} alt="" className={classes.slideImg}/>
                      <div className={classes.slideRight}>
                        <Typography className={classes.slideTitle}>{configJSON.slideTitle}</Typography>
                        <Typography className={classes.slideContent}>{configJSON.slideContent}</Typography>
                        <Typography className={classes.slideContent}>{configJSON.slideContent}</Typography>
                      </div>
                    </div>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
          <div className={classes.getStartedBtnCont}>
          <Button className={classes.getStartedBtn} 
             style={{backgroundColor:APP_COLORS.app_theme_green_color}}
             onClick={()=>this.navigateTo("/SignUp")}
             >
              {configJSON.GetStarted}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.experienceParentCont} >
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.experienceCont}
          >
            <Grid item xs={12} sm={8} className={classes.experienceLeft}>
              <img src={macbookImg} srcSet={`${macbookImg} 300w, ${macbookImg2} 768w, ${macbookImg3} 1280w`} alt="" className={classes.macbookImg}/>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.experienceRight}>
              <div className={classes.experienceRightItem}>
                <img src={addUser} alt="" className={classes.addUserImg}/>
              </div>
              <div className={classes.experienceRightItem}>
                <Typography className={classes.experienceHeadingText}>{configJSON.experienceLine1}</Typography>
              </div>
              <div className={classes.experienceRightItem}>
                <Typography className={classes.experienceHeadingText}>{configJSON.experienceLine2}</Typography>
              </div>
              <div className={classes.experienceRightItem} style={{marginTop:25}}>
                <Typography className={classes.experienceContentText}>{configJSON.experienceContentLine1}</Typography>
              </div>
              <div className={classes.experienceRightItem} style={{marginTop:15}}>
                <Typography className={classes.experienceContentText}>{configJSON.experienceContentLine2}</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.TestsCont}>
          <img src={prevIcon} alt="" onClick={this.prev}/>
          <div className={classes.carouselCont}>
            {/*<Carousel*/}
            {/*  autoPlay={this.state.autoPlay}*/}
            {/*  selectedItem={this.state.currentSlide}*/}
            {/*  onChange={this.updateCurrentSlide}*/}
            {/*  showArrows={false}*/}
            {/*  showStatus={false}*/}
            {/*  showIndicators={false}*/}
            {/*  showThumbs={false}*/}
            {/*  infiniteLoop={true}*/}
            {/*>*/}
              {
                [0,1,2,3,4,5].map(item=>
                  (
                    item===this.state.currentSlide && (
                      <div className={classes.eachTestMon} key={item}>
                        <Typography className={classes.testimonialsHeading}>{configJSON.testimonialsHeading}</Typography>
                        <Typography className={classes.testimonialsContent}>{configJSON.testimonialsContent}</Typography>
                        <div className={classes.authorCont}>
                          <img src={storyIcon} alt="" className={classes.authorImg}/>
                          <div className={classes.authorNameCont}>
                            <Typography className={classes.PersonName}>{configJSON.PersonName}</Typography>
                            <Typography className={classes.Designation}>{configJSON.Designation}</Typography>
                          </div>
                        </div>
                      </div>
                    )
                  )
                )
              }
            {/*</Carousel>*/}
          </div>
          <img src={nextIcon} alt="" onClick={this.next}/>
        </Grid>
        <Footer />
      </Grid>

      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  //Container Styling
  parentCont:{
    width:"100%",
    // display:"flex",
    // flexDirection:"column"
  },
  experienceCont:{
    width:"100%",
    minHeight:600
  },
  navBar:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    boxShadow:"0 2px 7px 0 rgba(147, 147, 147, 0.5)"
    // minHeight:150,
    // background:"red"
  },
  navMiddle:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-evenly"
  },
  navLeft:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  navRight:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  experienceParentCont:{
    width:"100%",
  },
  whyParentCont:{
    position:"relative",
    minHeight:600,
    width:"100%"
  },
  BannerCont:{
    width:"100%",
    minHeight:600,
    display:"flex",
    alignItems:"center",
    // background:"red",
    position:"relative"
  },
  dotsCont:{
    position:"absolute",
    bottom:"10%",
    width:"100%",
    display:"flex",
    justifyContent:'center',
    alignItems:"center"
  },
  BannerContentCont:{
    width:"100%",
  },
  BannerLeft:{
    height:"100%",
    paddingLeft:40,
    // display:"flex",
    // alignItems:"center",
  },
  BannerRight:{
    width:"100%",
    display:"flex",
    marginTop:20,
    justifyContent:"center",
    alignItems:"center",
  },
  bannerScan:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    marginLeft:20
  },
  qrCodes:{
    display:"flex",
    alignItems:"center",
  },
  eachQR:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column"
  },
  bannerDownload:{
    display:"flex",
    alignItems:"center",
    marginTop:30
    // justifyContent:"center",
  },
  bannerAppStore:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    flexDirection:"column"
  },
  slidesParentCont:{
    margin:"30px auto"
  },
  eachSlide:{
    width:"100%",
  },
  eachSlideContent:{
    width:"95%",
    margin:"10px auto",
    display:"flex",
    justifyContent:"space-evenly",
    alignItems:"center",
    backgroundColor:'#fdd001',
    borderRadius: 12,
    padding:"25px 10px",
    // height:150,
    boxShadow: "0 1px 12px 2px rgba(150, 150, 150, 0.63)"
  },
  authorCont:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginTop:30
  },
  getStartedBtnCont:{
    width:"100%",
    margin:"20px auto",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },

  experienceLeft:{
    // height:"100%",
    background:"#4A4A4A"
    // display:"flex",
    // flexDirection:"column",
    // alignItems:"center",
    // justifyContent:"center"
  },
  experienceRight:{
    background:"#4c4c4c",
    padding:"40px 0",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center"
  },
  authorNameCont:{
    display:"flex",
    marginLeft:15,
    flexDirection:"column",
  },
  experienceRightItem:{
    width:"80%",
    // margin:"auto"
  },
  footerCont:{
    backgroundColor:APP_COLORS.app_theme_green_color,
    borderRadius:32,
    boxShadow: "0 -1px 6px 0 rgba(0, 0, 0, 0.5)",
  },
  TestsCont:{
    minHeight:400,
    backgroundColor:"#f7f7f7",
    display:"flex",
    padding:"30px 10px",
    alignItems:'center',
    justifyContent:"space-evenly"
  },
  carouselCont:{
    width:"60%",
    maxWidth:500,
    backgroundColor:"#f7f7f7"
  },
  eachTestMon:{
   // width:60,
   // height:200,
    positon:"relative",
    backgroundColor:"#f7f7f7"
  },
  footerIconsCont:{
    display:"flex",
    width:"fit-content",
    margin:"auto"
  },


  //Image Styling
  authorImg:{
    width:40,
    height:40,
    objectFit:"contain",
  },
  testBackImg:{
    width:100,
    height:100,
    objectFit:"contain",
    position:"absolute",
    top:'20%',
    zIndex:"-10",
    left:"3%"
  },
  footerIcon:{
    width:30,
    height:30,
    objectFit:"contain",
    marginRight:10,
    marginLeft:10
  },
  macbookImg:{
    width:"100%",
    height:"100%",
    objectFit:"contain",
  },
  addUserImg:{
    width:120,
    height:120,
    objectFit:"contain",
    marginRight:"auto"
  },
  group7Img:{
    width:100,
    height:100,
    objectFit:"contain",
    position:"absolute",
    top:'20%',
    zIndex:"-10",
    left:"3%"
  },
  bannerBackImg:{
    width:"100%",
    height:"100%",
    objectFit:"cover",
    position:"absolute",
    // left:0,
    zIndex:"-10",
    top:0
  },
  AppStore:{
    width:100,
    height:30,
    objectFit:"contain"
  },
  QRCodeImg:{
    width:60,
    height:60,
    objectFit:"contain"
  },
  banner2Img:{
    width:"30%",
    objectFit:"contain"
  },
  bannerRightImg:{
    width:"70%",
    objectFit:"contain"
  },
  PlayStore:{
    width:100,
    marginTop:10,
    height:30,
    objectFit:"contain",
  },
  logoImg:{
    width:90,
    height:90,
    margin:"5px auto",
    objectFit:"contain",
    cursor: 'pointer'
  },
  navMiddleImg:{
    width:45,
    height:45,
    marginRight:"10px",
    objectFit:"contain",
  },
  navDownArrowImg:{
    width:20,
    height:20,
    marginLeft:"7px",
    float:"right",
    objectFit:"contain",
  },
  loginUser:{
    width:20,
    height:20,
    objectFit:"contain",
    marginRight:7
  },
  group12Img:{
    width:350,
    height:350,
    objectFit:"contain",
    position:"absolute",
    zIndex:"-10",
    bottom:'0%',
    right:"5%"
  },
  slideImg:{
    width:85,
    height:85,
    objectFit:"contain",
  },

  //Text stlyling
  footerText:{
    fontSize:16,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'white',
    marginLeft:10,
    marginRight:10
  },
  experienceHeadingText:{
    fontSize:30,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'white',
  },
  experienceContentText:{
    fontSize:16,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#8f92a1',
  },
  whyHeadingText:{
    fontSize:30,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"center",
    color:'#1e1f20',
    marginTop:"20px"
  },
  bannerOneHeading:{
    fontSize:50,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    lineHeight:"55px",
    // marginTop:-20,
  },
  bannerOneContent:{
    fontSize:18,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    marginTop:20
  },
  AppStoreText:{
    fontSize:12,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20'
  },
  ScanCode:{
    fontSize:14,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20'
  },
  testimonialsHeading:{
    fontSize:30,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"center",
    color:'#1e1f20',
    marginTop:"20px"
  },
  PersonName:{
    fontSize:16,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"left",
    width:'fit-content',
    color:'#1e1f20',
    // marginLeft:"20px"
  },
  Designation:{
    fontSize:14,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"left",
    width:'fit-content',
    color:'#a7b4cb',
    // marginLeft:"20px"
  },
  whyContentText:{
    fontSize:18,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"center",
    color:'#8f92a1',
  },
  testimonialsContent:{
    fontSize:18,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"center",
    color:'#8f92a1',
  },
  slideTitle:{
    fontSize:18,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"left",
    color:'#1e1f20'
  },
  slideContent:{
    fontSize:16,
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign:"left",
    color:'#5c5d5f'
  },
  navMiddleItem:{
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    display:"flex",
    alignItems:'center',
    cursor: 'pointer'
  },
  navMiddleMenuItem:{
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular,
    color:'#1e1f20',
    margin:"10px auto",
    // padding:"0 20px",
    width:"100%",
    display:"flex",
    alignItems:'center',
    justifyContent:'space-between'
  },



  //Button styling
  getStartedBtn:{
    color:'white',
    borderRadius:12,
    height:50,
    textTransform:"none",
    width:200,
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },
  bannerThreeBtn:{
    color:'white',
    borderRadius:12,
    height:50,
    textTransform:"none",
    width:250,
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },
  navLoginBtn:{
    borderRadius:12,
    height:45,
    width:130,
    textTransform:"none",
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },
  navSignupBtn:{
    borderRadius:12,
    height:45,
    color:"white",
    width:130,
    textTransform:"none",
    fontSize:15,
    fontFamily:APP_FONTS.SofiaProRegular
  },

  //Dot Styling
  dot:{
    width:15,
    height:15,
    borderRadius:"50%",
    marginRight:10,
    cursor:"pointer"
  },
});
// Customizable Area End
export default withRouter(withStyles(styles, { withTheme: true })(LandingPage));
