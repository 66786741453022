//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
import { RouterProps } from "react-router";
import { ToastContext } from "../../../components/src/ToastContext.jsx";

export type Props =  RouterProps & {
    // Customizable Area Start
    id: string;
    openDialog : any;
    showLoader : any;
    hideLoader : any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string,
    authToken: string,
    message: string,
    loading: boolean;
    userType: string;
    emailError: string;
    messageError: string;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class SupportsController extends BlockComponent<
    Props,
    S,
    SS
    > {

    apiSupportsCallId: String = "";
    emailReg: RegExp;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
        ]

        this.state = {
            email: "",
            authToken: "",
            message: "",
            loading: false,
            userType:"",
            emailError: "",
            messageError: ""
        };
        // Customizable Area End

        this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    static contextType = ToastContext;

    async receive(from: string, message: Message) {
        const context = this.context;
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if(getName(MessageEnum.AlertMessage) === message.id){
            const title = message.getData(
              getName(MessageEnum.AlertTitleMessage)
            );
            var AlertBodyMessage = message.getData(
              getName(MessageEnum.AlertBodyMessage)
            );
            this.props.showNotification(AlertBodyMessage,'error');  
         } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiSupportsCallId) {
                this.setState({ loading: false })
                this.props.hideLoader();
                if (responseJson && responseJson.meta && responseJson.meta.message) {
                    context.showNotification(responseJson.meta.message,"success");
                    this.setState({
                        email : '',
                        message : ''
                    })
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                    this.sendAPIFailMessage();
                }

            }
        }
        // Customizable Area End
    }

    // Customizable Area End
    //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
    supportsAPICall() {

        let email: any = this.state.email;
        let error: any = "";
        const validateEmailError = this.validateEmail(email);
        if (validateEmailError) {
            this.setState({emailError: validateEmailError});
            return;
        }

        let message: any = this.state.message;
        const validateMessageError = this.validateInput(message);
        if (validateMessageError) {
            this.setState({messageError: validateMessageError});
            return;
        }

        const header = {
            "Content-Type": configJSON.contentType
        };

        const data = {
            email: this.state.email,
            message: this.state.message
        };


        const httpBody = {
            data: data,
            token: this.state.authToken,
            user_type: this.state.userType,
        };
        this.props.showLoader();    
        this.setState({ loading: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiSupportsCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.supportsAPIEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiEndMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    //END --here we are validating the phone number and country code and making API call to get OTP to verify user 

    validateInput(inputMsg: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(inputMsg)) {
            error = "Message " + configJSON.errorBlankField;
        }
        return error;
    }

    isNonNullAndEmpty(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 0
        );
    }

    validateEmail(email: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(email)) {
          error = "Email " + configJSON.errorBlankField;
        } else if (!this.isValidEmail(email)) {
          error = configJSON.errorEmailNotValid;
        }
        return error;
    }

    isValidEmail(email: string) {
        return this.emailReg.test(email);
    }

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }
}
