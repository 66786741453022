//@ts-nocheck
import React, { useState } from "react";
// Customizable Area Start
import { IconButton } from '@material-ui/core';
import { ScrollView, Text,ActivityIndicator, View,Modal, Image, TouchableOpacity, ImageBackground, StyleSheet } from 'react-native'
// Customizable Area End
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import StorageProvider from '../../../framework/src/StorageProvider';
import { Grid, Typography, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import PaymentSuccessfulController, {
    Props,
    configJSON
} from "./PaymentSuccessfulController.Web";
import Scale from "../../../components/src/Scale";
import HeaderComponent from "../../../components/src/HeaderComponent";
import CustomButton from "../../../components/src/CustomButton";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withRouter } from "react-router";
class PaymentSuccessful extends PaymentSuccessfulController {

    constructor(props: Props) {
    super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        await StorageProvider.get('loginData').then((res) => {
            const loginData = JSON.parse(res);
            if (loginData) {
                this.setState({
                    id: loginData.id,
                    authToken: loginData.token
                });
            }
        });
        await StorageProvider.get('orderId').then((res) => {
            const orderIdData = JSON.parse(res);
            if (orderIdData) {
                console.log("orderId:::", orderIdData);
                this.setState({
                    orderId: orderIdData.orderId,
                    goBack: orderIdData.goBack,
                });

            }
        });
        await this.genrateQrCodeAPICall();
    }

    _backTapped = async () => {
      let data: any = {};
      data.show_pay_button = false;
      await StorageProvider.remove("showPay");
      await StorageProvider.set("showPay", JSON.stringify(data));
      this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectShop");
    };


    _onFindPrintersTapped = () => {
        this.props.history.push('NearByShopsCurrentOrder');
    }

    render() {
        const { classes } = this.props;
        return (
        <Grid
            container
            className={classes.rightSideCont}
        >
          <Grid container className={classes.contentMain}>
          <Grid item xs={12} sm={6} className={classes.contentLeft}>
            <Typography className={classes.paymentSuccess}>Payment Successful</Typography>
            <img src={this.state.qrCodeResponce} alt="" className={classes.barCodeImg}/>
            <Typography className={classes.scanCode}>Scan this QR code at any printer shop from list or shop of your choice to get your print out</Typography>
          </Grid>
         <Grid item xs={12} sm={6} className={classes.contentRight}>
            <Grid container  className={classes.descCont}>
              <Grid item xs={12} className={classes.eachDesc}>
                  <span className={classes.orderSpan}>Order # {this.state.orderNumber}</span>
                  <span className={classes.descSpan}>{this.state.orderTime}</span>
              </Grid>
              <Grid item xs={12} className={classes.eachDesc}>
                <span className={classes.imgSpan}><img src={require('../assets/generic_doc.png')} alt="" className={classes.spanImg}/>{this.state.totalDocs} docs | Rs.{parseFloat(this.state.totalAmount.toString()).toFixed(2)}</span>
                <span className={classes.descSpan}>{this.state.countDownTime} from expiry</span>
              </Grid>
              <Grid item xs={12} className={classes.eachDesc}>
               <Button onClick={this._backTapped} className={classes.findPrintersBtn}>Find Printers</Button>
              </Grid>
             </Grid>
          </Grid>
        </Grid>
     </Grid>
      );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
parentCont:{
    width:'100%',
    minHeight:'100vh',
    display:'flex'
},
leftSideCont:
  {
    backgroundColor: APP_COLORS.app_theme_yellow_color,
    height:'100%',
  },
rightSideCont:
  {
    height:'100%',
    background:'#f7f7f7'
  },
findPrintersBtn:
  {
    backgroundColor:'#32795f',
    color:'white',
    borderRadius:12,
    width:'90%',
    margin:'20px auto',
    fontFamily:APP_FONTS.SofiaProRegular,
    height:55,
    maxWidth:300,
    "&:hover": {
      background: "#32795f"
    },
  },
backgroundImg:
  {
    background:'#f7f7f7',
    // height:'100%'
    // backgroundImage:`url(require('../assets/background.png'))`,
    // backgroundRepeat:"repeat"
  },
barCodeImg:
  {
    width:'100%',
    maxWidth:200,
    objectFit:"contain",
    margin:"20px auto 0 auto"
  },
paymentSuccess:
  {
    fontSize:22,
    margin:"20px 0 30px 20px",
    fontWeight:'normal',
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign: 'center'
  },
scanCode:
  {
    fontSize:14,
    width:'80%',
    margin:"20px auto 0 auto",
    fontWeight:'normal',
    fontFamily:APP_FONTS.SofiaProRegular,
    textAlign: 'center'
  },
contentLeft:
  {
    display:'flex',
    flexDirection:"column",
    alignItems:'center',
    justifyContent:'center'
  },
descCont:{
  display:'flex',
  borderRadius:12,
  backgroundColor:'white',
  padding:'10px 20px',
  width:'90%',
  margin:'auto'
},
eachDesc:{
  width:'90%',
  display:'flex',
  alignItems:"center",
  justifyContent:'space-between',
  margin:'auto',
  marginTop:'20px'
},
spanImg:{
  width:25,
  objectFit:'contain',
  marginRight:10
},
imgSpan:{
  display:'flex',
  alignItems:'center',
  fontFamily:APP_FONTS.SofiaProRegular,
  color:'#8f92a1',
},
descSpan:{
  fontFamily:APP_FONTS.SofiaProRegular,
  color:'#8f92a1',
},
orderSpan:{
  fontFamily:APP_FONTS.SofiaProRegular,
  fontWeight:'bold',
  color:'#1e1f20',
},
navBar:{
  display:'flex',
  justifyContent:'flex-end',
  alignItems:'center',
  margin:"20px 30px"
},
navSpan:{
  height:45,
  width:45,
  backgroundColor:'#32795f',
  display:'flex',
  alignItems: 'center',
  justifyContent:'center',
  borderRadius: 14,
  marginLeft: 15,
  marginRight:30
},
navSpanImg:{
  height:20,
  width:20,
  objectFit:'contain'
},
userName:{
  fontFamily:APP_FONTS.SofiaProRegular,
  color:'#1e1f20'
},
contentMain:{
  display:'flex',
  alignItems: 'center',
  justifyContent:'center',
  marginBottom:40
}
});

const PaymentSuccessfulWithConfirmBox = withConfirmBox(PaymentSuccessful);
const PaymentSuccessfulWithLoader = withLoader(PaymentSuccessfulWithConfirmBox)
const PaymentSuccessfulWithToast = withToast(PaymentSuccessfulWithLoader)
  
export default withStyles(styles, { withTheme: true })(withRouter(PaymentSuccessfulWithToast));