// @ts-nocheck
import React from "react";
// Customizable Area Start
import { withRouter } from "react-router";
import {Grid, Typography, Button, InputBase, Modal,Dialog } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  userLogo,
  storeLogo,
  colorLogo,
  groupLogo,
  group8,
  group3,
  group2,
  group6,
  group4,
  group5,
  group
} from "./assets";
// Customizable Area End
import CustomCheckBox from "../../../components/src/CustomCheckBox";
import OTPInputView from "../../../components/src/OTPInputView6Digit";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import "./css/style.css";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import "./css/style.css";

import SignUpController, {
    Props,
    configJSON
} from "./SignUpController.web";
import { APP_FONTS } from "../../../components/src/AppFonts";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";

class SignUp extends SignUpController {
    constructor(props: Props) {
        super(props);
      this.scrollRef = React.createRef();
      this.state = {
            otp: "" ,
            confirmotp: "",
            checkedBox: false,
            currentCountryCode: "91",
            newValue: "",
            value: "Customer",
            alertMessage:"",
            alertOpen:false,
          };
    }
  
    handleChangeCheckBox = (event:any) => {
      this.setState({termConditionError: false})
      this.setState({checkedBox: event.target.checked})
    };

    getPasscode(passcode: string) {
      this.setState({passcodeError: false})
      this.setState({ passcode });
  }

    getConfirmedPasscode(confirmedPasscode: string) {
        this.setState({confirmPasscodeError: false})
        this.setState({matchPasscodeError: false})
        this.setState({ confirmedPasscode });
    }
    // hide show modal
    displayModal(show: boolean) {
        this.setState({ isVisible: show })
    }

    displayWhyEmailModal(show: boolean) {
        this.setState({ whyEmail: show })
    }

    checkBoChecked(show: boolean) {
        this.setState({ isCheckBoxChecked: show })
    }

    handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };

    handleWhyEmailModal=()=>{
      this.setState({whyEmailModal:!this.state.whyEmailModal})
    }

    handleTermsModal=()=>{
      this.setState({termsModal:!this.state.termsModal})
    }
    handleAlert=(txt)=>{
      this.setState({alertMessage:txt},()=>
      {
        this.setState({alertOpen:true})
      })
    }
  scrollToTop=()=>{
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }
    closeAlert=()=>{
      this.setState({alertOpen:false})
    }

    render() {
        const { navigation,classes } = this.props;
        const { value } = this.state;
    return (
       <form>
        <Grid 
        container
        direction="row"
        justify="center"
        className="container-box"
        ref={this.scrollRef}
        >
        <Grid container className="bg-auth">
          <Grid item xs={12} sm={6} className="left-side pb-150">
            <Typography className={`main-head ${classes.sofiaFont}`}>
              {configJSON.signUpLabel}
            </Typography>
            <Typography className={`label-name ${classes.sofiaFont}`}>
              {configJSON.signUpAs}
            </Typography>
            <Button onClick={(event) => {
                this.handleChange(event, "Customer");
              }}
              className={
                value === "Customer"
                  ? `auth-btn light ${classes.sofiaFont}`
                  : `auth-btn  ${classes.sofiaFont}`
              }
              variant="contained"
            >
              {configJSON.labelIamUser}
              <img src={userLogo} />
            </Button>
            <Button onClick={(event) => {
              this.handleChange(event, "Merchant");
            }}
            className={
              value === "Merchant"
                ? `auth-btn light ${classes.sofiaFont}`
                : `auth-btn  ${classes.sofiaFont}`
            }
            variant="contained"
            > 
              {configJSON.labelIamShoOwner}
              <img src={storeLogo} />
            </Button>
            <Grid className="phoneNumberCont">
              <Typography className={`label-name ${classes.sofiaFont}`}>
              {configJSON.labelPhoneNumber}
              </Typography>
              <div className="login_number_cont">
              <CountryCodeSelector
               isAllFlagAvailable={true}
               disable={true}
              allowPropChange={true}
              navigation={navigation}    //Merge Engine::From BDS
              id={"CountryCodeSelector"} //Merge Engine::From BDS
              placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
              disable={false}  //Merge Engine::From BDS
              value={this.state.currentCountryCode} //Merge Engine::From BDS
              />
              <TextField
                type="number"
                variant="outlined"
                className={`number-field ${classes.sofiaFont}`}
                maxLength={10}
                required
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
               }}
                value={this.state.phoneNumber}
                onChange={(e) => {this.onChangePhoneNumber(e)  
                this.setState({phoneNumberError: false})      
                }}
              />
              </div>
            </Grid>
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.phoneNumberError}</p>
            
            <Typography className={`label-name mt-25 ${classes.sofiaFont}`}>
              {configJSON.labelEmail}
            </Typography>
            <InputBase
              placeholder="davin.wong@mail.com"
              type="text"
              variant="outlined"
              fullWidth
              className="text-field"
              value={this.state.email}
              onChange={(e) => {this.onChangeEmail(e)
              this.setState({emailError: false})
              }}
            />
             <p className={`why-email ${classes.sofiaFont}`}
              onClick={this.handleWhyEmailModal}
              >
                Why email?
             </p>
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.emailError}</p>
            
            <Typography className={`label-name ${classes.sofiaFont}`}>
              {configJSON.labelYourPasscode}
            </Typography>
            <Grid className="otp-input-box">
              <OTPInputView isSecureType={false} getOtp={(otp: string) => this.getPasscode(otp)}
              className="pass-code-field" />
            </Grid>         
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.passcodeError}</p>
            
            <Typography className={`label-name ${classes.sofiaFont}`}>
              {configJSON.labelConfirmPasscode}
            </Typography>
            <Grid className="otp-input-box">
              <OTPInputView isSecureType={false} getOtp={(otp: string) => this.getConfirmedPasscode(otp)}
              className="pass-code-field"/>
            </Grid>
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.confirmPasscodeError}</p>
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.matchPasscodeError}</p>
            
            <Grid className="check-box-field">
              <CustomCheckBox
              testID={"chkBoxTermsAndConditions"}
              isChecked={this.state.isCheckBoxChecked}
              onChangeValue={() => this.checkBoChecked(!this.state.isCheckBoxChecked)} />
              <p className={`agree-terms ${classes.sofiaFont}`}>{configJSON.labelIagree}
              <span className={`text_underline`}
              onClick={this.handleTermsModal}
              >
              {configJSON.labelTerms}
              </span>
            </p>
            </Grid>
            <p className={`error-label ${classes.sofiaFont}`}>{this.state.termConditionError}</p>
            
            <Grid className="btn-box">
              <Button variant="contained" className={`site-lg-btn ${classes.sofiaFont}`}
              onClick={() => this.validatePhoneAndGetOtpCall()}>
                {configJSON.btnNextTitle}
              </Button>
              <Grid>
                <p className={`have-acc ${classes.sofiaFont}`}>{configJSON.alreadyHaveAccount}</p>
                <Button className={`login-btn ${classes.sofiaFont}`}
                 onClick={() => {
                   this.scrollToTop()
                   this.props.history.push("PhoneLoginWeb")
                 }}>
                  <img src={userLogo} />
                  {configJSON.labelTitle}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className="right-side" >
            <img src={colorLogo} onClick={() => this.props.history.push("/")}  className="site-logo" />
            <Grid className="right-icons mt-70">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip1}</span>
                <img src={group3}  className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip2}</span>
                <img src={group2}  className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip3}</span>
                <img src={group6}  className={"right-alert-img"} />
              </div>
            </Grid>
            <Grid className="right-icons">
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip4}</span>
                <img src={group4}  className={"right-alert-img "} />
              </div>
              <div className={"icon_cont "}>
                <span className={"icon_name"}>{configJSON.labeltooltip5}</span>
                <img src={group5}  className={"right-alert-img"} />
              </div>
              <div className={"icon_cont"}>
                <span className={"icon_name"}>{configJSON.labeltooltip6}</span>
                <img src={group}  className={"right-alert-img"} />
              </div>
            </Grid>
            <img src={group8} className="group-logo bt-80" />
          </Grid>
        </Grid>
      </Grid>
         <Modal
           disablePortal
           disableEnforceFocus
           disableAutoFocus
           open={this.state.whyEmailModal}
           onClose={this.handleWhyEmailModal}
           // className={classes.modalParent}
         >
           <div className={classes.modalContent}>
             <Typography className={`${classes.WhyModalHeading}  ${classes.sofiaFont}`}>{configJSON.labelWhyEmail}</Typography>
             <img className={classes.modalImg} src={group8} alt=""/>
             <Typography className={`${classes.WhyModalContent}  ${classes.sofiaFont}`}>{configJSON.labelWhyEmailContent}</Typography>
             <Button
               style={{backgroundColor:APP_COLORS.app_theme_green_color}}
               className={`${classes.closeModalBtn}  ${classes.sofiaFont}`}
               onClick={this.handleWhyEmailModal}
             >{configJSON.labelClose}</Button>
           </div>
         </Modal>
         <Modal
           disablePortal
           disableEnforceFocus
           disableAutoFocus
           open={this.state.termsModal}
           onClose={this.handleTermsModal}
           // className={classes.modalParent}
         >
           <div className={classes.modalContent}>
             <Typography className={`${classes.WhyModalHeading}  ${classes.sofiaFont}`}>{configJSON.labelTerms}</Typography>
             <img className={classes.modalImg} src={group8} alt=""/>
             <Typography className={`${classes.WhyModalContent}  ${classes.sofiaFont}`}>{configJSON.labeTermsContent}</Typography>
             <Button
               style={{backgroundColor:APP_COLORS.app_theme_green_color}}
               className={`${classes.closeModalBtn}  ${classes.sofiaFont}`}
               onClick={this.handleTermsModal}
             >{configJSON.labelClose}</Button>
           </div>
         </Modal>
      </form>
    )}
}
const styles = (theme: Theme) => createStyles({
  sofiaFont:{
    fontFamily:APP_FONTS.SofiaProRegular
  },
  modalParent: {
    // height:'100%',
    width: "100%",
    position: "relative",
  },
  modalContent: {
    position: "absolute",
    top:"50%",
    display:"flex",
    alignItems:'center',
    flexDirection:"column",
    justifyContent:"center",
    left:"50%",
    transform:"translate(-50%,-50%)",
    borderRadius: "12px",
    width: "50%",
    padding: "20px",
    backgroundColor: "#fff",
  },
  modalImg:{
    width:"90%",
    objectFit:"contain",
    margin:"10px auto"
  },
  closeModalBtn:{
    fontFamily: APP_FONTS.SofiaProRegular,
    fontSize: "16px",
    width:100,
    margin:"20px auto",
    borderRadius:12,
    color: "#ffffff",
    textTransform: "capitalize",
  },
  WhyModalHeading:{
    fontSize:22,
    margin:"20px auto"
  },
  WhyModalContent:{
    fontSize:16,
    margin:"10px auto"
  }
})

const SignUpWithLoader = withLoader(SignUp)
  const SignUpWithToast = withToast(SignUpWithLoader)
  export default withRouter(withStyles(styles, { withTheme: true })(SignUpWithToast));// Customizable Area End