Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentType = "application/json";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderManagement";
exports.labelBodyText = "OrderManagement Body";
exports.multiformData="multipart/form-data";
exports.btnExampleTitle = "CLICK ME";
exports.btnExampleTitle = "CLICK ME";


//API CONSTANTS
exports.getCurrentOrdersAPIEndPoint = "bx_block_menuordering/orders/get_orders"; //{userid}
exports.getOrderDetailsAPIEndPoint = "bx_block_ordermanagement/orders/orderId"; //{userid}
exports.getOTPToDownloadDocsAPIEndPoint = "account_block/accounts/send_otps/send_download_sms"; //{userid}
exports.getVerifyOTPToDownloadDocsAPIEndPoint = "account_block/accounts/sms_confirmations"; //{userid}

exports.postAPiEndMethod = "POST"
exports.getAPiEndMethod = "GET"
exports.putAPiEndMethod = "PUT"
exports.patchAPiEndMethod = "PATCH"
exports.deleteAPiEndMethod = "DELETE"
//API CONSTANTS



//Labels

exports.readyPrint="Ready To Print"
exports.orderExpiry="Expiry in 03:20"
exports.orderNumber="Order #1233231"
exports.totalAmount="Total Paid: Rs.2122"
exports.orderTime="12/12/2020 12:20 PM"
exports.navBarName="David Wong";
exports.doc1Name="Weekly_Data";
exports.doc2Name="App_Screens";
exports.doc3Name="Customer_Presentation";
exports.doc4Name="Meeting_Notes";
exports.docpages="All Pages - Colored";
exports.docSize="Select Files To Print";
exports.docCopy="1 copy";
exports.showQR="Show QR Code";
exports.finPrinterBtn="FIND PRINTERS NEARBY";
exports.allOrdersText="All Orders";
exports.POrderNumber="Order #23432";
exports.pDocs="12 Docs";
exports.readyForPrint="Ready For Print";
exports.orderCompleteText="Order Complete";
exports.withPrinter="with Printer 1";
exports.filterHeading="Filter Orders";
exports.byStatus="By Status";
exports.byTime="By Time";
exports.userNameWith="with Sourabh Mishra";
exports.ShoporderAmount="Rs. 500";
exports.applyBtnText="APPLY";
exports.fromText="From";
exports.toText="To";
exports.today="Today";
exports.thisWeek="This Week";
exports.thisMonth="This Month";
exports.errorBlankField = "can't be blank";
exports.continuePayBtn = "Continue to payment"
exports.findShopsBtn = "Find Open Print Shops"



// Customizable Area End