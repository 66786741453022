// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import SoftwareInformationController , {
  Props,
  configJSON
} from "./SoftwareInformationController.Web";
import {withRouter} from "react-router";
import renderHTML from 'react-render-html';
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import TermsAndCondition from "./TermsAndConditionBlock.Web";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";

class SoftwareInformation extends SoftwareInformationController{

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const _this = this;
    return (
      //Merge Engine DefaultContainer
      <Grid container style={{width:"100%",fontFamilty:APP_FONTS.SofiaProRegular}}>
        <p className={classes.paraFont}>{configJSON.labelAdditional}</p>
        {
          configJSON.additionalPackages.map((item,index)=>
            <Grid item xs={12} className={classes.eachItem}>
              <Typography className={classes.packageName}>{index+1}) {item.name}</Typography>
              <Typography className={classes.packageDesc}>{item.desc}</Typography>
            </Grid>
          )
        }
      </Grid>
    );
  }
}

// Customizable Area Start
// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    paraFont:{
      fontSize:18,
      fontFamily:APP_FONTS.SofiaProRegular,
      textAlign:"left",
      marginLeft:20,
      marginTop:40
    },
    eachItem:{
      marginTop:20
    },
    packageName:{
      fontSize:16,
      // fontWeight:"bold",
      fontFamily:APP_FONTS.SofiaProRegular,
      color:"#1e1f20",
      width:"90%",
      margin:"0 auto"
    },
    packageDesc:{
      fontSize:16,
      fontFamily:APP_FONTS.SofiaProRegular,
      color:"#8f92a1",
      width:"90%",
      margin:"5px auto"
    }
  })
export default withRouter(
  withStyles(styles, { withTheme: true })(withConfirmBox(SoftwareInformation))
);
// Customizable Area End
// Customizable Area End